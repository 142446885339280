import { useState } from "react";
import { Link } from "react-router-dom";

import images from "../../api/imagesConfig.js";
import { useUser } from "../utilities/UserContext.jsx";

const Nav = () => {
	let burgerMenu = images.icons["menu"];

	const { currentUser } = useUser();

	const [showNavMenu, setShowNavMenu] = useState("");

	const showNavMenuMobile = () => {
		setShowNavMenu("show");
	};

	const closeModalBox = () => {
		setShowNavMenu("");
	};

	return (
		<div>
			<div className="nav-wrapper">
				<nav>
					<li>
						<Link to="/caste/select">Search</Link>
					</li>
					<li>
						<Link to="/features">Features</Link>
					</li>
					<li>
						<Link to="/faq">FAQ</Link>
					</li>
					{/* <li>
						<Link to="/suggestions">Suggestions</Link>
					</li> */}
					<li>
						{currentUser ? (
							<Link to="/login">Account</Link>
						) : (
							<Link to="/login">Sign In</Link>
						)}
					</li>
					<li className="donate-button">
						<Link to="/donate">Donate</Link>
					</li>
				</nav>

				<div className="burger-menu">
					<img
						src={burgerMenu}
						alt="mobile-menu"
						onClick={showNavMenuMobile}
					/>
				</div>
			</div>

			<div className={"modal-box-container " + showNavMenu}>
				<div className="menu-mobile modal-box-wrapper">
					<button className="close" onClick={closeModalBox}>
						close
					</button>

					<div className="modal-box-wrapper">
						<div className="menu-mobile-container">
							<li>
								<Link to="/">Home</Link>
							</li>
							<li>
								<Link to="/caste/select">Search</Link>
							</li>
							<li>
								<Link to="/features">Features</Link>
							</li>
							<li>
								<Link to="/faq">FAQ</Link>
							</li>
							<li>
								{currentUser ? (
									<Link to="/login">Account</Link>
								) : (
									<Link to="/login">Sign In</Link>
								)}
							</li>
							<li className="donate-button">
								<Link to="/donate">Donate</Link>
							</li>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Nav;
