import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";

import { auth, sendPasswordReset } from "../../api/firebase";
import Header from "../layouts/Header";

const Reset = () => {
	let navigate = useNavigate();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [name, setName] = useState("");
	const [user, loading, error] = useAuthState(auth);

	const reset = async () => {
		if (email.length <= 0) {
			alert("email is invalid");
			return;
		}

		sendPasswordReset(email);

		navigate("/login");
	};

	useEffect(() => {
		if (loading) return;
	}, [user, loading]);

	return (
		<>
			<Header />
			<div className="aaa">
				<h1>Reset Password</h1>
			</div>

			<div className="block-wrapper">
				<div className="block login-container">
					<div className="login-content">
						<input
							className="login__textBox"
							placeholder="email"
							type="text"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<button className="login__btn" onClick={reset}>
							Reset Password
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default Reset;
