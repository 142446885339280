import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import SiteLogo from "./SiteLogo";
import Nav from "./Nav";

const Header = () => {
	let navigate = useNavigate();

	useEffect(() => {
		//! restricts access to the site for production
		if (process.env.NODE_ENV !== "development") {
			// navigate('/maintenance');
		}
	}, []);

	return (
		<header>
			<div className="header-wrapper">
				<div className="logo-box">
					<SiteLogo />
				</div>

				<Nav />
			</div>
		</header>
	);
};

export default Header;
