import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <div className="page page-faq">

            <Header />

            <h1>Page not found</h1>

            <div className="block-wrapper">
                <p>Looks like this page do not exists.</p>
                <p>Try clicking on the links on top menu / navigation links.</p>
            </div>
            
            <Footer />

        </div>
    )
}

export default NotFoundPage;