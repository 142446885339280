import { initializeApp } from "firebase/app";
import {
	GoogleAuthProvider,
	getAuth,
	signInWithPopup,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
	signOut,
} from "firebase/auth";
import {
	getFirestore,
	query,
	getDocs,
	collection,
	where,
	addDoc,
} from "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyBdMqY0mmLVcK7-JRpE0dkVfm1ixOtQ1Ow",
	authDomain: "namawali-24ab8.firebaseapp.com",
	projectId: "namawali-24ab8",
	storageBucket: "namawali-24ab8.appspot.com",
	messagingSenderId: "148396375578",
	appId: "1:148396375578:web:f8098be4d866f65bcd1ba3",
	measurementId: "G-2FHWBTN4LQ",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
	let result = "";
	try {
		const res = await signInWithPopup(auth, googleProvider);
		const user = res.user;
		const q = query(collection(db, "users"), where("uid", "==", user.uid));
		const docs = await getDocs(q);

		if (docs.docs.length === 0) {
			//TODO : solve permission issue, user can't write to users collection
			// await addDoc(collection(db, "users"), {
			//     uid: user.uid,
			//     name: user.displayName,
			//     authProvider: "google",
			//     email: user.email,
			// });
		}

		if (user) {
			localStorage.setItem("photoUrl", user.photoURL);
			localStorage.setItem("userId", user.uid);
			localStorage.setItem("userDisplayName", user.displayName);
			localStorage.setItem("userEmail", user.email);
			localStorage.setItem("accessToken", user.accessToken);
			return user;
		}
	} catch (err) {
		// console.error(err);
		console.log(err.message);
		switch (err.message) {
			case "Firebase: Error (auth/popup-closed-by-user).":
				result = "Google popup was closed without sign in.";
				break;
			default:
				result = err.message;
				break;
		}
	}
	return result;
};

const logInWithEmailAndPassword = async (email, password) => {
	let result = "";
	try {
		const res = await signInWithEmailAndPassword(auth, email, password);
		const user = res.user;

		if (user) {
			let userId = user.id;
			if (userId == undefined) userId = user.reloadUserInfo.localId;
			localStorage.setItem("userId", userId);
			localStorage.setItem("userDisplayName", user.displayName);
			localStorage.setItem("userEmail", user.email);
			localStorage.setItem("accessToken", user.accessToken);

			result = user;
		}
	} catch (err) {
		console.error(err);
		// alert('Wrong Email / Password entered, User was not found.');
		result = err.message;
		switch (err.message) {
			case "Firebase: Error (auth/wrong-password).":
				result =
					"Email or password did not match. If you forgot your password, click link below";
				break;
			default:
				result = err.message;
				break;
		}
	}

	return result;
};

const registerWithEmailAndPassword = async (name, email, password) => {
	try {
		const res = await createUserWithEmailAndPassword(auth, email, password);
		const user = res.user;
		await addDoc(collection(db, "users"), {
			uid: user.uid,
			name,
			authProvider: "local",
			email,
		});
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};

const sendPasswordReset = async (email) => {
	try {
		await sendPasswordResetEmail(auth, email);
		alert("Password reset link sent!");
	} catch (err) {
		console.error(err);
		alert(err.message);
	}
};

const logout = () => {
	signOut(auth);
};

export {
	auth,
	db,
	signInWithGoogle,
	logInWithEmailAndPassword,
	registerWithEmailAndPassword,
	sendPasswordReset,
	logout,
};
