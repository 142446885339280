const live_url = "https://namawaliserver.netlify.app/api";
const local_url = "http://localhost:5002";

const backend_url =
	process.env.NODE_ENV !== "development" ? live_url : local_url;

const URLS = {
	base_url: backend_url,
	api_version: "/v1/api",
	global: {
		limbu: "rjk8xQDh6fyjUue35oiK",
	},
	admin: {
		base: "/admin",
		userRoles: "/users", // get list of admin users only
	},
	castes: {
		base: "/castes",
		subcastes: "/castes/subcastes",
	},
	donate: {
		base: "/checkout",
		session: "/checkout/info",
	},
	people: {
		base: "/people",
		person: "/person",
		child: "/child",
	},
	person: {
		base: "/person",
		addChild: "/add-child",
		bookmark: "/bookmark",
		remove: "/remove-person",
		single: "/single", // get single person info for showing in tree (single-tree page)
	},
	suggestions: {
		base: "/suggestions",
	},
	user: {
		info: "/user/info",
	},
};

export default URLS;
