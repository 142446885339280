import { useEffect, useState } from "react";
import axios from "axios";

import Header from "../../layouts/Header.jsx";
import Footer from "../../layouts/Footer.jsx";

import Images from "../../../api/imagesConfig";
import { Link } from "react-router-dom";
import URLS from "../../../api/url.js";

const SuccessPage = () => {
	let coffeeImg = Images.icons["coffee"];

	const [donationOrderId, setDonationOrderId] = useState("");
	const [session, setSession] = useState(null);

	/**
	 * Check : donation was actually successful by
	 *  - checking webhook was called
	 *  - checking donation transaction was created in our db
	 *
	 * @param {int} donationId
	 */
	const checkDonation = (donationId) => {
		//TODO: call to our backend to verify above list
		// type and orderId will be passed in the query string
	};

	/**
	 * Get stripe session info
	 *  - if session is available, we also store the donation information in firestore collection
	 * @param {string} sessionId
	 * @returns
	 */
	const getStripeSessonInfo = (sessionId) => {
		const url = URLS.base_url + URLS.donate.session;

		const userEmail = localStorage.getItem("userEmail");

		let sendData = {
			email: userEmail,
			sessionId: sessionId,
		};

		const headers = {};

		axios.post(url, sendData, headers).then((res) => {
			const result = res.data;
			if (res.data.success) {
				setSession(res.data.session);
			}
		});
	};

	useEffect(() => {
		let donationId = 1;
		checkDonation(donationId);

		// get session id from query string
		const urlParams = new URLSearchParams(window.location.search);
		const sessionId = urlParams.get("sid");
		if (sessionId) {
			getStripeSessonInfo(sessionId);
		}
	}, []);

	return (
		<div className="page page-donate-cancel">
			<Header />

			<div className="block-wrapper">
				<div className="donate-container block">
					<h1>Donation Success</h1>

					<div className="coffee-icon">
						<img src={coffeeImg} alt="coffee thanks" />
					</div>

					<p>Thank you for your kind donation.</p>

					{donationOrderId ? (
						<p>
							Here is your donation ID {donationOrderId} which you
							can use to verify it in future.
						</p>
					) : (
						<></>
					)}

					<Link to="/caste/select" className="btn">
						Continue to search
					</Link>
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default SuccessPage;
