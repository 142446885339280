import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import cytoscape from "cytoscape";
import elk from "cytoscape-elk";
import { FiEye } from "react-icons/fi";

import Header from "../../../layouts/Header";
import { cyStyle, cyStyleSingle } from "../../../../data/tree";
import {
	convertToTreeData,
	findDataForSinglePerson,
	getPeople,
} from "../../../../api/people";
import Legends from "../Legends";
import Footer from "../../../layouts/Footer";
import SignInPopup from "../../../layouts/SignInPopup";

/**
 * Show Tree for a single person family
 *  - used for smaller less complex view
 *  - can be used to show this page when clicking on "Open My Tree"
 *  - .. instead of showing the full tree
 */

const SingleTree = () => {
	const [searchParams] = useSearchParams();
	let navigate = useNavigate();
	const lang = "english";

	const [people, setPeople] = useState([]);
	const [peopleDictionary, setPeopleDictionary] = useState([]);
	const [treeData, setTreeData] = useState([]);
	const [treeLink, setTreeLink] = useState("");

	// create cytoscape obj
	const createCyObj = () => {
		let treeElement = document.getElementById("cy");

		if (treeElement === null) return;

		cytoscape({
			container: treeElement, // container to render in

			elements: treeData,

			style: cyStyleSingle,

			layout: {
				name: "elk",
				directed: true,
				elk: {
					"elk.direction": "DOWN",
					algorithm: "mrtree",
					nodeOrdering: "default",
				},
				padding: 10,
				spacingFactor: 1.5,
				avoidOverlap: true,
				nodeDimensionsIncludeLabels: true,
			},

			minZoom: 0.5,
			maxZoom: 2,
		});
	};

	useEffect(() => {
		if (treeData.length <= 0) return;
		cytoscape.use(elk);
		createCyObj();
	}, [treeData]);

	useEffect(() => {
		if (people.length > 0) {
			let personId = searchParams.get("id");
			const personData = findDataForSinglePerson(people, personId);
			const tempTreeData = convertToTreeData(personData);
			const res = processPeople(tempTreeData, lang);
			const cryListWithNodesEdges = res.list;
			setPeopleDictionary(res.dictionary);
			setTreeData(cryListWithNodesEdges);
		}
	}, [people]);

	useEffect(() => {
		let personId = searchParams.get("id");
		let lang = searchParams.get("lang");
		let casteId = searchParams.get("caste");

		if (casteId === null) return;

		const treeParams = `id=${personId}&caste=${casteId}&lang=${lang}`;

		setTreeLink(`tree?${treeParams}`);

		getPeople({
			onPeopleFound: (tempPeople) => {
				setPeople(tempPeople);
			},
			onFail: (error) => {
				navigate("/login?redirect=access");
			},
			casteId: casteId,
		});
	}, []);

	return (
		<div className="page">
			<Header />

			<div className="block-wrapper">
				<h1>My Tree</h1>

				{treeLink.length > 0 && (
					<a href={treeLink} className="btn btn-primary btn-view">
						<span>
							<FiEye size={22} />
						</span>
						<span className="text">View full tree</span>
					</a>
				)}

				{/* Cytoscape Container - canvas */}
				<div id="cy"></div>
			</div>

			<div className="space-block"></div>

			<Legends />

			<Footer />

			<SignInPopup />
		</div>
	);
};

function processPeople(people, lang) {
	if (people.length <= 0) return;

	var list = {
		nodes: [],
		edges: [],
	};

	var tempPeopleDictionary = [];

	// sort people array by order
	people.sort(function (a, b) {
		return a.order - b.order;
	});

	// create nodes
	people.map((item, key) => {
		let fullName = capitalize(item.firstname);
		let nameDisplay = "";
		if (lang === "nepali") {
			fullName = item.firstname_nepali;
		}
		if (item.generation !== undefined) {
			if (item.generation) {
				nameDisplay += `${item.generation}. `;
			}
		}
		nameDisplay += fullName;
		let itemOrder = item.order;
		if (item.order !== undefined) {
			nameDisplay += ` ( ${itemOrder} )`;
		}
		let node = {
			data: {
				id: item.id,
				name: item.firstname,
				label: nameDisplay,
				spouse: item.spouse,
				gender: item.gender,
				order: itemOrder,
			},
		};

		if (typeof item.spouse !== "undefined") {
			node["data"]["wife"] = "yes";
		}
		list["nodes"].push(node);
		tempPeopleDictionary[item.id] = item;
		return "";
	});

	// create edges
	people.map((item) => {
		// edge for father and son
		if (item.father.length) {
			if (typeof tempPeopleDictionary[item.father] !== "undefined") {
				let edge = {
					data: {
						id: `${item.father}-${item.id}`,
						source: item.father,
						target: item.id,
						relation: "children",
					},
				};
				list["edges"].push(edge);
			}
		}
		// edge for husband and wife
		if (
			typeof item.spouse !== "undefined" &&
			item.spouse.length &&
			typeof tempPeopleDictionary[item.spouse] !== "undefined"
		) {
			let edge2 = {
				data: {
					id: `${item.spouse}-${item.id}`,
					source: item.spouse,
					target: item.id,
					relation: "married",
				},
			};
			list["edges"].push(edge2);
		}
		return "";
	});

	return {
		list: list,
		dictionary: tempPeopleDictionary,
	};
}

const capitalize = (str) => {
	if (str === undefined) return "";
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export default SingleTree;
