export function debounce(func, delay = 200) {
	let timeoutId;
	return function (...args) {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		timeoutId = setTimeout(() => {
			timeoutId = null;
			func.apply(this, args);
		}, delay);
	};
}
