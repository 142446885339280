import { useState, useEffect, useMemo, useCallback } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import SignInPopup from "../layouts/SignInPopup";
import URLS from "../../api/url";
import { cypherMessage } from "../utilities/CypherMessage";
import { resetUserData } from "../utilities/Reset";
import { debounce } from "../utilities/debounce";

const SearchPage = () => {
	let navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	const [casteId, setCasteId] = useState("");
	const [searchOptions, setSearchOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [message, setMessage] = useState("");

	const [sortedPersonList, setSortedPersonList] = useState([]);
	const [resultView, setResultView] = useState(<></>);

	const handlePersonClick = (person) => {
		// go to tree page
		navigate(
			"/tree?id=" + person.id + "&caste=" + casteId + "&lang=english"
		);
	};

	const getPeople = () => {
		if (casteId.length <= 0) return;
		const limbuCasteId = URLS.global.limbu;
		const url =
			URLS.base_url +
			URLS.people.base +
			"?caste=" +
			limbuCasteId +
			"&subcaste=" +
			casteId;
		const data = [];
		const token = localStorage.getItem("accessToken");

		if (token === null) return [];

		const headers = {
			headers: { authorization: `Bearer ${token}` },
		};

		axios
			.get(url, headers)
			.then((res) => {
				const result = res.data;
				if (res.data.success) {
					localStorage.setItem(`people-${casteId}`, result.people);
					let message = cypherMessage(result.people);
					let tempPeople = JSON.parse(message);
					setSearchOptions(tempPeople);
				} else {
					setSearchOptions([]);
					setMessage("There are no people records right now.");
				}

				setIsLoading(false);
			})
			.catch((error) => {
				if (error.code == "ERR_BAD_REQUEST") {
					// access token has expired
					console.error("access token has expired, relogin");
					resetUserData(navigate);
				}
			});
	};

	useEffect(() => {
		let casteIdFromUrl = searchParams.get("caste");
		setCasteId(casteIdFromUrl);
	}, []);

	useEffect(() => {
		let localPeople = localStorage.getItem(`people-${casteId}`);
		if (localPeople !== null) {
			let message = cypherMessage(localPeople);
			let tempPeople = JSON.parse(message);
			setSearchOptions(tempPeople);
			setIsLoading(false);
		} else {
			getPeople();
		}
	}, [casteId]);

	const debounceSearch = useCallback(
		debounce((value) => {
			findPerson(value);
		}, 300),
		[sortedPersonList]
	);

	const findPerson = async (searchTerm) => {
		let tempSearchOptions = sortedPersonList;

		const view = tempSearchOptions
			.filter((person) => {
				if (searchTerm === "") {
					return "";
				} else if (
					person.firstname
						.toLowerCase()
						.includes(searchTerm.toLowerCase())
				) {
					return person;
				}
			})
			.map((person, index) => {
				const father = searchOptions.filter((item) => {
					return item.id === person.father;
				});
				const spouse = searchOptions.filter((item) => {
					return item.id === person.spouse;
				});
				let fatherName = father.length ? father[0].firstname : "";
				let pronoun = person.gender == "male" ? "Son" : "Daughter";
				if (person.spouse != undefined) {
					pronoun = "Wife";
					fatherName = spouse.length ? spouse[0].firstname : "";
				}

				let generation = "";
				if (person.generation != undefined)
					generation = " - " + person.generation;

				return (
					<div
						key={index}
						className="search-item"
						onClick={() => handlePersonClick(person)}
					>
						<h3>
							{person.firstname}
							<span>{generation}</span>
						</h3>
						<p>
							{pronoun} of <span>{fatherName}</span>
						</p>
					</div>
				);
			});

		setResultView(view);
	};

	useEffect(() => {
		let list = searchOptions;
		list = searchOptions.sort((a, b) =>
			a.firstname.localeCompare(b.firstname)
		);
		setSortedPersonList(list);
	}, [searchOptions]);

	return (
		<div className="page">
			<Header />

			{isLoading === true ? (
				<div className="loader-container">
					<span className="loader"></span>
				</div>
			) : (
				<>
					<h1 className="step-header">Step 2. Search for a Person</h1>

					<div className="stepper-wrapper">
						<div className="stepper-item completed">
							<div className="step-counter">1</div>
							<div className="step-name">Select subcaste</div>
						</div>
						<div className="stepper-item completed">
							<div className="step-counter">2</div>
							<div className="step-name">Select person</div>
						</div>
						<div className="stepper-item active">
							<div className="step-counter">3</div>
							<div className="step-name">
								View the full family tree
							</div>
						</div>
					</div>

					<div className="block-wrapper">
						<div className="block block-search">
							{message.length ? (
								<>
									<p>{message}</p>
									<Link to="/caste/select" className="btn">
										Go back
									</Link>
								</>
							) : (
								<></>
							)}

							<input
								placeholder="Type person's name"
								onChange={(event) => {
									debounceSearch(event.target.value);
								}}
								className="form-item-search"
							/>

							<div className="result-area">{resultView}</div>
						</div>
					</div>
				</>
			)}

			<Footer />

			<SignInPopup />
		</div>
	);
};

export default SearchPage;
