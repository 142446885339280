import Header from "../../layouts/Header.jsx";
import Footer from "../../layouts/Footer.jsx";
import { useUser } from "../../utilities/UserContext.jsx";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import URLS from "../../../api/url.js";
import { USERS_AND_ROLES_RESPONSE } from "../../../data/user_roles.js";
import axios from "axios";

const UserRolesPage = () => {
	let navigate = useNavigate();
	const { currentUser } = useUser();

	const [data, setData] = useState([]);

	const getUserAndRoles = async () => {
		const token = currentUser.accessToken;
		const url = URLS.base_url + URLS.admin.base + URLS.admin.userRoles;

		if (token === null) return [];

		const headers = {
			headers: { authorization: `Bearer ${token}` },
		};

		axios.get(url, headers).then((res) => {
			const result = res.data;
			if (res.data.success) {
				setData(result.users);
			}
		});

		// setData(USERS_AND_ROLES_RESPONSE.users);
	};

	useEffect(() => {
		if (currentUser !== null) {
			// Fetch user roles if user is logged in
			getUserAndRoles();
		}
	}, [currentUser]);

	useEffect(() => {
		if (localStorage.getItem("userId") === null) {
			navigate("/login");
		}
	}, []);

	return (
		<div>
			<Header />

			<div className="block-wrapper">
				<div className="user-roles-container block">
					{currentUser ? (
						<TableRenderer data={data} />
					) : (
						<div>loading...</div>
					)}
				</div>
			</div>

			<Footer />
		</div>
	);
};

const TableRenderer = ({ data }) => {
	console.log("data", data);
	return (
		<div className="container mx-auto p-4">
			<h1 className="text-2xl font-bold mb-4">User Roles</h1>
			<div className="overflow-x-auto">
				<table className="table">
					<thead>
						<tr>
							<th>Person ID</th>
							<th>Caste ID</th>
							<th>Email</th>
							<th>Surname</th>
							<th>Role</th>
						</tr>
					</thead>
					<tbody>
						{data.length > 0 ? (
							data.map((item) => (
								<tr key={item.id} className="hover:bg-gray-50">
									<td className="py-2 px-4 border-b">
										{item.person_id}
									</td>
									<td className="py-2 px-4 border-b">
										{item.caste_id}
									</td>
									<td className="py-2 px-4 border-b">
										{item.email}
									</td>
									<td className="py-2 px-4 border-b">
										{item.surname}
									</td>
									<td className="py-2 px-4 border-b">
										{item.role}
									</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan={4}>No data available</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default UserRolesPage;
