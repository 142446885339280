import { useNavigate } from "react-router-dom";

import images from "../../api/imagesConfig.js";

const SiteLogo = () => {

    let treeImageUrl = images.icons['tree'];
    let navigate = useNavigate();

    const gotoHome = () => {
        navigate('/');
    }
    
    return (
        <div className="site-title" onClick={gotoHome}>

            <div>
                <img src={treeImageUrl} className="icon-tree" />
            </div>
            <div>
                <h1 className="title">Namawali</h1>
            </div>
            
        </div>
    )
}

export default SiteLogo;