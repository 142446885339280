import React from "react";

import images from "../../api/imagesConfig";

function FeatureList() {
	let familyTreeImg = images.features["familyTree"];
	let historyLineImg = images.features["historyLine"];
	let saveAsImageImg = images.features["saveAsImage"];
	let searchImg = images.features["search"];

	return (
		<div className="block-wrapper block-wrapper-features">
			<div className="block-features">
				<div className="feature-item">
					<div className="left-side">
						<h2>Family Tree</h2>
						<p>
							You can view your family tree in a graphical format.
							You can search for your subcaste and find your
							family members.
						</p>
						<p>
							You can zoom in and out using either mouse or pinch
							gesture in your mobile view. Person nodes can also
							be dragged to move around.
						</p>
					</div>
					<div className="right-side">
						<img src={familyTreeImg} alt="family-tree" />
					</div>
				</div>
				<div className="feature-item">
					<div className="left-side">
						<h2>Search Person</h2>
						<p>
							You can search for a specific person by typing their
							name in the search page. Although you will have to
							first select the limbu subcaste.
						</p>
						<p>
							If you find multiple person by the same first name,
							you can view their generation number at start or
							checkout their father's name to identify the correct
							person.
						</p>
					</div>
					<div className="right-side">
						<img src={searchImg} alt="search-person" />
					</div>
				</div>
				<div className="feature-item">
					<div className="left-side">
						<h2>History Line</h2>
						<p>
							Quickly view your history line in a single list. By
							selecting a person from tree page, it will show a
							popup box that will show the list of family line.
						</p>
						<p>
							You can also click on any of the forefather name and
							jump to that part of the family tree.
						</p>
					</div>
					<div className="right-side">
						<img src={historyLineImg} alt="history-line" />
					</div>
				</div>
				<div className="feature-item">
					<div className="left-side">
						<h2>Save as Image</h2>
						<p>
							You can save your family tree as an image and share
							it with your family members.
						</p>
						<p>
							Go to tree page after searching through the person
							search page, then click on download icon on top
							right of the tree graph. This will download the
							current visible tree as an image.
						</p>
					</div>
					<div className="right-side">
						<img src={saveAsImageImg} alt="save-as-image" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default FeatureList;
