import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link } from "react-router-dom";
import FeatureList from "../features/FeatureList";

const FeaturesPage = () => {
	return (
		<div className="page page-faq">
			<Header />

			<h1>Features</h1>

			<FeatureList />

			<Footer />
		</div>
	);
};

export default FeaturesPage;
