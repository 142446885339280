import React, { useEffect, useRef, useState } from "react";
import Konva from "konva";
import Header from "../layouts/Header";
import { testPeopleBigList, testPeopleSmallerList } from "./Tree/testData";

const colorPalaette = {
	blue: "#1878E8",
	pink: "#ff59d6",
	purple: "#6558F5",
	green: "#1AAE9F",
	orange: "#D97236",
	grey: "#BFBFBF",
	lightgrey: "#D8D8D8",
	black: "#3F3F3F",
	white: "#FFFFFF",
};

let colorList = Object.keys(colorPalaette).map(function (key) {
	return colorPalaette[key];
});

export default function TreeSamplePage() {
	const divRef = useRef(null);

	const [stage, setStage] = React.useState(null);
	const [rootGroup, setRootGroup] = React.useState(null); // group that contains all the people
	const [layer, setLayer] = React.useState(null); // layer for drawing people
	const [layerLines, setLayerLines] = React.useState(null); // layer that contains lines
	const [fillColor, setFillColor] = React.useState(colorPalaette.blue);
	const [lineColor, setLineColor] = React.useState("black");
	const [scaleX, setScaleX] = React.useState(1);
	const [scaleY, setScaleY] = React.useState(1);
	const [childrenGap, setChildrenGap] = React.useState(50);
	const [childrenYGap, setChildrenYGap] = React.useState(160);
	const [tileWidth, setTileWidth] = React.useState(200);
	// const [ people, setPeople ] = React.useState([]);
	const [hero, setHero] = useState(null);

	// const people = testPeopleSmallerList;
	const people = testPeopleBigList;

	const getCorrectBoxColor = (person, level) => {
		let color = fillColor;

		switch (level) {
			case 0:
				// self
				return colorPalaette.blue;
				break;
			case 1:
				// children
				return colorPalaette.green;
				break;
			case -1:
				// parents
				return colorPalaette.purple;
				break;
		}

		return color;
	};

	// prepare family data for a person, grouping parents, spouses, children and grand children in array
	const prepareFamily = (person) => {
		person.parents = [];
		person.children = [];
		person.grandChildren = [];
		person.spouses = [];

		person.children = people.filter((p) => p.father === person.id);
		let father = people.find((p) => p.id === person.father);
		let mothers = people.filter((p) => p.spouse === person.father);
		person.parents = [father, ...mothers];
		person.parentIds = person.parents.map((p) => p.id);

		let childrenIds = person.children.map((p) => p.id);
		person.grandChildren = people.filter((p) =>
			childrenIds.includes(p.father)
		);
		let grandChildrenIds = person.grandChildren.map((p) => p.id);
		person.grandGroup = [];
		person.grandChildren.map((p) => {
			let father = people.find((p1) => p1.id === p.father);
			let mother = people.find((p1) => p1.id === p.mother);

			let group = person.grandGroup.find(
				(g) => g.father.id === father.id
			);
			if (group) {
				group.children.push(p);
			} else {
				group = {
					father: father,
					fatherId: father.id,
					children: [p],
				};
				person.grandGroup.push(group);
			}
		});

		person.childrenIds = childrenIds;
		person.grandChildrenIds = grandChildrenIds;

		let spouses = people.filter((p) => p.spouse === person.id);
		person.spouses = spouses;
		person.spousesIds = [];
		person.spouses.map((p) => {
			person.spousesIds.push(p.id);
		});

		setHero(person);
	};

	// create person at position x,y and add to the group
	// xOrigin, yOrigin is the origin of the starting point (is never changed)
	const drawFullPerson = (person, x, y, level, xOrigin, yOrigin) => {
		// console.log(person.childrenIds)
		let boxColor = getCorrectBoxColor(person, level);
		let group = new Konva.Group({
			id: "person-" + person.id,
		});

		let labelBox = drawLabelWithRect(person, x, y, "white", boxColor, 20);
		group.add(labelBox);
		person.group = group;
		person.groupWidth = group.getClientRect({ skipShadow: true }).width;
		person.groupChildren = [];
		person.groupParents = [];
		layer.add(group);

		// ! Restrict certain levels
		// * ignore children lower than level 2, also ignore parent's child because they are already drawn
		if (level > 1 || level < 0) {
			return group;
		}

		// if (level >=1 ) return group;

		// children
		let prevChild = null;
		let prevX = x;
		let prevChildWidth = 0;

		let prevSiblingChildrenW = 0;
		if (level > 0) {
			if (person.order === 1) {
				person.prevChildGroupW = 0;
			} else {
				// find siblings
				let siblings = people.filter((p) => p.father === person.father);
				for (var i = 0; i < siblings.length; i++) {
					// find total distance to start with using children of previous siblings
					if (siblings[i].order < person.order) {
						// console.log(siblings[i].firstname + ' is previous sibling of me ' + person.firstname);
						// find children of previous sibling
						let prevSiblingChildren = people.filter(
							(p) => p.father === siblings[i].id
						);
						prevSiblingChildrenW +=
							prevSiblingChildren.length * tileWidth +
							prevSiblingChildren.length * childrenGap;
					}
				}

				person.prevChildGroupW = prevSiblingChildrenW;
			}
		}

		let children = people.filter((p) => p.father === person.id);
		children.map((child, index) => {
			let gap = childrenGap;
			let xPos = xOrigin + index * tileWidth + prevSiblingChildrenW;

			if (prevSiblingChildrenW !== 0) {
				xPos += gap * (person.order - 1);
			}
			if (index > 0) {
				xPos += gap * index;
			}
			// console.log('drawing ' + child.firstname + ' at ' + xPos + ' and prev W is ' + prevSiblingChildrenW); // + ' and tileWidth is ' + tileWidth)
			let childGroup = drawFullPerson(
				child,
				xPos,
				y + childrenYGap,
				level + 1,
				xOrigin,
				yOrigin
			);

			prevChild = childGroup;
			person.groupChildren.push(childGroup.id());
		});

		// console.log('- - -')

		// return group;

		// ! do not need to draw parents for level other than 0, level 0 is the hero
		if (level !== 0) return group;

		// reset for parent calculations
		prevChild = null;
		prevX = x;
		prevChildWidth = 0;

		let parents = [];
		let father = people.find((p) => p.id === person.father);
		if (father) parents.push(father);
		let mother = null;
		if (father) {
			mother = people.find((p) => p.spouse === father.id);
			if (mother) parents.push(mother);
		}
		parents.map((child, index) => {
			let gap = childrenGap;
			if (index > 0 && prevChild) {
				prevChildWidth = prevChild.getClientRect({
					skipShadow: true,
				}).width;
				prevX = prevChild.getClientRect({ skipShadow: true }).x;
			} else {
				gap = 0;
			}
			let xPos = prevX + prevChildWidth + gap;
			let childGroup = drawPerson(child, xPos, y - childrenYGap, -1);

			prevChild = childGroup;
			person.groupParents.push(childGroup.id());

			// draw path from hero to parents
			let poly = new Konva.Line({
				points: [0, 0, 100, 100, 100, 200],
				stroke: "#262626",
				strokeWidth: 5,
				closed: false,
			});
			group.add(poly);
		});

		// * Spouse
		let spouses = people.filter((p) => person.id === p.spouse);
		if (spouses.length <= 0) return group;
		prevChild = null;
		prevX = x + person.groupWidth + childrenGap;
		prevChildWidth = 0;
		spouses.map((spouse, index) => {
			let gap = childrenGap;
			if (index > 0 && prevChild) {
				prevChildWidth = prevChild.getClientRect({
					skipShadow: true,
				}).width;
				prevX = prevChild.getClientRect({ skipShadow: true }).x;
			} else {
				gap = 0;
			}
			let xPos = prevX + prevChildWidth + gap;
			let childGroup = drawPerson(spouse, xPos, y, -1);

			prevChild = childGroup;
			person.groupParents.push(childGroup.id());
		});

		return group;
	};

	// create person at position x,y and add to the group
	const drawPerson = (person, x, y, level) => {
		// console.log(person.childrenIds)
		let boxColor = getCorrectBoxColor(person, level);
		let group = new Konva.Group({
			id: "person-" + person.id,
		});

		let labelBox = drawLabelWithRect(person, x, y, "white", boxColor, 20);
		group.add(labelBox);
		person.group = group;
		person.groupWidth = group.getClientRect({ skipShadow: true }).width;
		person.groupChildren = [];
		person.groupParents = [];
		layer.add(group);

		// ! Restrict certain levels
		// * ignore children lower than level 2, also ignore parent's child because they are already drawn
		if (level > 1 || level < 0) return group;

		// children
		let prevChild = null;
		let prevX = x;
		let prevChildWidth = 0;

		let children = people.filter((p) => p.father === person.id);
		children.map((child, index) => {
			let gap = childrenGap;
			if (index > 0 && prevChild) {
				prevChildWidth = prevChild.getClientRect({
					skipShadow: true,
				}).width;
				prevX = prevChild.getClientRect({ skipShadow: true }).x;
			} else {
				gap = 0;
			}
			let xPos = prevX + prevChildWidth + gap;
			let childGroup = drawPerson(
				child,
				xPos,
				y + childrenYGap,
				level + 1
			);

			prevChild = childGroup;
			person.groupChildren.push(childGroup.id());
		});

		// ! do not need to draw parents for level other than 0
		if (level !== 0) return group;

		// reset for parent calculations
		prevChild = null;
		prevX = x;
		prevChildWidth = 0;

		let parents = [];
		let father = people.find((p) => p.id === person.father);
		if (father) parents.push(father);
		let mother = null;
		if (father) {
			mother = people.find((p) => p.spouse === father.id);
			if (mother) parents.push(mother);
		}
		parents.map((child, index) => {
			let gap = childrenGap;
			if (index > 0 && prevChild) {
				prevChildWidth = prevChild.getClientRect({
					skipShadow: true,
				}).width;
				prevX = prevChild.getClientRect({ skipShadow: true }).x;
			} else {
				gap = 0;
			}
			let xPos = prevX + prevChildWidth + gap;
			let childGroup = drawPerson(child, xPos, y - childrenYGap, -1);

			prevChild = childGroup;
			person.groupParents.push(childGroup.id());
		});

		// * Spouse
		let spouses = people.filter((p) => person.id === p.spouse);
		if (spouses.length <= 0) return group;
		prevChild = null;
		prevX = x + person.groupWidth + childrenGap;
		prevChildWidth = 0;
		spouses.map((spouse, index) => {
			let gap = childrenGap;
			if (index > 0 && prevChild) {
				prevChildWidth = prevChild.getClientRect({
					skipShadow: true,
				}).width;
				prevX = prevChild.getClientRect({ skipShadow: true }).x;
			} else {
				gap = 0;
			}
			let xPos = prevX + prevChildWidth + gap;
			let childGroup = drawPerson(spouse, xPos, y, -1);

			prevChild = childGroup;
			person.groupParents.push(childGroup.id());
		});

		return group;
	};

	const drawTooltop = (person, x, y, boxColor, padding) => {
		const tooltipLabel = new Konva.Label({
			x: x + 40,
			y: y - 40,
			visible: false,
		});
		const tooltipText = new Konva.Text({
			text: person.firstname + " " + person.surname,
			fontSize: 18,
			fill: colorPalaette.white,
			padding: 10,
		});
		tooltipLabel.add(
			new Konva.Tag({
				fill: boxColor,
				cornerRadius: 5,
			})
		);
		tooltipLabel.add(tooltipText);
		layer.add(tooltipLabel);
	};

	// draw text with border and shadow, usually for a person
	const drawLabelWithRect = (person, x, y, textColor, boxColor, padding) => {
		const group = new Konva.Group();

		const text = new Konva.Text({
			x: 0,
			y: 0,
			text: person.firstname, // + ' ' + person.surname,
			fontSize: 20,
			fontFamily: "Roboto",
			fill: textColor,
			align: "center",
			padding: padding,
			width: tileWidth,
		});

		var simpleLabel = new Konva.Label({
			x: x,
			y: y,
			padding: 10,
			opacity: 1,
		});

		group.add(text);

		simpleLabel.add(
			new Konva.Tag({
				fill: boxColor,
				cornerRadius: 5,
				shadowColor: "black",
				shadowBlur: 4,
				shadowOffsetX: 4,
				shadowOffsetY: 4,
				shadowOpacity: 0.2,
			})
		);
		simpleLabel.add(text);

		group.add(simpleLabel);

		group.on("mouseover", function () {
			stage.container().style.cursor = "pointer";
			stage.batchDraw();
		});

		group.on("mouseout", function () {
			stage.container().style.cursor = "default";
			stage.batchDraw();
		});

		group.draggable(true);

		return group;

		// group.on('dragend', function() {
		//     stage.container().style.cursor = 'grab';
		//     drawLine(samplePerson, group);
		// });

		// group.on('dragmove', function() {
		//     stage.container().style.cursor = 'grabbing';
		// });

		// group.on('click', function() {
		//     stage.container().style.cursor = 'grab';
		// });
	};

	// draw a arrow connection between two people
	const drawArrow = (person1, person2) => {
		const group = new Konva.Group();

		let person1Rect = person1.getClientRect();
		let person2Rect = person2.getClientRect();

		let startX = person1Rect.x + person1Rect.width / 2;
		let startY = person1Rect.y + person1Rect.height;
		let endX = person2Rect.x + person2Rect.width / 2;
		let endY = person2Rect.y;

		const line = new Konva.Line({
			points: [startX, startY, endX, endY],
			stroke: "black",
			strokeWidth: 5,
			lineCap: "round",
			lineJoin: "round",
		});

		const arrow = new Konva.Arrow({
			x: endX,
			y: endY,
			points: [0, 0, 0, 0],
			pointerLength: 10,
			pointerWidth: 10,
			fill: "black",
			stroke: "black",
			strokeWidth: 4,
		});

		group.add(line);
		group.add(arrow);

		return group;
	};

	// 1. Draw line 1
	// parent : person who is parent
	// parentBox : label object that represents parent
	const drawLine = (parent, parentGroup) => {
		const height = 20;

		let parentRect = parentGroup.getClientRect();
		let startX = parentRect.x + parentRect.width / 2;
		let startY = parentRect.y + parentRect.height;
		let endX = parentRect.x + parentRect.width / 2;
		let endY = parentRect.y + parentRect.height + height;

		const line = new Konva.Line({
			points: [startX, startY, endX, endY],
			stroke: lineColor,
			strokeWidth: 5,
			lineCap: "round",
			lineJoin: "round",
		});

		return line;
	};

	// 2. horizontal lines according to number of children
	const drawLineHorChild = (parent, parentBox) => {
		const group = new Konva.Group();
		const height = 20;
		const width = childrenGap;
		const numOfChild = parent.children.length;

		let parentRect = parentBox.getClientRect();

		let direction = "left";
		let evenCount = 0;
		let oddCount = 0;
		for (let i = 0; i < numOfChild; i++) {
			let color = lineColor;
			let startX = parentRect.x + parentRect.width / 2;
			let startY = parentRect.y + parentRect.height + height;
			let endX =
				parentRect.x + parentRect.width / 2 - width * (evenCount + 1);
			if (direction === "right") {
				endX =
					parentRect.x +
					parentRect.width / 2 +
					width * (oddCount + 1);
			}
			let endY = startY;

			const line = new Konva.Line({
				points: [startX, startY, endX, endY],
				stroke: color,
				strokeWidth: 5,
				lineCap: "round",
				lineJoin: "round",
			});

			group.add(line);
			direction = direction === "right" ? "left" : "right";
			if (i % 2 === 0) evenCount++;
			else oddCount++;
		}

		return group;
	};

	// 3. Draw vertical lines according to number of children
	const drawLineVerChild = (parent, parentBox) => {
		const group = new Konva.Group();
		const height = 20; // height of the vertical line
		const width = childrenGap;
		const numOfChild = parent.children.length;

		let parentRect = parentBox.getClientRect();

		let direction = "left";
		let evenCount = 0;
		let oddCount = 0;
		for (let i = 0; i < numOfChild; i++) {
			let color = lineColor;
			let startX =
				parentRect.x + parentRect.width / 2 - width * (evenCount + 1);
			let startY = parentRect.y + parentRect.height + height;
			let endX = startX;
			if (direction === "right") {
				startX =
					parentRect.x +
					parentRect.width / 2 +
					width * (oddCount + 1);
				endX =
					parentRect.x +
					parentRect.width / 2 +
					width * (oddCount + 1);
			}
			let endY = startY + height;

			const line = new Konva.Line({
				points: [startX, startY, endX, endY],
				stroke: color,
				strokeWidth: 5,
				lineCap: "round",
				lineJoin: "round",
			});

			group.add(line);
			direction = direction === "right" ? "left" : "right";
			if (i % 2 === 0) evenCount++;
			else oddCount++;
		}

		return group;
	};

	// generate color randomly
	const randomColor = () => {
		return colorList[Math.floor(Math.random() * colorList.length)];
		// return '#'+(Math.random()*0xFFFFFF<<0).toString(16);
	};

	// sample box rotation animation
	const simpleAnimation = () => {
		var rect = new Konva.Rect({
			x: 100,
			y: 100,
			width: 100,
			height: 100,
			fill: "red",
			stroke: "black",
			strokeWidth: 4,
			draggable: true,
		});
		layer.add(rect);

		var anim = new Konva.Animation(function (frame) {
			var time = frame.time,
				timeDiff = frame.timeDiff,
				frameRate = frame.frameRate;

			// update stuff
			var angleDiff = (frame.timeDiff * 90) / 1000;
			rect.rotate(angleDiff);
		}, layer);
		anim.start();
	};

	useEffect(() => {
		const stageWidth = divRef.current ? divRef.current.offsetWidth : 1200;
		const stageHeight = divRef.current ? divRef.current.offsetHeight : 800;

		const stage = new Konva.Stage({
			container: "tree-canvas",
			width: stageWidth,
			height: stageHeight,
			draggable: true,
			scale: {
				x: scaleX,
				y: scaleY,
			},
		});
		setStage(stage);
	}, []);

	useEffect(() => {
		const layer1 = new Konva.Layer();
		const layer2 = new Konva.Layer();
		setLayer(layer1);
		setLayerLines(layer2);
	}, [stage]);

	useEffect(() => {
		if (stage) {
			let centerX = stage.width() / 2;
			let personWidth = 100; // assumed average width of person
			let level = 0;
			let tekPerson = people.find((p) => p.firstname === "JasBahadur"); // Tek
			prepareFamily(tekPerson);
			drawFullPerson(tekPerson, 100, 240, level, 100, 240);
			// console.log(tekPerson)

			setScaleX(0.7);
			setScaleY(0.7);

			stage.add(layer);

			// simpleAnimation();
		}
	}, [stage]);

	useEffect(() => {
		if (stage) {
			stage.scale({ x: scaleX, y: scaleY });
		}
	}, [scaleX, scaleY]);

	return (
		<div className="page page-tree">
			<Header />

			<div className="block-wrapper">
				<div className="block block-transparent" ref={divRef}>
					<h1>Tree Sample Page</h1>

					<div id="tree-canvas"></div>
				</div>
			</div>
		</div>
	);
}
