import axios from "axios";
import { cypherMessage } from "../components/utilities/CypherMessage";
import URLS from "./url";

// get list of people from server
// - if it is already in local storage, use that
export const getPeople = ({ onPeopleFound, onFail, casteId }) => {
	if (casteId.length <= 0) return;
	const limbuCasteId = URLS.global.limbu;
	const url =
		URLS.base_url +
		URLS.people.base +
		"?caste=" +
		limbuCasteId +
		"&subcaste=" +
		casteId;
	const token = localStorage.getItem("accessToken");

	if (token === null) return [];

	const localPeople = localStorage.getItem("people-" + casteId);

	if (localPeople != null) {
		let message = cypherMessage(localPeople);
		let tempPeople = JSON.parse(message);
		onPeopleFound(tempPeople);
		return;
	}

	const headers = {
		headers: { authorization: `Bearer ${token}` },
	};

	axios
		.get(url, headers)
		.then((res) => {
			const result = res.data;
			if (res.data.success) {
				const cypheredPeople = result.people;
				let message = cypherMessage(cypheredPeople);
				let tempPeople = JSON.parse(message);
				onPeopleFound(tempPeople);
				localStorage.setItem("people-" + casteId, cypheredPeople);
			} else {
				onPeopleFound([]);
			}
		})
		.catch((error) => {
			if (error.code === "ERR_BAD_REQUEST") {
				// access token has expired
				console.error("access token has expired, relogin");
				onFail(error);
			}
		});
};

// find data for single person
// including its own data, parents and children
export const findDataForSinglePerson = (people, personId) => {
	if (people.length > 0) {
		let personData = people.find((person) => person.id === personId);
		if (personData) {
			let fatherData = people.find(
				(person) => person.id === personData.father
			);
			let motherData = people.find(
				(person) => person.id === personData.mother
			);
			let childrenData = people.filter(
				(person) => person.father === personId
			);
			// find person with name
			let wifeData = people.find((person) => person.spouse === personId);

			return {
				personData: personData,
				fatherData: fatherData,
				motherData: motherData,
				wifeData: wifeData,
				childrenData: childrenData,
			};
		}
	}
	return null;
};

export const convertToTreeData = (personData) => {
	let result = [];

	if (personData === null) return [];

	result.push(personData.personData);

	if (personData.fatherData !== undefined) {
		result.push(personData.fatherData);
	}
	if (personData.motherData !== undefined) {
		result.push(personData.motherData);
	}
	if (personData.wifeData !== undefined) {
		result.push(personData.wifeData);
	}
	if (personData.childrenData !== undefined) {
		personData.childrenData.map((child) => {
			result.push(child);
		});
	}

	return result;
};
