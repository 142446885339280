import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../../layouts/Header';
import URLS from '../../../api/url';
import axios from 'axios';
import { resetUserData } from '../../utilities/Reset';
import SignInPopup from '../../layouts/SignInPopup';

export default function Suggestions() {

    const [ suggestions, setSuggestions ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    let navigate = useNavigate();

    let renderItems = suggestions.map((item, index) => (
        <div key={index} className="block block-suggestions">
            <p className="suggestion-uid">{item.uid}</p>
            <p className="suggestion-msg">{item.suggestion}</p>
        </div>
    ));
    if (suggestions.length == 0) {
        renderItems = (
            <div className="block block-suggestions">
                No suggestions found.
            </div>
        )
    }

    const getSuggestions = () => {
        const url = URLS.base_url + URLS.suggestions.base;
        const data = [];
        const token = localStorage.getItem('accessToken');

        if (token === null)
         return [];

        const headers = {
            headers:  
            { authorization: `Bearer ${token}` }
        };

        axios.get(url, headers)
            .then((res) => {
                const result = res.data;
                if (res.data.success) {
                    setSuggestions(result.suggestions);
                } else {
                    setSuggestions([]);
                }

                setLoading(false);
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    console.error('you are not authorized to view this page');
                    setLoading(false);
                } else if (error.code == 'ERR_BAD_REQUEST') {
                    // access token has expired
                    console.error('access token has expired, relogin');
                    resetUserData(navigate);
                }
            });
    }

    useEffect(() => {
        getSuggestions();
    }, [])


    return (
        <div className="page page-suggestions">

            <Header />

            <h1>Suggestions</h1>

            <div className="block-wrapper">
                
                    
                    {loading ? (
                        <div className="block block-suggestions">loading...</div>
                    ) : (
                        <div>
                            {renderItems}
                        </div>
                    )}

            </div>

            <SignInPopup />

        </div>
    )
}
