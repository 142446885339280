import { getStorage, ref } from "firebase/storage";

export const cyElements1 = [
	// list of graph elements to start with
	{
		// node a
		data: {
			id: "grandfather",
			name: "grandfather",
			label: "Grandfather + Grandmother",
			spouse: "grandmother",
		},
	},
	{
		// node b
		data: {
			id: "father",
			name: "Father + Mother",
			label: "Father + Mother",
			spouse: "mother",
		},
	},
	{
		// edge ab
		data: {
			id: "grandfather-father",
			source: "grandfather",
			target: "father",
		},
	},
	{
		// node c
		data: { id: "son1", name: "son1" },
	},
	{
		// node c
		data: { id: "son2", name: "son2" },
	},
	{
		// node d
		data: { id: "garndson", name: "garndson" },
	},
	{
		// node e
		data: { id: "garndson2", name: "garndson2" },
	},
	{
		// node f
		data: { id: "garndson3", name: "garndson3" },
	},
	{
		// node g
		data: { id: "garndson4", name: "garndson4" },
	},
	{
		// edge ab
		data: { id: "father-son1", source: "father", target: "son1" },
	},
	{
		// edge ab
		data: { id: "father-son2", source: "father", target: "son2" },
	},
	{
		// edge ab
		data: { id: "son1-garndson", source: "son1", target: "garndson" },
	},
	{
		// edge ab
		data: { id: "son1-garndson2", source: "son1", target: "garndson2" },
	},
	{
		// edge ab
		data: { id: "son2-garndson3", source: "son2", target: "garndson3" },
	},
	{
		// edge ab
		data: { id: "son2-garndson4", source: "son2", target: "garndson4" },
	},
];

export const cyElements2 = [
	{
		// node b
		data: {
			id: "father",
			name: "father",
			label: "Father + Mother",
			spouse: "mother",
		},
	},
	{
		// node c
		data: { id: "son1", name: "son1" },
	},
	{
		// node c
		data: { id: "son2", name: "son2" },
	},
	{
		// edge ab
		data: { id: "father-son1", source: "father", target: "son1" },
	},
	{
		// edge ab
		data: { id: "father-son2", source: "father", target: "son2" },
	},
];

export const cyElements3 = [
	{
		// node x
		data: {
			id: "greatgrandfather",
			name: "greatgrandfather",
			label: "Great Grandfather + Great Grandmother",
			spouse: "greatgrandmother",
		},
	},
	{
		// node a
		data: {
			id: "grandfather",
			name: "grandfather",
			label: "Grandfather + Grandmother",
			spouse: "grandmother",
		},
	},
	{
		// edge xa
		data: {
			id: "greatgrandfather-grandfather",
			source: "greatgrandfather",
			target: "grandfather",
		},
	},
	{
		// node b
		data: {
			id: "father",
			name: "father",
			label: "Father + Mother",
			spouse: "mother",
		},
	},
	{
		// edge ab
		data: {
			id: "grandfather-father",
			source: "grandfather",
			target: "father",
		},
	},
	{
		// node c
		data: { id: "son1", name: "son1" },
	},
	{
		// node c
		data: { id: "son2", name: "son2" },
	},
	{
		// edge ab
		data: { id: "father-son1", source: "father", target: "son1" },
	},
	{
		// edge ab
		data: { id: "father-son2", source: "father", target: "son2" },
	},
];

export const cyElements1b = {
	nodes: [
		{ data: { id: "n1", foo: "one", weight: 0.25 }, classes: "odd one" },
		{ data: { id: "n2", foo: "two", weight: 0.5 }, classes: "even two" },
		{
			data: { id: "n3", foo: "three", weight: 0.75 },
			classes: "odd three",
		},
		{ data: { id: "n4", parent: "n5", foo: "bar" } },
		{ data: { id: "n5" } },
	],

	edges: [
		{
			data: { id: "n1n2", source: "n1", target: "n2", weight: 0.33 },
			classes: "uh",
		},
		{
			data: { id: "n2n3", source: "n2", target: "n3", weight: 0.66 },
			classes: "huh",
		},
		{ data: { id: "n1n1", source: "n1", target: "n1" } },
	],
};

const color_male = "#6558F5";
const color_female = "#1AAE9F";
const color_wife = "#ff59d6";
const color_edge = "#b6b3db";

export const cyStyle = [
	// the stylesheet for the graph
	{
		selector: "node",
		style: {
			"background-color": color_edge,
			label: "data(label)",
			"font-size": "18px",
			"font-weight": "normal",
			width: "30px",
			height: "30px",
		},
	},

	{
		selector: "#grandfather",
		style: {
			"background-color": color_male,
			label: "data(label)",
		},
	},

	{
		selector: 'node[gender="female"]',
		style: {
			"background-color": color_female,
		},
	},

	{
		selector: 'node[wife="yes"]',
		style: {
			"background-color": color_wife,
		},
	},

	{
		selector: 'edge[relation="married"]',
		style: {
			width: 3,
			"line-color": color_female,
			"target-arrow-color": color_female,
			"target-arrow-shape": "triangle",
			"curve-style": "bezier",
		},
	},

	{
		selector: "edge",
		style: {
			width: 3,
			"line-color": color_edge,
			"target-arrow-color": color_edge,
			"target-arrow-shape": "triangle",
			"curve-style": "bezier",
		},
	},
];

const image2 =
	"https://firebasestorage.googleapis.com/v0/b/namawali-24ab8.appspot.com/o/public%2Fman.png?alt=media&token=61c84ae4-d5c2-4b69-9adc-68f4542aedc7";

// const image = "https://live.staticflickr.com/7272/7633179468_3e19e45a0c_b.jpg";

const image = require("../images/people/man.png");

const imageMan2 = require("../images/people/man2.png");

export const cyStyleSingle = [
	// the stylesheet for the graph
	{
		selector: "node",
		style: {
			"background-image": `url(${image}) 0px 0px 30px 30px`,
			"background-size": "10px 10px",
			label: "data(label)",
			height: 80,
			width: 80,
			"background-fit": "cover",
			"border-color": "#000",
			"border-width": 3,
			"border-opacity": 0.5,
		},
	},

	// adding image to the node
	{
		selector: "#aL1bOqcJCxCAedOJZxDi",
		style: {
			"background-image": `url(${imageMan2}) 0px 0px 30px 30px`,
		},
	},

	{
		selector: "#grandfather",
		style: {
			"background-color": color_male,
			label: "data(label)",
		},
	},

	{
		selector: 'node[gender="female"]',
		style: {
			"background-color": color_female,
		},
	},

	{
		selector: 'node[wife="yes"]',
		style: {
			"background-color": color_wife,
		},
	},

	{
		selector: 'edge[relation="married"]',
		style: {
			width: 3,
			"line-color": color_female,
			"target-arrow-color": color_female,
			"target-arrow-shape": "triangle",
			"curve-style": "bezier",
		},
	},

	{
		selector: "edge",
		style: {
			width: 3,
			"line-color": color_edge,
			"target-arrow-color": color_edge,
			"target-arrow-shape": "triangle",
			"curve-style": "bezier",
		},
	},
];
