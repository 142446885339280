import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link } from "react-router-dom";

import { FaRegBookmark } from "react-icons/fa";

const FaqPage = () => {
	return (
		<div className="page page-faq">
			<Header />

			<h1>Frequently Asked Questions</h1>

			<div className="block-wrapper">
				<div className="block block-faq">
					<div className="faq-item">
						<p className="question">How to view the tree?</p>
						<p>
							First you need to{" "}
							<Link to="/login">Login Page</Link> using google
							account or email.
						</p>
						<p>
							Then, go to the{" "}
							<Link to="/caste/select">Search Page</Link>. Search
							for your limbu clan.
						</p>
						<p>
							In next page, you can search for person by typing
							their name.
						</p>
					</div>
					<div className="faq-item">
						<p className="question">
							Can I save a point in the tree?
						</p>
						<p>
							You can click on any person on the tree page and
							click <FaRegBookmark style={{ fontSize: 20 }} />{" "}
							<span>bookmark</span> icon to mark that person as
							yourself.
						</p>
						<p>
							Next time, after you login in your account page, you
							will get a direct link to open the tree with you
							loaded in the center starting point.
						</p>
					</div>
					<div className="faq-item">
						<p className="question">How can I help?</p>
						<p>
							You can help this platform by spreading the word to
							your friends and family.
						</p>
						<p>
							You can also donate small amount of money by
							clicking on the Donate page.
						</p>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default FaqPage;
