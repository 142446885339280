export const USERS_AND_ROLES_RESPONSE = {
	success: true,
	message: "got users",
	users: [
		{
			uid: "rcneTeZw8dg87o6XVh1oJsCYbPC1",
			caste_id: "nXdPMrlPsX8yHH4dUxah",
			surname: "neyong",
			person_id: "EdnRADUwhqxpovNJwtGw",
			id: "1WiDqfaRaqhiLt33vcuL",
			email: "tester@gmail.com",
		},
		{
			uid: "gPxNcakbCNTGm9av4yICCvSUdet2",
			caste_id: "nXdPMrlPsX8yHH4dUxah",
			surname: "H. Chongbang",
			role: "admin",
			person_id: "gcIsXF4onv9j8oIdhL56",
			id: "AQpAWBKEBJv9aoGADrCP",
			email: "admin@gmail.com",
		},
	],
};
