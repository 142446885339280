import cytoscape from "cytoscape";
import { cypherMessage } from "./CypherMessage";

export function findSubcaste(casteId) {
	let localSubcastes = localStorage.getItem("subcastes");
	let message = cypherMessage(localSubcastes);

	if (message === undefined) return undefined;

	console.log("message", message);
	let tempSubcastes = JSON.parse(message);

	let subcaste = tempSubcastes.find((subcaste) => subcaste.id === casteId);
	return subcaste;
}
