const Legends = () => {

    return (
        <div className="block-wrapper">
            <div className="block block-legends">
                <h3>Legend</h3>
                <div className="legends-wrapper">
                    <div className="legend legend-current">
                        <span></span><p>Currently selected person</p>
                    </div>
                    <div className="legend legend-male">
                        <span></span><p>Male</p>
                    </div>
                    <div className="legend legend-female">
                        <span></span><p>Female</p>
                    </div>
                    <div className="legend legend-wife">
                        <span></span><p>Wife</p>
                    </div>
                </div>

                <hr></hr>

                <p>Number on the right side of person's name represents the eldest to youngest one. 1 being the eldest.</p>

                <p>Number on the left side represents the generation level of that person.</p>

            </div>
        </div>
    );
};

export default Legends;