import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { IoArrowBackCircleSharp } from "react-icons/io5";

import { auth, registerWithEmailAndPassword } from "../../api/firebase";
import images from "../../api/imagesConfig";
import Header from "../layouts/Header";

const Register = () => {
	let navigate = useNavigate();

	let treeImageUrl = images.icons["tree"];

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [name, setName] = useState("");
	const [user, loading, error] = useAuthState(auth);
	const [errorMessages, setErrorMessages] = useState([]);

	const register = () => {
		let errors = [];

		if (name.length <= 0) {
			errors.push("Please enter name");
		}

		if (email.length <= 0 || validateEmail(email) == null) {
			errors.push("Email is invalid");
		}

		if (password.length <= 8) {
			errors.push("Password is invalid, must be 8 character long");
		}

		if (errors.length) {
			setErrorMessages(errors);
			return;
		}

		registerWithEmailAndPassword(name, email, password);

		navigate("/login");
	};

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
		setErrorMessages([]);
	};
	const handleNameChange = (e) => {
		setName(e.target.value);
		setErrorMessages([]);
	};
	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
		setErrorMessages([]);
	};

	const validateEmail = (email) => {
		return email.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	};

	useEffect(() => {
		if (loading) return;
	}, [user, loading]);

	return (
		<div className="onboarding-page">
			<div className="btn-back">
				<IoArrowBackCircleSharp
					size={35}
					color={"white"}
					onClick={() => navigate("/")}
				/>
			</div>
			<div className="left">
				<img src={treeImageUrl} alt="namawali tree" className="logo" />
				<h2>Register</h2>
				<p>
					Thank you for registering to our platform. Please fill out
					the form on the right to register.
				</p>
			</div>
			<div className="right">
				<div className="login-container">
					<div className="login-content">
						<input
							className="login__textBox"
							placeholder="name"
							type="text"
							value={name}
							onChange={handleNameChange}
						/>
						<input
							className="login__textBox"
							placeholder="email"
							type="text"
							value={email}
							onChange={handleEmailChange}
						/>
						<input
							className="login__textBox"
							placeholder="password"
							type="password"
							value={password}
							onChange={handlePasswordChange}
						/>
						{errorMessages.length ? (
							<ErrorMessages errors={errorMessages} />
						) : (
							<></>
						)}
						<button className="login__btn" onClick={register}>
							Register
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const ErrorMessages = ({ errors }) => {
	const messages = errors.map((item, key) => {
		return (
			<li className="errors-item" key={key}>
				{item}
			</li>
		);
	});

	return (
		<div className="errors-area">
			<ul>{messages}</ul>
		</div>
	);
};

export default Register;
