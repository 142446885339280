const CasteSearchResult = ({ data, handleCasteClick }) => {
    
    const result = data.map((row, index) => {
        return (
            <div key={index} className="search-item" onClick={() => handleCasteClick(row) }>
                <ResultItem caste={row} />
            </div>
        )
    })

    return (
        <>
            {result}
        </>
    )

}

const ResultItem = ({ caste }) => {
    return (
        <h3>{caste.name}</h3>
    )
}

export default CasteSearchResult;