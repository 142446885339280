import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../utilities/UserContext";

const SignInPopup = () => {
	let navigate = useNavigate();

	const [popupClass, setPopupClass] = useState("");
	const { currentUser, loading } = useUser();

	useEffect(() => {
		if (loading === false) {
			if (currentUser === null) {
				navigate("/login?redirect=caste/select");
			} else {
				setPopupClass("hide");
			}
		}
	}, [loading]);

	useEffect(() => {
		if (currentUser === null) return;

		let token = currentUser.accessToken;
		if (token === null) {
			setPopupClass("show");
		}
	}, []);

	return (
		<div className={"modal-box-container " + popupClass}>
			<div className="modal-box-wrapper block-must-signin">
				<h2>Please sign in to view this page</h2>
				<Link to="/login">Sign In</Link>
			</div>
		</div>
	);
};

export default SignInPopup;
