import CryptoJS from "crypto-js";

const LACA = "7f8b606eb4bdc173c1a205fe84b05889";

/**
 * Decrypt a message
 *
 * @param {*} text
 * @returns
 */
export const cypherMessage = (text) => {
	if (text === null) return;

	const byaa = CryptoJS.AES.decrypt(text, LACA);
	const result = byaa.toString(CryptoJS.enc.Utf8);

	return result;
};

/**
 * Encrypts a message
 * @param {*} text
 * @returns
 */
export const encryptMessage = (text) => {
	if (text === null) return;

	const byaa = CryptoJS.AES.encrypt(text, LACA);

	return byaa.toString();
};
