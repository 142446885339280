import axios from 'axios'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import URLS from '../../api/url'
import Footer from '../layouts/Footer'
import Header from '../layouts/Header'
import SignInPopup from '../layouts/SignInPopup'

const SuggestionPage = () => {

	let navigate = useNavigate();

	const countLimit = 100;
	const minCharacters = 10;
	const [ thankyou, setThankyou ] = useState('Thank you for your suggestion submission.');
	const [ suggestion, setSuggestion ] = useState('');
	const [ counter, setCounter ] = useState(countLimit);
	const [ isDisabled, setIsDisabled ] = useState('disabled');
	const [ suggestionSent, setSuggestionSent ] = useState(false);

	const handleSuggestionChange = (e) => {
        const val = e.target.value;

		let count = countLimit - val.length;

		setCounter(count);

		if (count <= 0) {
			return;
		}

		if (val.length  >= minCharacters) {
			setIsDisabled('');
		} else {
			setIsDisabled('disabled');
		}

        setSuggestion(val);
    }

	const handleSend = () => {
		setIsDisabled('disabled');
		setSuggestionSent(true);

		const url = URLS.base_url + URLS.suggestions.base;
		const token = localStorage.getItem('accessToken');
		const userId = localStorage.getItem('userId');

        if (token === null)
         return [];

        const headers = {
            headers:  
            { authorization: `Bearer ${token}` }
        };

		const sendData = {
			'uid': userId,
			'suggestion': suggestion,
		}
		
		axios.post(url, sendData, headers)
            .then((res) => {
                const result = res.data;
                if (result.success) {
                } else {
                }
            })
            .catch((error) => {
				setThankyou('Suggestion could not be sent due to maximum limit reached.');
                if (error.code === 'ERR_BAD_REQUEST') {
                    // access token has expired
                    console.error('access token has expired, relogin');
                    // resetUserData();
                }
            });
	}

	const handleSearch = () => {
		navigate('/caste/select');
	}

	return (
		<div className="page page-suggestions">

            <Header />

            <h1>Suggestions</h1>

            <div className="block-wrapper">
                <div className="block block-suggestions">
					{!suggestionSent ? (
						<>
							<Link to="/suggestions/list">[ View all suggestions ]</Link>
							<p>Your suggestions and feedbacks are more than welcome</p>
							<textarea onChange={handleSuggestionChange} maxLength={countLimit}></textarea>
							<p>{counter} characters left</p>
							<button onClick={handleSend} disabled={isDisabled}>Send</button>
						</>
					) : (
						<>
							<p>{thankyou}</p>
							<button onClick={handleSearch}>Search</button>
						</>
					)}
					
                </div>
            </div>
            
            <Footer />

			<SignInPopup />

        </div>
	)
}

export default SuggestionPage