import CookieConsent, { Cookies } from "react-cookie-consent";

const Footer = () => {
	const handleAccept = () => {
		Cookies.set("nama-cookie-accepted", true, { expires: 365 });
	};

	const year = new Date().getFullYear();

	return (
		<footer>
			<p>Share this page with your friends and spread the love.</p>
			<p>Copyright © {year} Nirmal Limbu</p>

			<CookieConsent
				location="bottom"
				buttonText="Accept"
				cookieName="myAwesomeCookieName2"
				style={{ background: "#2B373B" }}
				buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
				expires={150}
				onAccept={handleAccept}
			>
				This website uses cookies to enhance the user experience.{" "}
				<span style={{ fontSize: "10px" }}>Thank you :O</span>
			</CookieConsent>
		</footer>
	);
};

export default Footer;
