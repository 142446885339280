const images = {
	icons: {
		coffee: require("../images/coffee.png"),
		donate: require("../images/donation-icon.png"),
		donateCancel: require("../images/donation-cancel-icon.png"),
		download: require("../images/download.png"),
		google: require("../images/google.png"),
		leaf: require("../images/leaf.png"),
		menu: require("../images/menu.png"),
		person: require("../images/person.png"),
		tree: require("../images/tree.png"),
		woman: require("../images/woman.png"),
		womanHead: require("../images/woman-head.png"),
		womanBody: require("../images/woman-body.png"),
	},
	features: {
		familyTree: require("../images/features/family-tree.png"),
		historyLine: require("../images/features/history-line.png"),
		saveAsImage: require("../images/features/save-as-image.png"),
		search: require("../images/features/search.png"),
	},
};

export default images;
