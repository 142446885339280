export const testPeopleSmallerList = [
    {
        "generation": 1,
        "firstname": "Jash",
        "gender": "male",
        "surname": "maswa",
        "father": "",
        "firstname_nepali": "अजय",
        "order": 1,
        "id": "1"
    },
    {
        "generation": 1,
        "firstname": "Tanka",
        "gender": "female",
        "surname": "fabo chongbang",
        "father": "",
        "firstname_nepali": "अजय",
        "order": 1,
        "spouse": "1",
        "id": "101"
    },

    {
        "generation": 2,
        "firstname": "Tek",
        "gender": "male",
        "surname": "maswa",
        "father": "1",
        "firstname_nepali": "अजय",
        "order": 1,
        "id": "2"
    },
    {
        "generation": 2,
        "firstname": "Mohan",
        "gender": "male",
        "surname": "maswa",
        "father": "1",
        "firstname_nepali": "अजय",
        "order": 2,
        "id": "3"
    },

    {
        "generation": 3,
        "firstname": "Kamal",
        "gender": "male",
        "surname": "maswa",
        "father": "2",
        "firstname_nepali": "अजय",
        "order": 1,
        "id": "4"
    },
    {
        "generation": 3,
        "firstname": "Nirmal",
        "gender": "male",
        "surname": "maswa",
        "father": "2",
        "firstname_nepali": "अजय",
        "order": 2,
        "id": "5"
    },
    {
        "generation": 3,
        "firstname": "Sharada",
        "gender": "male",
        "surname": "maswa",
        "father": "2",
        "firstname_nepali": "अजय",
        "order": 3,
        "id": "6"
    },

    {
        "generation": 4,
        "firstname": "Niskarsh",
        "gender": "male",
        "surname": "maswa",
        "father": "5",
        "firstname_nepali": "अजय",
        "order": 1,
        "id": "7"
    },
    {
        "generation": 4,
        "firstname": "Sarah",
        "gender": "male",
        "surname": "maswa",
        "father": "5",
        "firstname_nepali": "अजय",
        "order": 2,
        "id": "8"
    },
    {
        "generation": 4,
        "firstname": "Bobo",
        "gender": "male",
        "surname": "maswa",
        "father": "5",
        "firstname_nepali": "अजय",
        "order": 3,
        "id": "81"
    },
    {
        "generation": 4,
        "firstname": "Mira",
        "gender": "male",
        "surname": "maswa",
        "father": "4",
        "firstname_nepali": "अजय",
        "order": 1,
        "id": "9"
    },
    {
        "generation": 4,
        "firstname": "Vivi",
        "gender": "male",
        "surname": "maswa",
        "father": "4",
        "firstname_nepali": "अजय",
        "order": 2,
        "id": "10"
    },
    {
        "generation": 4,
        "firstname": "Saroj",
        "gender": "male",
        "surname": "maswa",
        "father": "6",
        "firstname_nepali": "अजय",
        "order": 1,
        "id": "16"
    },
    {
        "generation": 4,
        "firstname": "Manoj",
        "gender": "male",
        "surname": "maswa",
        "father": "6",
        "firstname_nepali": "अजय",
        "order": 2,
        "id": "17"
    },
    

    {
        "generation": 2,
        "firstname": "Bhakti",
        "gender": "female",
        "surname": "neyong",
        "father": "200",
        "spouse": "2",
        "firstname_nepali": "अजय",
        "order": 1,
        "id": "201"
    },
    {
        "generation": 2,
        "firstname": "Shakti",
        "gender": "female",
        "surname": "neyong",
        "father": "200",
        "spouse": "2",
        "firstname_nepali": "अजय",
        "order": 1,
        "id": "202"
    },
    
];

export const testPeopleBigList = [
    {
        "generation": 31,
        "firstname": "Ajay",
        "gender": "male",
        "surname": "neyong",
        "father": "5x8aVF3lmWNp0yGiXeWV",
        "firstname_nepali": "अजय",
        "order": 1,
        "id": "0s3YYtbvLm2qCiXQfFur"
    },
    {
        "generation": 30,
        "firstname": "Laxmi",
        "gender": "female",
        "surname": "Yakso",
        "father": "",
        "spouse": "VjLLbXIbb1Pg054VwmSq",
        "firstname_nepali": "लक्ष्मी",
        "order": 1,
        "id": "0xuRl0ZtWGXzZBLG9wX8"
    },
    {
        "generation": 29,
        "firstname": "Tanka Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "8byoiXRAjaQtsg9Yrz0y",
        "firstname_nepali": "टंकबहादुर",
        "order": 1,
        "id": "11U7zezKhLd8xGqhBiOl"
    },
    {
        "generation": 29,
        "firstname": "kavidhoj",
        "gender": "male",
        "surname": "neyong",
        "father": "BfbZgRLMtIE0ayUsO7ax",
        "firstname_nepali": "कविधोज",
        "order": 1,
        "id": "13ZOeZvGLPXyhanOwOtq"
    },
    {
        "generation": 30,
        "firstname": "Chandraa",
        "gender": "female",
        "surname": "Khapung",
        "father": "",
        "spouse": "tH7iv918UMwJjif31eSg",
        "firstname_nepali": "चन्द्रा",
        "order": 1,
        "id": "17q13YzHlzWGEWh3hvSh"
    },
    {
        "generation": 30,
        "firstname": "Bishnu",
        "gender": "female",
        "surname": "neyong",
        "father": "a56rJXA0e9D9degC1yWe",
        "firstname_nepali": "विष्णु",
        "order": 1,
        "id": "1VvafMEv1uEKKzYhpWS7"
    },
    {
        "generation": 29,
        "firstname": "Yetirani",
        "gender": "female",
        "surname": "Liwang",
        "father": "",
        "spouse": "h19FdDID6NoY5evIqsdy",
        "firstname_nepali": "यतिरानी",
        "order": 1,
        "id": "1uKfzY4sFiMIKMSAtdDf"
    },
    {
        "generation": 26,
        "firstname": "Narbir",
        "gender": "male",
        "surname": "neyong",
        "father": "adZmBL6zCCbLDwbTrg1Z",
        "firstname_nepali": "नरबिर",
        "order": 1,
        "id": "20D1cu5miJxZUqLZXvdh"
    },
    {
        "generation": 28,
        "firstname": "Dhanmaya ",
        "gender": "female",
        "surname": "Hanggam",
        "father": "BNIMygjbAj4nQvDxUcfJ",
        "firstname_nepali": "धनमाया",
        "order": 1,
        "id": "26xjQcxU9gpUFVQCjKFo"
    },
    {
        "generation": 29,
        "firstname": "Deshmaya",
        "gender": "female",
        "surname": "Yakhya",
        "father": "KNIGo4xoVGVuLnV5pMIj",
        "firstname_nepali": "देशमाया",
        "order": 1,
        "id": "27lUdQ9VfSzHzmz1wSy8"
    },
    {
        "generation": 30,
        "firstname": "Sunita",
        "gender": "female",
        "surname": "Thatlung",
        "father": "",
        "spouse": "R2HEcMIqsvQa7kON8pm1",
        "firstname_nepali": "सुनिता",
        "order": 1,
        "id": "2ZY0bHNCnaN80bUi33IG"
    },
    {
        "generation": 31,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "",
        "spouse": "sc5GQQMKHH0vIeceI39U",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "2p1V6JanF11WplsbBkXZ"
    },
    {
        "generation": 30,
        "firstname": "Shivkumari",
        "gender": "female",
        "surname": "Yakhya Rai",
        "father": "",
        "spouse": "qZq4Ynfi4NIXYGFOzvfy",
        "firstname_nepali": "शिवकुमारी",
        "order": 1,
        "id": "2vSHXULiZYeEiUVQalJr"
    },
    {
        "generation": 31,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "rai",
        "father": "",
        "spouse": "OEo707EqYXnJUtdqeh2v",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "2ySyhDChsJX7r6ohKcRI"
    },
    {
        "generation": 29,
        "firstname": "Sarimaya",
        "gender": "female",
        "surname": "Rai",
        "father": "KNIGo4xoVGVuLnV5pMIj",
        "firstname_nepali": "सरिमाया",
        "order": 1,
        "id": "32bxssjUP9jW16RkZf2U"
    },
    {
        "generation": 30,
        "firstname": "Arjun",
        "gender": "male",
        "surname": "neyong",
        "father": "e1TiTW9USSP8HBdOkHle",
        "firstname_nepali": "अर्जुन",
        "order": 1,
        "id": "33juj0OqW2AYg0cs6WSd"
    },
    {
        "generation": 28,
        "firstname": "Tilmaya",
        "gender": "female",
        "surname": "Wanem",
        "father": "dv7viJovtWgjXcID9hqt",
        "firstname_nepali": "तिलमाया",
        "order": 1,
        "id": "3D8Ijs2n3eaF3a1p7Wfi"
    },
    {
        "generation": 29,
        "firstname": "Ajahang",
        "gender": "male",
        "surname": "neyong",
        "father": "h19FdDID6NoY5evIqsdy",
        "firstname_nepali": "अजहाङ",
        "order": 1,
        "id": "3YLvHWCWdU1XtyPUbQ90"
    },
    {
        "generation": 25,
        "firstname": "Bhagirup",
        "gender": "male",
        "surname": "neyong",
        "father": "oEafKRqTRSz0eMgYR9jK",
        "firstname_nepali": "भागिरूप",
        "order": 1,
        "id": "3hu3aXkYoxPY9VA96MJT"
    },
    {
        "generation": 29,
        "firstname": "Bijaya",
        "gender": "male",
        "surname": "neyong",
        "father": "miXHsHQr36HDj79PK0om",
        "firstname_nepali": "विजया",
        "order": 1,
        "id": "46WnSvKxL1rPJwBRvQ9h"
    },
    {
        "generation": 28,
        "firstname": "Padam Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "WX97sNzmxAYBl5FKB3gT",
        "firstname_nepali": "पदम बहादुर",
        "order": 1,
        "id": "4I2vQ1fiu0BmwI0OlbzY"
    },
    {
        "generation": 30,
        "firstname": "baghbir",
        "gender": "male",
        "surname": "neyong",
        "father": "zGv5Oqm4WMll6hXgZLv5",
        "firstname_nepali": "बागवीर",
        "order": 1,
        "id": "4K3qHOoZR3daXcJByv27"
    },
    {
        "generation": 32,
        "firstname": "Abhisekh",
        "gender": "male",
        "surname": "neyong",
        "father": "CBy6iQssxc7x7OoKa0fd",
        "firstname_nepali": "अभिषेक",
        "order": 1,
        "id": "Vl57I7ziTNC3omU3Vcie"
    },
    {
        "generation": 30,
        "firstname": "Pramila",
        "gender": "female",
        "surname": "F.Chongbang",
        "father": "",
        "spouse": "Gv8Vod1jzQTbetCtbkIQ",
        "firstname_nepali": "प्रमिला",
        "order": 1,
        "id": "VwcWBCTE5ccaANBminNM"
    },
    {
        "generation": 30,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "Gita Phombe",
        "father": "",
        "spouse": "nXKvHx8kvAMu9qGFNb90",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "W19UxvFilaCiytgs6J8C"
    },
    {
        "generation": 28,
        "firstname": "Sher Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "sOCofrH1YF9KUC3xrOJh",
        "firstname_nepali": "शेरबहादुर",
        "order": 1,
        "id": "W28YpQv1MguJ6toMax4j"
    },
    {
        "generation": 31,
        "firstname": "Yungna",
        "gender": "female",
        "surname": "neyong",
        "father": "xFyka1iAoJ6G4jeIF9YQ",
        "firstname_nepali": "युङ्ना",
        "order": 1,
        "id": "W5mkVc4u1iTgLq4JXS0S"
    },
    {
        "generation": 30,
        "firstname": "Sandip",
        "gender": "male",
        "surname": "neyong",
        "father": "11U7zezKhLd8xGqhBiOl",
        "firstname_nepali": "सन्दिप",
        "order": 1,
        "id": "WKz48HBzuOGzsdcPlEO4"
    },
    {
        "generation": 30,
        "firstname": "Tara",
        "gender": "female",
        "surname": "neyong",
        "father": "ikiGkJgxhaFwSdYUeXQ1",
        "firstname_nepali": "तारा",
        "order": 1,
        "id": "WMY7JI0eJ4XvnDR49slM"
    },
    {
        "generation": 30,
        "firstname": "Dilkumari",
        "gender": "female",
        "surname": "H.Chongbang",
        "father": "re3rudyfMy7PkrwnU1j3",
        "firstname_nepali": "दिलकुमारी",
        "order": 1,
        "id": "WOXlw6Ugiw4DagFiiaoN"
    },
    {
        "generation": 27,
        "firstname": "Chandrabir",
        "gender": "male",
        "surname": "neyong",
        "father": "OoNWKOdqDioDWMqkiCCT",
        "firstname_nepali": "चन्द्रवीर",
        "order": 1,
        "id": "WX97sNzmxAYBl5FKB3gT"
    },
    {
        "generation": 26,
        "firstname": "satriyaangli",
        "gender": "male",
        "surname": "neyong",
        "father": "7c1CIpD6IZ1jmutSRVmR",
        "firstname_nepali": "सत्रियांगली",
        "order": 1,
        "id": "Weft1rWYsac8B7RlO9LL"
    },
    {
        "generation": 29,
        "firstname": "Kamala",
        "gender": "female",
        "surname": "Pangma",
        "father": "",
        "spouse": "tHJfnfHlXZjOmPQjbfST",
        "firstname_nepali": "कमला",
        "order": 1,
        "id": "X4LtdYe0Kf3oeU1bGC7P"
    },
    {
        "generation": 30,
        "firstname": "Sanjuka",
        "gender": "female",
        "surname": "neyong",
        "father": "ngyZZ89D3lUG54oXEE8d",
        "firstname_nepali": "सञ्जुका",
        "order": 1,
        "id": "XO5gRMHHTqjqOEioeqps"
    },
    {
        "generation": 29,
        "firstname": "Harkamaya",
        "gender": "female",
        "surname": "neyong",
        "father": "kf4un20w1ssu3R7xYu3Y",
        "firstname_nepali": "हर्कमाया",
        "order": 1,
        "id": "XQbhZgAd0xjvg4Qi7KZi"
    },
    {
        "generation": 29,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "",
        "spouse": "EdA9PnqNWSa3kR0mDMwC",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "Xao86jGiVx83D2LzYtfP"
    },
    {
        "generation": 30,
        "firstname": "Anjana",
        "gender": "female",
        "surname": "Yakso",
        "father": "",
        "spouse": "xYxZwzugiHlHI3Rc4yeK",
        "firstname_nepali": "अञ्जना",
        "order": 1,
        "id": "XiZH8uZQNH27v1AX6Vl8"
    },
    {
        "generation": 29,
        "firstname": "Mangalshi",
        "gender": "male",
        "surname": "neyong",
        "father": "N9O37TuJcTeet1Ku981Y",
        "firstname_nepali": "मंगलसी",
        "order": 1,
        "id": "XigKQtgxYZQdpCeUhHpy"
    },
    {
        "generation": 29,
        "firstname": "Ushika",
        "gender": "female",
        "surname": "neyong",
        "father": "Oncg6ZoiciU8fyA610i9",
        "firstname_nepali": "उशिका",
        "order": 1,
        "id": "XjW1k9vQYbWOhmsmpxaB"
    },
    {
        "generation": 31,
        "firstname": "Dilkumari",
        "gender": "female",
        "surname": "Aangbuhang",
        "father": "",
        "spouse": "Yaav4K0D7iLo7WPTeLlx",
        "firstname_nepali": "दिलकुमारी",
        "order": 1,
        "id": "Xmq9u0Nwj1W1y3HLRYXM"
    },
    {
        "generation": 31,
        "firstname": "Anisha",
        "gender": "female",
        "surname": "sambahangphe",
        "father": "",
        "spouse": "IdBmWceSURfNlN1qwGLV",
        "firstname_nepali": "अनिषा",
        "order": 1,
        "id": "Xy8K0lTpiYplzQBf6eI9"
    },
    {
        "generation": 29,
        "firstname": "Binita",
        "gender": "female",
        "surname": "neyong",
        "father": "tHJfnfHlXZjOmPQjbfST",
        "firstname_nepali": "बिनिता",
        "order": 1,
        "id": "Y9ohgoswkj77oiO3yjH2"
    },
    {
        "generation": 30,
        "firstname": "Kala ",
        "gender": "female",
        "surname": "Mangden",
        "father": "PBfA6yIfHlRwzTqzXRCa",
        "firstname_nepali": "कला",
        "order": 1,
        "id": "YALu0vCB4EcN9VjqjMhK"
    },
    {
        "generation": 30,
        "firstname": "Hillimaya",
        "gender": "female",
        "surname": "Okhrabu",
        "father": "",
        "spouse": "ZagHQ0QrPX2qEzX7GGNG",
        "firstname_nepali": "हिल्लीमाया",
        "order": 1,
        "id": "YDjFluRRTn0p1zjyyWwS"
    },
    {
        "generation": 31,
        "firstname": "Keshma",
        "gender": "female",
        "surname": "Wanem",
        "father": "",
        "spouse": "xFyka1iAoJ6G4jeIF9YQ",
        "firstname_nepali": "केष्मा",
        "order": 1,
        "id": "YlLIic0ymIBnRWPHfiez"
    },
    {
        "generation": 28,
        "firstname": "Panchamati",
        "gender": "female",
        "surname": "Nalbo",
        "father": "",
        "spouse": "5td9VaM6nfhcmvFLM28Q",
        "firstname_nepali": "पञ्चमती",
        "order": 1,
        "id": "ZVy9E7ocbXE18Rmexq6V"
    },
    {
        "generation": 29,
        "firstname": "Hari Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "Imc5TtSjmhclodSnd4M1",
        "firstname_nepali": "हरिबहादुर",
        "order": 1,
        "id": "ZZ6ATmUNwWYnngLRMoQ9"
    },
    {
        "generation": 29,
        "firstname": "Renuka",
        "gender": "female",
        "surname": "neyong",
        "father": "7CST4AAEgdaJ4I1kx8Jp",
        "firstname_nepali": "रेणुका",
        "order": 1,
        "id": "ZZp2QssW0q7Pr0f4qN4X"
    },
    {
        "generation": 27,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "",
        "spouse": "3bibwxYc96UIXF2UGCkt",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "ZdSLaVHMqeQGkAiG88IM"
    },
    {
        "generation": 28,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "1GepDR9JBG91M6qmNVTG",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "Zg4hRRJ7mcbFWBcKhch1"
    },
    {
        "generation": 30,
        "firstname": "Sakuntala",
        "gender": "female",
        "surname": "Lawati",
        "father": "",
        "spouse": "hQyPAM4dDLfI5ShleBx2",
        "firstname_nepali": "शकुन्तला",
        "order": 1,
        "id": "a35iSHES31He3R7h13tX"
    },
    {
        "generation": 31,
        "firstname": "Prabin",
        "gender": "male",
        "surname": "neyong",
        "father": "FDuIt3kIlkgc71RRxtLm",
        "firstname_nepali": "प्रविन",
        "order": 1,
        "id": "a62h7T0UcyCxDc0PSi1S"
    },
    {
        "generation": 28,
        "firstname": "Kanjahangma",
        "gender": "female",
        "surname": "Abheng Thopra",
        "father": "",
        "spouse": "YbfnmnrCBHCa4fd8FOHH",
        "firstname_nepali": "कन्जहाङ्मा",
        "order": 1,
        "id": "aCBWj6Y9jxnpwjYTKslU"
    },
    {
        "generation": 30,
        "firstname": "Shanti",
        "gender": "female",
        "surname": "neyong",
        "father": "dYahEBO0s8A4U63VZfoc",
        "firstname_nepali": "शान्ति",
        "order": 1,
        "id": "aGliRR2eskT0RDj7faie"
    },
    {
        "generation": 28,
        "firstname": "Jasrani",
        "gender": "female",
        "surname": "Nalbo",
        "father": "",
        "spouse": "WX97sNzmxAYBl5FKB3gT",
        "firstname_nepali": "जसरानी",
        "order": 1,
        "id": "aNqNaT8wWZ57ZLm6bqGw"
    },
    {
        "generation": 28,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "Nalbo",
        "father": "",
        "spouse": "Fg2kKyyZb5vWv3118HpK",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "agkoO1BSWLjfSt5QslmS"
    },
    {
        "generation": 29,
        "firstname": "Shukmaya",
        "gender": "female",
        "surname": "Haangam",
        "father": "",
        "spouse": "72xTPRMzTjPPQQVDjSSB",
        "firstname_nepali": "शुक्माया",
        "order": 1,
        "id": "b7mbLxHszKVv8IG21VrS"
    },
    {
        "generation": 29,
        "firstname": "Bina",
        "gender": "female",
        "surname": "Pomu",
        "father": "",
        "spouse": "J2ac0kCcTxVQjo4TbSqr",
        "firstname_nepali": "बिना",
        "order": 1,
        "id": "bIrwRu2Nw05XEnbKNTrK"
    },
    {
        "generation": 26,
        "firstname": "Hangsarath",
        "gender": "male",
        "surname": "neyong",
        "father": "3hu3aXkYoxPY9VA96MJT",
        "firstname_nepali": "हङ्सरथ",
        "order": 1,
        "id": "bRkDszHtx7zqUlerNeer"
    },
    {
        "generation": 29,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "Abheng",
        "father": "",
        "spouse": "7qraaWx47ZiKZFyQNupe",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "biHKHT9mnzRMCMWrh2Nv"
    },
    {
        "generation": 30,
        "firstname": "Wedraj",
        "gender": "male",
        "surname": "neyong",
        "father": "re3rudyfMy7PkrwnU1j3",
        "firstname_nepali": "बेडराज",
        "order": 1,
        "id": "bqf7k79zbbHI6wED7Wsh"
    },
    {
        "generation": 28,
        "firstname": "Shukbir",
        "gender": "male",
        "surname": "neyong",
        "father": "sYK5xBb6KRk4rX3iff0Y",
        "firstname_nepali": "शुक्बीर",
        "order": 1,
        "id": "brM77FeJVKGPNvPfClyX"
    },
    {
        "generation": 29,
        "firstname": "Kumari",
        "gender": "female",
        "surname": "Yengden",
        "father": "OM9MJEJFRT2bKOAo3iUi",
        "firstname_nepali": "कुमारी",
        "order": 1,
        "id": "bshPIJzeZpqqxWWBnHVp"
    },
    {
        "generation": 30,
        "firstname": "Shisir",
        "gender": "male",
        "surname": "neyong",
        "father": "wXoi6mCx1t64iFs1E04j",
        "firstname_nepali": "शिशिर",
        "order": 1,
        "id": "c6cYi3YIGEdAMw5GjDAG"
    },
    {
        "generation": 30,
        "firstname": "Nandamaya",
        "gender": "female",
        "surname": "Kangdawa",
        "father": "",
        "spouse": "OwV0mfHCf1GVYHiL7E4x",
        "firstname_nepali": "नन्द्रमाया",
        "order": 1,
        "id": "ccLKnAJ2RHZVJ0Et1c3h"
    },
    {
        "generation": 29,
        "firstname": "Yuddhabir",
        "gender": "male",
        "surname": "neyong",
        "father": "5alf3Nw8bulym1dR6cxr",
        "firstname_nepali": "युद्धवीर",
        "order": 1,
        "id": "cuZHgbKG3DtYhIXfq5qm"
    },
    {
        "generation": 31,
        "firstname": "Narmaya",
        "gender": "female",
        "surname": "Wanem",
        "father": "",
        "spouse": "6hdWyig4xeRlNlykDwun",
        "firstname_nepali": "नरमाया",
        "order": 1,
        "id": "d7dbkbJCT6jimVUQF2OS"
    },
    {
        "generation": 30,
        "firstname": "mangale",
        "gender": "male",
        "surname": "neyong",
        "father": "8D4xsNuReFz8qkTRQutD",
        "firstname_nepali": "मंगले",
        "order": 1,
        "id": "dURXA19Q5JUnWq6eMF6l"
    },
    {
        "generation": 29,
        "firstname": "Bhim Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "wqBPds94CS3LmfwG6uN9",
        "firstname_nepali": "भीमबहादुर",
        "order": 1,
        "id": "dYahEBO0s8A4U63VZfoc"
    },
    {
        "generation": 29,
        "firstname": "Maita bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "wPQMm9eZcmVTM3W7ZQY8",
        "firstname_nepali": "माइता बहादुर",
        "order": 1,
        "id": "dfPoFsd66CVmbvKE5kSs"
    },
    {
        "generation": 27,
        "firstname": "Laghunath",
        "gender": "male",
        "surname": "neyong",
        "father": "LfSWWYnJz14nXeCU3EwM",
        "firstname_nepali": "लघुनाथ",
        "order": 1,
        "id": "dv7viJovtWgjXcID9hqt"
    },
    {
        "generation": 29,
        "firstname": "Nopti",
        "gender": "female",
        "surname": "neyong",
        "father": "8byoiXRAjaQtsg9Yrz0y",
        "firstname_nepali": "नोप्ति",
        "order": 1,
        "id": "e1d3U0peCfdKzYlBceJY"
    },
    {
        "generation": 31,
        "firstname": "Mirak",
        "gender": "male",
        "surname": "neyong",
        "father": "Jt2YhXThoq9J6ndMYM2B",
        "firstname_nepali": "मिराक",
        "order": 1,
        "id": "e4nO1gcYRTs5cTu1P0fs"
    },
    {
        "generation": 29,
        "firstname": "Bhimkumari",
        "gender": "female",
        "surname": "Wanem",
        "father": "eXOIzWaaOWilxGaoNY1q",
        "firstname_nepali": "भीमकुमारी",
        "order": 1,
        "id": "eCwM49OItXybKsYnW34l"
    },
    {
        "generation": 30,
        "firstname": "Sukmaya",
        "gender": "female",
        "surname": "Thangden",
        "father": "",
        "spouse": "9bNY6EVA4CbzcXIIRNS9",
        "firstname_nepali": "शुक्माया",
        "order": 1,
        "id": "eK6TOLbfIvirWRHAXOnu"
    },
    {
        "generation": 28,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "",
        "spouse": "GAEABfATbf0FabIDXRq9",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "eP7hVWfVtAffsNmW5XbE"
    },
    {
        "generation": 30,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "eJtNxXWWM6QeqBVCA9x4",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "ec7pj8rUsZeI51Av6pr1"
    },
    {
        "generation": 27,
        "firstname": "Jasbir",
        "gender": "male",
        "surname": "neyong",
        "father": "bRkDszHtx7zqUlerNeer",
        "firstname_nepali": "जसबिर",
        "order": 1,
        "id": "efEWP3DLaCN0MxeRZt2M"
    },
    {
        "generation": 30,
        "firstname": "Ratnakumari",
        "gender": "female",
        "surname": "Thallang",
        "father": "",
        "spouse": "dYahEBO0s8A4U63VZfoc",
        "firstname_nepali": "रत्नकुमारी",
        "order": 1,
        "id": "en7njIvAqeyI40uPNDnD"
    },
    {
        "generation": 29,
        "firstname": "Bishnu",
        "gender": "female",
        "surname": "Wanem",
        "father": "",
        "spouse": "7CST4AAEgdaJ4I1kx8Jp",
        "firstname_nepali": "विष्णु",
        "order": 1,
        "id": "esJjZXPGJ01rETHHtjCe"
    },
    {
        "generation": 26,
        "firstname": "Mukundashi",
        "gender": "male",
        "surname": "neyong",
        "father": "Mi9dyKxhyvikZ7kKKa7F",
        "firstname_nepali": "मुकुन्दसिं",
        "order": 1,
        "id": "exx5e7ZeKORqWUlq50nv"
    },
    {
        "generation": 30,
        "firstname": "Bimala",
        "gender": "female",
        "surname": "Faben",
        "father": "",
        "spouse": "e1TiTW9USSP8HBdOkHle",
        "firstname_nepali": "बिमला",
        "order": 1,
        "id": "fI6ZTWfWsPzF7DTMT7g2"
    },
    {
        "generation": 30,
        "firstname": "Alex",
        "gender": "male",
        "surname": "neyong",
        "father": "qcsZQblaYoGvkRwVHwaF",
        "firstname_nepali": "आलेक्स",
        "order": 1,
        "id": "gAutYJuvOOIOFKfa6STR"
    },
    {
        "generation": 29,
        "firstname": "Durga",
        "gender": "female",
        "surname": "Rai",
        "father": "4I2vQ1fiu0BmwI0OlbzY",
        "firstname_nepali": "दुर्गा",
        "order": 1,
        "id": "lG8E6I8aaVIJos6DjF83"
    },
    {
        "generation": 32,
        "firstname": "Rita",
        "gender": "female",
        "surname": "Nugo",
        "father": "",
        "spouse": "NS5UjGypAI85KX2lse93",
        "firstname_nepali": "रिता",
        "order": 1,
        "id": "lW7xEZ4enDzrRV4Ibmjr"
    },
    {
        "generation": 30,
        "firstname": "Samudra",
        "gender": "female",
        "surname": "neyong",
        "father": "XGfojZ421gtdVs7HspEj",
        "firstname_nepali": "समुद्र",
        "order": 1,
        "id": "ljgnLoSbSqLbLbUxotpb"
    },
    {
        "generation": 30,
        "firstname": "Narmaya",
        "gender": "female",
        "surname": "Rai",
        "father": "",
        "spouse": "a56rJXA0e9D9degC1yWe",
        "firstname_nepali": "नर्मया ",
        "order": 1,
        "id": "lmTvsd4Ju5m2Gm3PqS8m"
    },
    {
        "generation": 29,
        "firstname": "PadamDhoj",
        "gender": "male",
        "surname": "neyong",
        "father": "sSRNStHwtiSf582KrlVl",
        "firstname_nepali": "पदमधोज",
        "order": 1,
        "id": "lvJOdENSgGMIGztLY2cU"
    },
    {
        "generation": 31,
        "firstname": "Kumar",
        "gender": "male",
        "surname": "neyong",
        "father": "mALZzIFULNi9IhNMAXr3",
        "firstname_nepali": "कुमार",
        "order": 1,
        "id": "m201jshWuoU7EXFkrn9w"
    },
    {
        "generation": 30,
        "firstname": "Kamal",
        "gender": "male",
        "surname": "neyong",
        "father": "qZq4Ynfi4NIXYGFOzvfy",
        "firstname_nepali": "कमल",
        "order": 1,
        "id": "mALZzIFULNi9IhNMAXr3"
    },
    {
        "generation": 29,
        "firstname": "Chhalimaya",
        "gender": "female",
        "surname": "Sawa",
        "father": "",
        "spouse": "KNIGo4xoVGVuLnV5pMIj",
        "firstname_nepali": "छलिमाया",
        "order": 1,
        "id": "mH2ME4woiwybkTyFTG9b"
    },
    {
        "generation": 29,
        "firstname": "Shantimaya",
        "gender": "female",
        "surname": "neyong",
        "father": "miXHsHQr36HDj79PK0om",
        "firstname_nepali": "शान्तिमाया",
        "order": 1,
        "id": "mKWGHQDCad11zy8ExcEI"
    },
    {
        "generation": 30,
        "firstname": "Nita",
        "gender": "female",
        "surname": "neyong",
        "father": "uxyxVmb94D6gbIXyHyag",
        "firstname_nepali": "नीता",
        "order": 1,
        "id": "miPBj3VAxauzilZnt4q7"
    },
    {
        "generation": 28,
        "firstname": "Balahang",
        "gender": "male",
        "surname": "neyong",
        "father": "ZotaxQ2AZ50SaSBP7UfA",
        "firstname_nepali": "बालाहाङ",
        "order": 1,
        "id": "n52s3uDsPp3A2XdaNaay"
    },
    {
        "generation": 30,
        "firstname": "Rekha",
        "gender": "female",
        "surname": "neyong",
        "father": "R2HEcMIqsvQa7kON8pm1",
        "firstname_nepali": "रेखा",
        "order": 1,
        "id": "n5Yfo24ltzRo844GuV3w"
    },
    {
        "generation": 30,
        "firstname": "Devkala",
        "gender": "female",
        "surname": "Yakso",
        "father": "",
        "spouse": "eMgtRHWQXym72VbqwpH6",
        "firstname_nepali": "देवकला",
        "order": 1,
        "id": "n9WFS5HWa1WulVd4Rcfe"
    },
    {
        "generation": 30,
        "firstname": "Preson ",
        "gender": "male",
        "surname": "neyong",
        "father": "ySuEYexhhmyHiituMT18",
        "firstname_nepali": "प्रिसन",
        "order": 1,
        "id": "nAwx6baFGijSMXrcvcOC"
    },
    {
        "generation": 29,
        "firstname": "Maitabahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "7qraaWx47ZiKZFyQNupe",
        "firstname_nepali": "माइताबहादुर",
        "order": 1,
        "id": "nXKvHx8kvAMu9qGFNb90"
    },
    {
        "generation": 30,
        "firstname": "Pranaya",
        "gender": "male",
        "surname": "neyong",
        "father": "Gv8Vod1jzQTbetCtbkIQ",
        "firstname_nepali": "प्रणय",
        "order": 1,
        "id": "nXosYm5qCBRQMfSqzqMX"
    },
    {
        "generation": 30,
        "firstname": "Bina",
        "gender": "female",
        "surname": "neyong",
        "father": "11U7zezKhLd8xGqhBiOl",
        "firstname_nepali": "बिना",
        "order": 1,
        "id": "nnlfyeAbaI7U2mzQF7zf"
    },
    {
        "generation": 29,
        "firstname": "Jitmati",
        "gender": "female",
        "surname": "Pangthak",
        "father": "",
        "spouse": "8byoiXRAjaQtsg9Yrz0y",
        "firstname_nepali": "जितमती",
        "order": 1,
        "id": "nrBpNaWpFuP7y48sQMR1"
    },
    {
        "generation": 32,
        "firstname": "Bhanu",
        "gender": "female",
        "surname": "Gurung",
        "father": "",
        "spouse": "CBy6iQssxc7x7OoKa0fd",
        "firstname_nepali": "भानु",
        "order": 1,
        "id": "oCz9PkoBbfgTAObcw6d4"
    },
    {
        "generation": 28,
        "firstname": "Mautimaya",
        "gender": "female",
        "surname": "F.Chongbang",
        "father": "WX97sNzmxAYBl5FKB3gT",
        "firstname_nepali": "मौतिमाया",
        "order": 1,
        "id": "oGMhr76YrHoKPdvbRBs4"
    },
    {
        "generation": 28,
        "firstname": "Twakhung",
        "gender": "female",
        "surname": "neyong",
        "father": "5td9VaM6nfhcmvFLM28Q",
        "firstname_nepali": "ट्वाखुङ",
        "order": 1,
        "id": "oM4IXyDJhw6NDJrITtsZ"
    },
    {
        "generation": 27,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "",
        "spouse": "bRkDszHtx7zqUlerNeer",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "oMzG4PH68Mv4PmmCg0vW"
    },
    {
        "generation": 30,
        "firstname": "Namgeyhang",
        "gender": "male",
        "surname": "neyong",
        "father": "t5MeCY6EJVRvW7u70xxz",
        "firstname_nepali": "नाम्गेहाङ्",
        "order": 1,
        "id": "oQqwGyCiwVnv3RB0167f"
    },
    {
        "generation": 32,
        "firstname": "Prabha",
        "gender": "female",
        "surname": "neyong",
        "father": "NS5UjGypAI85KX2lse93",
        "firstname_nepali": "प्रभा",
        "order": 1,
        "id": "oxdSiDwLPGJpZlgaCSTp"
    },
    {
        "generation": 28,
        "firstname": "harkabahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "DXyM4QUXaltfQGF7rLZT",
        "firstname_nepali": "हर्कबहादुर",
        "order": "1",
        "id": "p0ij5WKbT07X3Ff3IgUc"
    },
    {
        "generation": 28,
        "firstname": "Man Prasad",
        "gender": "male",
        "surname": "neyong",
        "father": "u5mt0MbVXnMOpYT1sn9h",
        "firstname_nepali": "मनप्रसाद",
        "order": 1,
        "id": "pFAAWd1xpo44DHuts3G2"
    },
    {
        "generation": 29,
        "firstname": "Dal Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "Dt6LJ1nYTuaOj6ZwSW5z",
        "firstname_nepali": "दलबहादुर",
        "order": 1,
        "id": "pLnkJiXFiQcdylhCcZcB"
    },
    {
        "generation": 27,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "Mangden phisti",
        "father": "",
        "spouse": "OoNWKOdqDioDWMqkiCCT",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "pViJje6O7TlpOUJzlBk2"
    },
    {
        "generation": 27,
        "firstname": "Agamhaang",
        "gender": "male",
        "surname": "neyong",
        "father": "20D1cu5miJxZUqLZXvdh",
        "firstname_nepali": "आगमहाङ",
        "order": 1,
        "id": "qESM7BN6tjwDD9nhQGT3"
    },
    {
        "generation": 28,
        "firstname": "purandhoj",
        "gender": "male",
        "surname": "neyong",
        "firstname_nepali": "पुरनधोज",
        "order": "1",
        "father": "jvu9WcOQ1Pq7s7ijBoNk",
        "id": "qGZDF5Wc1bgWkE0OC74L"
    },
    {
        "generation": 30,
        "firstname": "Chandrakumar",
        "gender": "male",
        "surname": "neyong",
        "father": "ZagHQ0QrPX2qEzX7GGNG",
        "firstname_nepali": "चन्द्रकुमार",
        "order": 1,
        "id": "qPh9ANBRU7jXYH8cBZOr"
    },
    {
        "generation": 27,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "",
        "spouse": "2ACsb6YKCCyXefmfgN0q",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "qXxaPLQe8Xl17z4Zrd18"
    },
    {
        "generation": 28,
        "firstname": "Manrani",
        "gender": "female",
        "surname": "Okhrabu",
        "father": "",
        "spouse": "BNIMygjbAj4nQvDxUcfJ",
        "firstname_nepali": "मनरानी",
        "order": 1,
        "id": "qqLxOYkYVcCD0gU9lCP3"
    },
    {
        "generation": 31,
        "firstname": "Saraswati",
        "gender": "female",
        "surname": "Brahman",
        "father": "",
        "spouse": "vPJscrn8uDcIxyqnFaZk",
        "firstname_nepali": "सरस्वती",
        "order": 1,
        "id": "qqdBocgFJzeEuWXoMA3Q"
    },
    {
        "generation": 30,
        "firstname": "Nirma",
        "gender": "female",
        "surname": "Wanem",
        "father": "",
        "spouse": "7N9LhsItnNzrVSHof5lC",
        "firstname_nepali": "निरमा",
        "order": 1,
        "id": "r8M0n7QM3qMfWUdf1tpZ"
    },
    {
        "generation": 28,
        "firstname": "Sushree",
        "gender": "female",
        "surname": "Khadkamaya",
        "father": "sOCofrH1YF9KUC3xrOJh",
        "firstname_nepali": "सुश्री",
        "order": 1,
        "id": "rCVb2JoJDkmKeh39mSpy"
    },
    {
        "generation": 26,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "Wakhim",
        "father": "",
        "spouse": "adZmBL6zCCbLDwbTrg1Z",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "rSM6yEAkFxRibCLtlxsG"
    },
    {
        "generation": 27,
        "firstname": "Chitramoti",
        "gender": "female",
        "surname": "Anchangbo",
        "father": "",
        "spouse": "fqdqLVyNkvlGvTXTMBIz",
        "firstname_nepali": "चित्रमोती",
        "order": 1,
        "id": "rSlhwSZ7QGcwevQYLzAg"
    },
    {
        "generation": 29,
        "firstname": "Gopi",
        "gender": "female",
        "surname": "ejam",
        "father": "n9tJfeBxMuhTWbJGBIYt",
        "firstname_nepali": "गोपी",
        "order": 1,
        "id": "rZzx4s0uFnFb5TLvcx6f"
    },
    {
        "generation": 30,
        "firstname": "Dilkumari ",
        "gender": "female",
        "surname": "Wanem",
        "father": "",
        "spouse": "kXbMroxtUzS2HS1OUeWK",
        "firstname_nepali": "दिलकुमारी",
        "order": 1,
        "id": "rhVH3MW71NxWqoauRK1i"
    },
    {
        "generation": 29,
        "firstname": "Lalkumari",
        "gender": "female",
        "surname": "Thutlung",
        "father": "",
        "spouse": "WXGqb1YfQDVUjfw1bdjz",
        "firstname_nepali": "लालकुमारी",
        "order": 1,
        "id": "rhqLmJezJpcV6NfrWSV2"
    },
    {
        "generation": 32,
        "firstname": "Dhadkan",
        "gender": "male",
        "surname": "neyong",
        "father": "QAfsfOC2FHOOce7xmu8L",
        "firstname_nepali": "धड्कन",
        "order": 1,
        "id": "rsPYzmXn0Z7N5N4pj7aq"
    },
    {
        "generation": 29,
        "firstname": "Mahadevi",
        "gender": "female",
        "surname": "Thutlung",
        "father": "EdA9PnqNWSa3kR0mDMwC",
        "firstname_nepali": "महादेवी",
        "order": 2,
        "id": "0HbJbdoJ1X8t9Y5JLZZG"
    },
    {
        "generation": 26,
        "firstname": "Dhanpoti",
        "gender": "male",
        "surname": "neyong",
        "father": "7r6MWXFKNvpwHDR6seQN",
        "firstname_nepali": "धनपोटी",
        "order": 2,
        "id": "1SvWh2eWbKIU9uRgAYdS"
    },
    {
        "generation": 28,
        "firstname": "Dilmaya",
        "gender": "female",
        "surname": "Wanem",
        "father": "LXwLyNatGKv4iOisO1yF",
        "firstname_nepali": "दिलमाया",
        "order": 2,
        "id": "3Z4ilGKmooJwPzJJiSg0"
    },
    {
        "generation": 26,
        "firstname": "Abidal",
        "gender": "male",
        "surname": "neyong",
        "father": "O60Ygvjy8PwSkcwHQCHc",
        "firstname_nepali": "अवीदल",
        "order": 2,
        "id": "3bibwxYc96UIXF2UGCkt"
    },
    {
        "generation": 30,
        "firstname": "Pwaguri",
        "gender": "female",
        "surname": "neyong",
        "father": "dfPoFsd66CVmbvKE5kSs",
        "firstname_nepali": "प्वागुरी",
        "order": 2,
        "id": "3cpaTYUCWw3ertjybFSK"
    },
    {
        "generation": 30,
        "firstname": "Til Prasad",
        "gender": "male",
        "surname": "neyong",
        "father": "ql3VFANib5uzCyGbKzGB",
        "firstname_nepali": "तिलप्रसाद",
        "order": 2,
        "id": "44l5dzUKfMqmFiM6WPw1"
    },
    {
        "generation": 31,
        "firstname": "Nishan",
        "gender": "male",
        "surname": "neyong",
        "father": "Z23KepFq1svmYR7FmGz5",
        "firstname_nepali": "निशान",
        "order": 2,
        "id": "4EoXlmXGo6i0fD6VcsRU"
    },
    {
        "generation": 29,
        "firstname": "Shree Prasad",
        "gender": "male",
        "surname": "neyong",
        "father": "n9tJfeBxMuhTWbJGBIYt",
        "firstname_nepali": "श्रीप्रसाद",
        "order": 2,
        "id": "VjLLbXIbb1Pg054VwmSq"
    },
    {
        "generation": 28,
        "firstname": "Sarna Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "YbfnmnrCBHCa4fd8FOHH",
        "firstname_nepali": "सरनबहादुर",
        "order": 2,
        "id": "WXGqb1YfQDVUjfw1bdjz"
    },
    {
        "generation": 26,
        "firstname": "Thakyanba",
        "gender": "male",
        "surname": "neyong",
        "father": "3hu3aXkYoxPY9VA96MJT",
        "firstname_nepali": "थाक्यान्बा",
        "order": 2,
        "id": "XOMjN1Y0H6jnEI7aJe6H"
    },
    {
        "generation": 32,
        "firstname": "Aastha",
        "gender": "female",
        "surname": "neyong",
        "father": "CBy6iQssxc7x7OoKa0fd",
        "firstname_nepali": "आस्था",
        "order": 2,
        "id": "XQlOMEUQtYix3RwdQxdF"
    },
    {
        "generation": 30,
        "firstname": "deviprasad",
        "gender": "male",
        "surname": "neyong",
        "father": "8D4xsNuReFz8qkTRQutD",
        "firstname_nepali": "देवीप्रसाद",
        "order": 2,
        "id": "YBL7z5EuHgONVjBj4m1O"
    },
    {
        "generation": 31,
        "firstname": "Ganga",
        "gender": "female",
        "surname": "neyong",
        "father": "SmvCiXMbxWGrPvxueSXU",
        "firstname_nepali": "गंगा",
        "order": 2,
        "id": "YMmcqNlL3o6yHmE4YIK7"
    },
    {
        "generation": 31,
        "firstname": "Trishna Kala",
        "gender": "female",
        "surname": "neyong",
        "father": "o7fTY7jpN2NLCBeAgJ7y",
        "firstname_nepali": "तृष्णकला",
        "order": 2,
        "id": "YOoeeyktlKECDRx3dla0"
    },
    {
        "generation": 29,
        "firstname": "Kamanshi",
        "gender": "male",
        "surname": "neyong",
        "father": "kNtaIpShWQZl4KPsu6l3",
        "firstname_nepali": "कमानसिं",
        "order": 2,
        "id": "Yht84zfwSdRGQIKzTNh4"
    },
    {
        "generation": 30,
        "firstname": "Sharmila",
        "gender": "female",
        "surname": "neyong",
        "father": "9bNY6EVA4CbzcXIIRNS9",
        "firstname_nepali": "शर्मिला",
        "order": 2,
        "id": "Yke7T0c9XEuWTy3SYHK8"
    },
    {
        "generation": 29,
        "firstname": "Krishna bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "72xTPRMzTjPPQQVDjSSB",
        "firstname_nepali": "कृष्णबहादुर",
        "order": 2,
        "id": "Yq2J0oi42JPTgZ4zkBG7"
    },
    {
        "generation": 29,
        "firstname": "Mina",
        "gender": "female",
        "surname": "neyong",
        "father": "kf4un20w1ssu3R7xYu3Y",
        "firstname_nepali": "मिना",
        "order": 2,
        "id": "YqdPkI5PUFRdTN6ivsjX"
    },
    {
        "generation": 30,
        "firstname": "Bhumika",
        "gender": "female",
        "surname": "neyong",
        "father": "XGfojZ421gtdVs7HspEj",
        "firstname_nepali": "भुमिका",
        "order": 2,
        "id": "YvGSddlhKhzQ0DRb6Lf7"
    },
    {
        "generation": 27,
        "firstname": "Shumsherjung",
        "gender": "male",
        "surname": "neyong",
        "father": "8ALJvsu4rCbrreGPywFx",
        "firstname_nepali": "सम्शेरजङ",
        "order": 2,
        "id": "Yyk6rkgn77Gp8JLUSx97"
    },
    {
        "generation": 30,
        "firstname": "Prem Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "nMftfgKM92GtIvQnUN8Y",
        "firstname_nepali": "प्रेमबहादुर",
        "order": 2,
        "id": "Z23KepFq1svmYR7FmGz5"
    },
    {
        "generation": 29,
        "firstname": "Robertsi",
        "gender": "male",
        "surname": "neyong",
        "father": "7owTCC5XAFs3GtNO7DKV",
        "firstname_nepali": "रोबर्टसी",
        "order": 2,
        "id": "ZWVb5AoCnmIbhMYxyYqO"
    },
    {
        "generation": 31,
        "firstname": "manisha",
        "gender": "female",
        "surname": "neyong",
        "father": "YBL7z5EuHgONVjBj4m1O",
        "firstname_nepali": "मनिशा",
        "order": 2,
        "id": "ZsHCMXn7T2hKP4CpBlir"
    },
    {
        "generation": 28,
        "firstname": "Harkamaya",
        "gender": "female",
        "surname": "Wanem",
        "father": "",
        "spouse": "SGx7IrnzwUx2XvIhDxzG",
        "firstname_nepali": "हर्कमाया",
        "order": 2,
        "id": "ZyONXkA7G3ebkDA08jGt"
    },
    {
        "generation": 30,
        "firstname": "Sukhrani",
        "gender": "female",
        "surname": "neyong",
        "father": "3YLvHWCWdU1XtyPUbQ90",
        "firstname_nepali": "सुखरानी",
        "order": 2,
        "id": "aDRlQwSXKJdc4y5jVBiM"
    },
    {
        "generation": 28,
        "firstname": "Nar Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "sOCofrH1YF9KUC3xrOJh",
        "firstname_nepali": "नरबहादुर",
        "order": 2,
        "id": "aL1bOqcJCxCAedOJZxDi"
    },
    {
        "generation": 30,
        "firstname": "Masine",
        "gender": "male",
        "surname": "neyong",
        "father": "dfPoFsd66CVmbvKE5kSs",
        "firstname_nepali": "मसिना",
        "order": 2,
        "id": "alvHWMf52Ogz6HbrdPOe"
    },
    {
        "generation": 23,
        "firstname": "chatta",
        "gender": "male",
        "surname": "neyong",
        "father": "o7YMAqEasqeSQ0fNZGpp",
        "firstname_nepali": "चत्ता",
        "order": 2,
        "id": "bTm0b2XGVT9OLkzUr00v"
    },
    {
        "generation": 30,
        "firstname": "Shutraraj",
        "gender": "male",
        "surname": "neyong",
        "father": "6EsKckwf0hIIRmTH02kk",
        "firstname_nepali": "शुत्रराज",
        "order": 2,
        "id": "bWVu2ZigPrUWcG1veZ8C"
    },
    {
        "generation": 29,
        "firstname": "Bhanu ",
        "gender": "female",
        "surname": "Shrestha",
        "father": "Imc5TtSjmhclodSnd4M1",
        "firstname_nepali": "भानु",
        "order": 2,
        "id": "cEDgUIqkBOgG6eJ5DyJK"
    },
    {
        "generation": 31,
        "firstname": "Rudraprasad",
        "gender": "male",
        "surname": "neyong",
        "father": "6hdWyig4xeRlNlykDwun",
        "firstname_nepali": "रुट्रप्रसाद",
        "order": 2,
        "id": "cEWRYmoox7UadMNhHeze"
    },
    {
        "generation": 31,
        "firstname": "Pallavi",
        "gender": "female",
        "surname": "neyong",
        "father": "FDuIt3kIlkgc71RRxtLm",
        "firstname_nepali": "पल्लवी",
        "order": 2,
        "id": "cEwhpBp2mCvbI9dh3Rv9"
    },
    {
        "generation": 27,
        "firstname": "Bindra",
        "gender": "female",
        "surname": "Khangba",
        "father": "fqdqLVyNkvlGvTXTMBIz",
        "firstname_nepali": "बिन्द्रा",
        "order": 2,
        "id": "cR4ACBLqiYRl7NP1Ceix"
    },
    {
        "generation": 29,
        "firstname": "Chandramaya",
        "gender": "female",
        "surname": "neyong",
        "father": "h19FdDID6NoY5evIqsdy",
        "firstname_nepali": "चन्द्रमाया",
        "order": 2,
        "id": "dPPLgdS8XHIMqOWhkahg"
    },
    {
        "generation": 30,
        "firstname": "Nilkumar",
        "gender": "male",
        "surname": "neyong",
        "father": "ZagHQ0QrPX2qEzX7GGNG",
        "firstname_nepali": "निलकुमार",
        "order": 2,
        "id": "dTNES491ZUojwI88G1sh"
    },
    {
        "generation": 31,
        "firstname": "mandesh",
        "gender": "male",
        "surname": "neyong",
        "father": "Ows4I1rXmr71R5u8qFzX",
        "firstname_nepali": "मन्देश",
        "order": 2,
        "id": "dgahOTgxZx1cEnNzZDxp"
    },
    {
        "generation": 29,
        "firstname": "Dipendra",
        "gender": "male",
        "surname": "neyong",
        "father": "eXOIzWaaOWilxGaoNY1q",
        "firstname_nepali": "दिपेन्द्र",
        "order": 2,
        "id": "e1TiTW9USSP8HBdOkHle"
    },
    {
        "generation": 29,
        "firstname": "Bimal(Majhaang)",
        "gender": "male",
        "surname": "neyong",
        "father": "h19FdDID6NoY5evIqsdy",
        "firstname_nepali": "बिमल(माझाङ)",
        "order": 2,
        "id": "eJtNxXWWM6QeqBVCA9x4"
    },
    {
        "generation": 29,
        "firstname": "Indra Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "Dt6LJ1nYTuaOj6ZwSW5z",
        "firstname_nepali": "इन्द्रबहादुर",
        "order": 2,
        "id": "eMgtRHWQXym72VbqwpH6"
    },
    {
        "generation": 30,
        "firstname": "Anju",
        "gender": "female",
        "surname": "neyong",
        "father": "kXbMroxtUzS2HS1OUeWK",
        "firstname_nepali": "अन्जु",
        "order": 2,
        "id": "f0n6zsnnYSTuu2S3fbOH"
    },
    {
        "generation": 28,
        "firstname": "Harkamaya",
        "gender": "female",
        "surname": "Barumhang",
        "father": "5td9VaM6nfhcmvFLM28Q",
        "firstname_nepali": "हर्कमाया",
        "order": 2,
        "id": "fhxtArFusASrcZiPHpYJ"
    },
    {
        "generation": 30,
        "firstname": "Rajkumar",
        "gender": "male",
        "surname": "neyong",
        "father": "WklMNwi1KB861pJ1JV8H",
        "firstname_nepali": "राजकुमार",
        "order": 2,
        "id": "gD0SfwHp8VvbIQhWjM9w"
    },
    {
        "generation": 29,
        "firstname": "Shukmati ",
        "gender": "female",
        "surname": "Abheng",
        "father": "",
        "spouse": "5alf3Nw8bulym1dR6cxr",
        "firstname_nepali": "शुक्मति",
        "order": 2,
        "id": "lKMVDBjeFgThNSeA27k7"
    },
    {
        "generation": 28,
        "firstname": "Ram Prasad",
        "gender": "male",
        "surname": "neyong",
        "father": "u5mt0MbVXnMOpYT1sn9h",
        "firstname_nepali": "रामप्रसाद",
        "order": 2,
        "id": "lsyFjL97gAHZ9uRlB1L2"
    },
    {
        "generation": 30,
        "firstname": "Hangmaya",
        "gender": "female",
        "surname": "neyong",
        "father": "dYahEBO0s8A4U63VZfoc",
        "firstname_nepali": "हाङ्माया",
        "order": 2,
        "id": "m86rUdxsa52EzP1URWui"
    },
    {
        "generation": 29,
        "firstname": "Naresh",
        "gender": "male",
        "surname": "neyong",
        "father": "pFAAWd1xpo44DHuts3G2",
        "firstname_nepali": "नरेश",
        "order": 2,
        "id": "mevpY19XY7JJNdJD7oqe"
    },
    {
        "generation": 28,
        "firstname": "Bal Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "SGx7IrnzwUx2XvIhDxzG",
        "firstname_nepali": "बलबहादुर",
        "order": 2,
        "id": "miXHsHQr36HDj79PK0om"
    },
    {
        "generation": 28,
        "firstname": "Maandhoj",
        "gender": "male",
        "surname": "neyong",
        "father": "WX97sNzmxAYBl5FKB3gT",
        "firstname_nepali": "मान्धोज",
        "order": 2,
        "id": "n9tJfeBxMuhTWbJGBIYt"
    },
    {
        "generation": 30,
        "firstname": "Sharan",
        "gender": "male",
        "surname": "neyong",
        "father": "PBfA6yIfHlRwzTqzXRCa",
        "firstname_nepali": "शरण",
        "order": 2,
        "id": "nIVdf0upLrCji1h8BVoP"
    },
    {
        "generation": 29,
        "firstname": "JasBahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "qGZDF5Wc1bgWkE0OC74L",
        "firstname_nepali": "जसबहादुर",
        "order": 2,
        "id": "nMftfgKM92GtIvQnUN8Y"
    },
    {
        "generation": 29,
        "firstname": "Sutraraaj",
        "gender": "male",
        "surname": "neyong",
        "father": "4I2vQ1fiu0BmwI0OlbzY",
        "firstname_nepali": "सूत्रराज",
        "order": 2,
        "id": "ngyZZ89D3lUG54oXEE8d"
    },
    {
        "generation": 29,
        "firstname": "Radhika",
        "gender": "female",
        "surname": "neyong",
        "father": "7CST4AAEgdaJ4I1kx8Jp",
        "firstname_nepali": "राधिका",
        "order": 2,
        "id": "oTiXYZO8s5U9LjhjcgyZ"
    },
    {
        "generation": 30,
        "firstname": "Buddharaj",
        "gender": "male",
        "surname": "neyong",
        "father": "3YLvHWCWdU1XtyPUbQ90",
        "firstname_nepali": "बुद्धराज",
        "order": 2,
        "id": "p95aAGX1OMgLGfBmeLoB"
    },
    {
        "generation": 28,
        "firstname": "Birkhamati",
        "gender": "female",
        "surname": "neyong",
        "father": "WX97sNzmxAYBl5FKB3gT",
        "firstname_nepali": "बिखमती",
        "order": 2,
        "id": "q7fPJBZNrHkhdUpswxou"
    },
    {
        "generation": 29,
        "firstname": "Shree Prasad",
        "gender": "male",
        "surname": "neyong",
        "father": "sSRNStHwtiSf582KrlVl",
        "firstname_nepali": "श्रीप्रसाद",
        "order": 2,
        "id": "qZq4Ynfi4NIXYGFOzvfy"
    },
    {
        "generation": 29,
        "firstname": "Gita",
        "gender": "female",
        "surname": "Khajum",
        "father": "5alf3Nw8bulym1dR6cxr",
        "firstname_nepali": "गीता",
        "order": 2,
        "id": "r14PzHuiN4L9YRCUZZRk"
    },
    {
        "generation": 30,
        "firstname": "Ganga",
        "gender": "female",
        "surname": "neyong",
        "father": "ikiGkJgxhaFwSdYUeXQ1",
        "firstname_nepali": "गंगा",
        "order": 2,
        "id": "r6EOEASHDaTfpOzNsuWy"
    },
    {
        "generation": 29,
        "firstname": "Somkumari",
        "gender": "female",
        "surname": "Thatlung",
        "father": "n9tJfeBxMuhTWbJGBIYt",
        "firstname_nepali": "सोमकुमारी",
        "order": 2,
        "id": "rIiDjoXrSGUUb3JvWtUU"
    },
    {
        "generation": 30,
        "firstname": "Dil Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "a56rJXA0e9D9degC1yWe",
        "firstname_nepali": "दिलबहादुर",
        "order": 2,
        "id": "rVMIGfx3p6tCwS3GyGLD"
    },
    {
        "generation": 30,
        "firstname": "Binu",
        "gender": "female",
        "surname": "Rai",
        "father": "JmQAJQarEcVQ8v9jXrF9",
        "firstname_nepali": "विणु",
        "order": 2,
        "id": "sGddpYPH5J82MwhL4XTr"
    },
    {
        "generation": 28,
        "firstname": "Balmaya",
        "gender": "female",
        "surname": "Maden",
        "father": "sOCofrH1YF9KUC3xrOJh",
        "firstname_nepali": "बलमाया",
        "order": 2,
        "id": "sLYXwwinRbxdAaHYYrBv"
    },
    {
        "generation": 30,
        "firstname": "bishnu",
        "gender": "male",
        "surname": "neyong",
        "father": "zGv5Oqm4WMll6hXgZLv5",
        "firstname_nepali": "विष्णु",
        "order": 3,
        "id": "11PRMH2SDGaWGJxTpfpM"
    },
    {
        "generation": 30,
        "firstname": "Ramkumar",
        "gender": "male",
        "surname": "neyong",
        "father": "ikiGkJgxhaFwSdYUeXQ1",
        "firstname_nepali": "रामकुमार",
        "order": 3,
        "id": "17463VFfQeaeL5k6dDFt"
    },
    {
        "generation": 27,
        "firstname": "Fagohang",
        "gender": "male",
        "surname": "neyong",
        "father": "3bibwxYc96UIXF2UGCkt",
        "firstname_nepali": "फागोहाङ",
        "order": 3,
        "id": "1GepDR9JBG91M6qmNVTG"
    },
    {
        "generation": 25,
        "firstname": "Sanjel",
        "gender": "male",
        "surname": "neyong",
        "father": "jah7fN311IS4msHcF6Tk",
        "firstname_nepali": "सञ्जेल",
        "order": 3,
        "id": "1UXPIm8YAP88TZ9jxl3f"
    },
    {
        "generation": 26,
        "firstname": "Kanchoba",
        "gender": "male",
        "surname": "neyong",
        "father": "UXgo4mpm0X4z5PtPciRX",
        "firstname_nepali": "कञ्चोबा",
        "order": 3,
        "id": "2ACsb6YKCCyXefmfgN0q"
    },
    {
        "generation": 30,
        "firstname": "Tara",
        "gender": "female",
        "surname": "Tamang",
        "father": "re3rudyfMy7PkrwnU1j3",
        "firstname_nepali": "तारा",
        "order": 3,
        "id": "3Qh4bW0pnEYIaunxaIOQ"
    },
    {
        "generation": 29,
        "firstname": "Til Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "72xTPRMzTjPPQQVDjSSB",
        "firstname_nepali": "तिलबहादुर",
        "order": 3,
        "id": "4hgiuk5Lx0SrjwY3D01R"
    },
    {
        "generation": 29,
        "firstname": "Tanduri",
        "gender": "female",
        "surname": "neyong",
        "father": "h19FdDID6NoY5evIqsdy",
        "firstname_nepali": "तन्दुरी",
        "order": 3,
        "id": "WaJlkL3VeJohhTQ37nUt"
    },
    {
        "generation": 29,
        "firstname": "Purnasi",
        "gender": "male",
        "surname": "neyong",
        "father": "qGZDF5Wc1bgWkE0OC74L",
        "firstname_nepali": "पूर्णसिं",
        "order": 3,
        "id": "WklMNwi1KB861pJ1JV8H"
    },
    {
        "generation": 29,
        "firstname": "Santa bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "wPQMm9eZcmVTM3W7ZQY8",
        "firstname_nepali": "सन्तबहादुर",
        "order": 3,
        "id": "XG5zBowNuZkDdinIpOYk"
    },
    {
        "generation": 29,
        "firstname": "Rupendra",
        "gender": "male",
        "surname": "neyong",
        "father": "eXOIzWaaOWilxGaoNY1q",
        "firstname_nepali": "रुपेन्द्र",
        "order": 3,
        "id": "XGfojZ421gtdVs7HspEj"
    },
    {
        "generation": 27,
        "firstname": "Nar Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "bRkDszHtx7zqUlerNeer",
        "firstname_nepali": "नरबहादुर",
        "order": 3,
        "id": "YbfnmnrCBHCa4fd8FOHH"
    },
    {
        "generation": 30,
        "firstname": "Ranmati",
        "gender": "female",
        "surname": "Rai",
        "father": "ZagHQ0QrPX2qEzX7GGNG",
        "firstname_nepali": "रनमति",
        "order": 4,
        "id": "0u54B61cGY96dP8Dnh5l"
    },
    {
        "generation": 30,
        "firstname": "Chanda",
        "gender": "female",
        "surname": "Rai",
        "father": "WklMNwi1KB861pJ1JV8H",
        "firstname_nepali": "चन्दा",
        "order": 4,
        "id": "1IxEuCiG8ozMISUQ4OWy"
    },
    {
        "generation": 30,
        "firstname": "Manu ",
        "gender": "female",
        "surname": "Tamang",
        "father": "JQhJS0JoOub4wEDU26Yw",
        "firstname_nepali": "मनु",
        "order": 4,
        "id": "1kWrP4C4Ctruq2AJ35SW"
    },
    {
        "generation": 29,
        "firstname": "Sabin",
        "gender": "male",
        "surname": "neyong",
        "father": "5alf3Nw8bulym1dR6cxr",
        "firstname_nepali": "सबिन",
        "order": 4,
        "id": "2aaeU76ANyCUCJGvmjpu"
    },
    {
        "generation": 30,
        "firstname": "Parwati",
        "gender": "female",
        "surname": "neyong",
        "father": "Yht84zfwSdRGQIKzTNh4",
        "firstname_nepali": "पार्वती",
        "order": 4,
        "id": "WAIQNrbJVmZh7PrOu1r8"
    },
    {
        "generation": 30,
        "firstname": "Samjhana",
        "gender": "female",
        "surname": "neyong",
        "father": "pLnkJiXFiQcdylhCcZcB",
        "firstname_nepali": "सम्झना",
        "order": 4,
        "id": "WIJuxgg4ojmlQK6zh9YU"
    },
    {
        "generation": 30,
        "firstname": "Indramaya",
        "gender": "female",
        "surname": "Gurung",
        "father": "ZagHQ0QrPX2qEzX7GGNG",
        "firstname_nepali": "इन्द्रमाया",
        "order": 5,
        "id": "35NXaiPxSJk1mUpszOCi"
    },
    {
        "generation": 30,
        "firstname": "Hemkala",
        "gender": "female",
        "surname": "neyong",
        "father": "ql3VFANib5uzCyGbKzGB",
        "firstname_nepali": "हेमकला",
        "order": 5,
        "id": "W0MvtuowBL36y8G0EOLk"
    },
    {
        "generation": 30,
        "firstname": "Kaushalia",
        "gender": "female",
        "surname": "neyong",
        "father": "re3rudyfMy7PkrwnU1j3",
        "firstname_nepali": "कौशलिया",
        "order": 6,
        "id": "0V0o8ztMP3dlcvm43n2h"
    },
    {
        "generation": 24,
        "firstname": "indramaan",
        "gender": "male",
        "surname": "neyong",
        "father": "PoTRFDxUu9kJRxkv5ok7",
        "firstname_nepali": "इन्द्रमान",
        "order": 6,
        "id": "2rrv38hfE3O09McbP2yp"
    },
    {
        "generation": 29,
        "firstname": "Parwati",
        "gender": "female",
        "surname": "Katwal",
        "father": "IWuqwgwdrs0s9uGJTP95",
        "firstname_nepali": "पार्वती",
        "order": 7,
        "id": "20FEL7PRcDnVEaUsttgI"
    },
    {
        "generation": 30,
        "firstname": "Harkamaya",
        "gender": "female",
        "surname": "Okhrabu",
        "father": "",
        "spouse": "t5MeCY6EJVRvW7u70xxz",
        "firstname_nepali": "हर्कमाया",
        "id": "2BZseIYONU2M44o01LKw"
    },
    {
        "generation": 20,
        "firstname": "kumfuko",
        "gender": "male",
        "surname": "neyong",
        "father": "eIJxjMlMKrSpXWPTR8dS",
        "firstname_nepali": "कुम्फुको",
        "id": "2VXuIXdhP2Hf5hGXuEk4"
    },
    {
        "generation": 15,
        "firstname": "pamyangla",
        "gender": "male",
        "surname": "neyong",
        "father": "QlQuLcv7cRn31nMMMIiU",
        "firstname_nepali": "पम्याङला",
        "id": "3IWRqWgEVwrcz3noFReA"
    },
    {
        "generation": 30,
        "firstname": "Rupamaya",
        "gender": "female",
        "surname": "Okhrabu",
        "father": "",
        "spouse": "ImkXMy2dTkAhNihvm6zk",
        "firstname_nepali": "रुपामाया",
        "id": "4jorlpvTMRjTgStsUA6y"
    },
    {
        "generation": 30,
        "firstname": "Shukmaya",
        "gender": "female",
        "surname": "Kedem",
        "father": "JQhJS0JoOub4wEDU26Yw",
        "firstname_nepali": "शुक्माया",
        "order": 1,
        "id": "4nqWLoyaNzM5Hv5ajLgS"
    },
    {
        "generation": 31,
        "firstname": "Khinakumari",
        "gender": "female",
        "surname": "neyong",
        "father": "I2Ri8A10eCMMJWHWXkg2",
        "firstname_nepali": "खिनाकुमारी",
        "order": 1,
        "id": "5L16fS4SFaW5JJn7Fh5T"
    },
    {
        "generation": 29,
        "firstname": "Purnamaya",
        "gender": "female",
        "surname": "Pangthak",
        "father": "",
        "spouse": "pFAAWd1xpo44DHuts3G2",
        "firstname_nepali": "पूर्णमाया",
        "order": 1,
        "id": "5i4s9GJDDTR48vXpX6kW"
    },
    {
        "generation": 30,
        "firstname": "Mangalu",
        "gender": "male",
        "surname": "neyong",
        "father": "5st3NwcppZWvJGlqoFz1",
        "firstname_nepali": "मङ्गलु",
        "order": 1,
        "id": "5yXaQZttmb4Dc4Pmz7C5"
    },
    {
        "generation": 29,
        "firstname": "Aashmati",
        "gender": "female",
        "surname": "neyong",
        "father": "h19FdDID6NoY5evIqsdy",
        "firstname_nepali": "आशमती",
        "order": 1,
        "id": "65DBFCUb3OXDPc6jOirt"
    },
    {
        "generation": 29,
        "firstname": "Birkha Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "WXGqb1YfQDVUjfw1bdjz",
        "firstname_nepali": "बिरखबहादुर",
        "order": 1,
        "id": "6EsKckwf0hIIRmTH02kk"
    },
    {
        "generation": 27,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "Unknown",
        "father": "",
        "spouse": "Tc40zxg3u4FxyFEqBH1R",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "6JwcbbFZqluvcHwZLIsw"
    },
    {
        "generation": 30,
        "firstname": "Shreehang",
        "gender": "male",
        "surname": "neyong",
        "father": "lvJOdENSgGMIGztLY2cU",
        "firstname_nepali": "श्रीहाङ",
        "order": 1,
        "id": "6hdWyig4xeRlNlykDwun"
    },
    {
        "generation": 28,
        "firstname": "Chibhyamaya",
        "gender": "female",
        "surname": "neyong",
        "father": "ElxKj4EA459ijYl9IW1K",
        "firstname_nepali": "चिभ्यामाया",
        "order": 1,
        "id": "6ttShltGctdAErY1UR0r"
    },
    {
        "generation": 26,
        "firstname": "Hangsakar",
        "gender": "male",
        "surname": "neyong",
        "father": "UXgo4mpm0X4z5PtPciRX",
        "firstname_nepali": "हङ्साकर",
        "order": 1,
        "id": "6vWu1oEuH6XYPNOOU0Pm"
    },
    {
        "generation": 29,
        "firstname": "Ranjita",
        "gender": "female",
        "surname": "Shreng Chongbang",
        "father": "7owTCC5XAFs3GtNO7DKV",
        "firstname_nepali": "रञ्जिता",
        "order": 1,
        "id": "7ArRj9nXUVoG06Lojd9a"
    },
    {
        "generation": 29,
        "firstname": "Karna Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "txwvFLftgmHOR0RxlO9A",
        "firstname_nepali": "कर्णबहादुर",
        "order": 1,
        "id": "7N9LhsItnNzrVSHof5lC"
    },
    {
        "generation": 24,
        "firstname": "dharmajeet",
        "gender": "male",
        "surname": "neyong",
        "father": "PoTRFDxUu9kJRxkv5ok7",
        "order": 1,
        "firstname_nepali": "धर्मजीत",
        "id": "7OBd3Izm7KcYOBJABNRS"
    },
    {
        "generation": 30,
        "firstname": "Rolex",
        "gender": "male",
        "surname": "neyong",
        "father": "R2HEcMIqsvQa7kON8pm1",
        "firstname_nepali": "रोलेक्स",
        "order": 1,
        "id": "7Q3z15ANuRqYbqJfLBh5"
    },
    {
        "generation": 29,
        "firstname": "Netramaya",
        "gender": "female",
        "surname": "Maden",
        "father": "",
        "spouse": "n9tJfeBxMuhTWbJGBIYt",
        "firstname_nepali": "नेत्रमाया",
        "order": 1,
        "id": "7gsZ30xSA5iTRVAxMQVw"
    },
    {
        "generation": 28,
        "firstname": "Kamansi",
        "gender": "male",
        "surname": "neyong",
        "father": "LXwLyNatGKv4iOisO1yF",
        "firstname_nepali": "कमांसी",
        "order": 1,
        "id": "7owTCC5XAFs3GtNO7DKV"
    },
    {
        "generation": 29,
        "firstname": "Anil",
        "gender": "male",
        "surname": "neyong",
        "father": "J2ac0kCcTxVQjo4TbSqr",
        "firstname_nepali": "अनिल",
        "order": 1,
        "id": "7vwoJ0rjGoES06427baq"
    },
    {
        "generation": 26,
        "firstname": "Amrit",
        "gender": "male",
        "surname": "neyong",
        "father": "O60Ygvjy8PwSkcwHQCHc",
        "firstname_nepali": "अमृत",
        "order": 1,
        "id": "8ALJvsu4rCbrreGPywFx"
    },
    {
        "generation": 31,
        "firstname": "sandesh",
        "gender": "male",
        "surname": "neyong",
        "father": "Ows4I1rXmr71R5u8qFzX",
        "firstname_nepali": "सन्देश",
        "order": 1,
        "id": "8BUdkoqYxUryqDpawJh1"
    },
    {
        "generation": 28,
        "firstname": "Harkamaya",
        "gender": "female",
        "surname": "F.Chongbang",
        "father": "",
        "spouse": "sOCofrH1YF9KUC3xrOJh",
        "firstname_nepali": "हर्कमाया",
        "order": 1,
        "id": "8By1NqwrWPwdlaWZ6MWn"
    },
    {
        "generation": 29,
        "firstname": "dambardhoj",
        "gender": "male",
        "surname": "neyong",
        "father": "P4Ad5J4jIDcrNpV1oIHJ",
        "firstname_nepali": "डम्बरधोज",
        "order": 1,
        "id": "8D4xsNuReFz8qkTRQutD"
    },
    {
        "generation": 30,
        "firstname": "Nisha",
        "gender": "female",
        "surname": "Senihang",
        "father": "",
        "spouse": "QRWYRJQVys6Kmf3z3o5n",
        "firstname_nepali": "निशा",
        "order": 1,
        "id": "8bwwqnaV93RBcGGLVAlz"
    },
    {
        "generation": 28,
        "firstname": "Purnahaang",
        "gender": "male",
        "surname": "neyong",
        "father": "5td9VaM6nfhcmvFLM28Q",
        "firstname_nepali": "पूर्णहाङ",
        "order": 1,
        "id": "8byoiXRAjaQtsg9Yrz0y"
    },
    {
        "generation": 27,
        "firstname": "Dilbir",
        "gender": "male",
        "surname": "neyong",
        "father": "Tc40zxg3u4FxyFEqBH1R",
        "firstname_nepali": "दिलबिर",
        "order": 1,
        "id": "8eJ4IyOHA5yVmikYooxe"
    },
    {
        "generation": 27,
        "firstname": "Aananda",
        "gender": "male",
        "surname": "neyong",
        "father": "bRkDszHtx7zqUlerNeer",
        "firstname_nepali": "आनन्द",
        "order": 2,
        "id": "4n7wtNiYezMWJmIuefPx"
    },
    {
        "generation": 31,
        "firstname": "Rabindra",
        "gender": "male",
        "surname": "neyong",
        "father": "VFchMsM9H5j3ikGSkG5h",
        "firstname_nepali": "रविन्द्र",
        "order": 2,
        "id": "5Q2fAze5lT6VHCKoHwyb"
    },
    {
        "generation": 28,
        "firstname": "Dal Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "5td9VaM6nfhcmvFLM28Q",
        "firstname_nepali": "दलबहादुर",
        "order": 2,
        "id": "5alf3Nw8bulym1dR6cxr"
    },
    {
        "generation": 29,
        "firstname": "Jit bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "wPQMm9eZcmVTM3W7ZQY8",
        "firstname_nepali": "जितबहादुर",
        "order": 2,
        "id": "5st3NwcppZWvJGlqoFz1"
    },
    {
        "generation": 27,
        "firstname": "Maanbir",
        "gender": "male",
        "surname": "neyong",
        "father": "OoNWKOdqDioDWMqkiCCT",
        "firstname_nepali": "मानबीर",
        "order": 2,
        "id": "5td9VaM6nfhcmvFLM28Q"
    },
    {
        "generation": 28,
        "firstname": "kabidhoj",
        "gender": "male",
        "surname": "neyong",
        "father": "BNIMygjbAj4nQvDxUcfJ",
        "firstname_nepali": "कविधोज",
        "order": 2,
        "id": "72xTPRMzTjPPQQVDjSSB"
    },
    {
        "generation": 25,
        "firstname": "gumdal",
        "surname": "neyong",
        "father": "7OBd3Izm7KcYOBJABNRS",
        "firstname_nepali": "गुमदल",
        "order": "2",
        "gender": "male",
        "id": "7c1CIpD6IZ1jmutSRVmR"
    },
    {
        "generation": 25,
        "firstname": "Jiripal",
        "gender": "male",
        "surname": "neyong",
        "father": "yQtDCK1tH6s4kX53TfY2",
        "firstname_nepali": "जिरीपाल",
        "order": 2,
        "id": "7ePmlqWJ7vtmaVhQAxUG"
    },
    {
        "generation": 28,
        "firstname": "Raj bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "1GepDR9JBG91M6qmNVTG",
        "firstname_nepali": "राज बहादुर",
        "order": 2,
        "id": "7qraaWx47ZiKZFyQNupe"
    },
    {
        "generation": 31,
        "firstname": "Shanti",
        "gender": "female",
        "surname": "neyong",
        "father": "myeaPpjzfdFflJDYBXqd",
        "firstname_nepali": "शान्ति",
        "order": 2,
        "id": "8YkE2KzG3C4CNmP0eEi5"
    },
    {
        "generation": 27,
        "firstname": "Ram Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "fqdqLVyNkvlGvTXTMBIz",
        "firstname_nepali": "रामबहादुर",
        "order": 3,
        "id": "5anPZv9lMj5313AGy3L0"
    },
    {
        "generation": 30,
        "firstname": "Shivkumar",
        "gender": "male",
        "surname": "neyong",
        "father": "nMftfgKM92GtIvQnUN8Y",
        "firstname_nepali": "शिवकुमार",
        "order": 3,
        "id": "5x8aVF3lmWNp0yGiXeWV"
    },
    {
        "generation": 30,
        "firstname": "Sobha",
        "gender": "female",
        "surname": "neyong",
        "father": "a56rJXA0e9D9degC1yWe",
        "firstname_nepali": "शोभा",
        "order": 3,
        "id": "6KNFc9lWOoldN2tFQ8CJ"
    },
    {
        "generation": 28,
        "firstname": "Khadga Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "SGx7IrnzwUx2XvIhDxzG",
        "firstname_nepali": "खड्गबहादुर",
        "order": 3,
        "id": "7CST4AAEgdaJ4I1kx8Jp"
    },
    {
        "generation": 29,
        "firstname": "Shyamkumar",
        "gender": "male",
        "surname": "neyong",
        "father": "OM9MJEJFRT2bKOAo3iUi",
        "firstname_nepali": "श्यामकुमार",
        "order": 4,
        "id": "5XUZi41DfWTObqElfpUQ"
    },
    {
        "generation": 25,
        "firstname": "Mukundashi",
        "gender": "male",
        "surname": "neyong",
        "father": "jah7fN311IS4msHcF6Tk",
        "firstname_nepali": "मुकुन्दशी",
        "order": 4,
        "id": "7r6MWXFKNvpwHDR6seQN"
    },
    {
        "generation": 31,
        "firstname": "Harkamaya",
        "gender": "female",
        "surname": "Maden",
        "father": "6hdWyig4xeRlNlykDwun",
        "firstname_nepali": "हर्कमाया",
        "order": 5,
        "id": "6o0GCv0juir6TQ0yFLQt"
    },
    {
        "generation": 30,
        "firstname": "Maya",
        "gender": "female",
        "surname": "Liwang",
        "father": "Yht84zfwSdRGQIKzTNh4",
        "firstname_nepali": "माया",
        "order": 6,
        "id": "5ll6Vbc9e2P94oL33Ee9"
    },
    {
        "generation": 30,
        "firstname": "Krishnamaya",
        "gender": "female",
        "surname": "neyong",
        "father": "ZagHQ0QrPX2qEzX7GGNG",
        "firstname_nepali": "कृष्णमाया",
        "order": 6,
        "id": "6LwCdifW46FlAAmuyyuC"
    },
    {
        "generation": 30,
        "firstname": "Sanja",
        "gender": "female",
        "surname": "neyong",
        "father": "Yht84zfwSdRGQIKzTNh4",
        "firstname_nepali": "सन्जा",
        "order": 7,
        "id": "54PpX4jC0UqmAmJ7m8ct"
    },
    {
        "generation": "13",
        "firstname": "samdere",
        "gender": "male",
        "surname": "neyong",
        "father": "at4H7DeCucIeHVt2huDU",
        "firstname_nepali": "सम्देरे",
        "id": "73lMqvlywWogOLIpmWoB"
    },
    {
        "generation": 6,
        "firstname": "hathitong",
        "gender": "male",
        "surname": "neyong",
        "father": "r5088R2zbGzMSD7iN2a9",
        "firstname_nepali": "हाथितोंग",
        "id": "82hpLlo0TRb8GZlSCbMp"
    },
    {
        "generation": 25,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "Unknown",
        "father": "",
        "spouse": "jah7fN311IS4msHcF6Tk",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "8hH81vVzJMocK3ZudzTc"
    },
    {
        "generation": 29,
        "firstname": "Sukmati",
        "gender": "female",
        "surname": "Okhrabu",
        "father": "",
        "spouse": "txwvFLftgmHOR0RxlO9A",
        "firstname_nepali": "सुक्मती",
        "order": 1,
        "id": "8wpk6r6Xp8nIoymfQsef"
    },
    {
        "generation": 29,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "",
        "spouse": "N9O37TuJcTeet1Ku981Y",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "92iItYq94P23YK0MEVu3"
    },
    {
        "generation": 30,
        "firstname": "Ram Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "PBfA6yIfHlRwzTqzXRCa",
        "firstname_nepali": "रामबहादुर",
        "order": 1,
        "id": "9CNUHRVYFzurEYQOIpAw"
    },
    {
        "generation": 30,
        "firstname": "Mamata",
        "gender": "female",
        "surname": "neyong",
        "father": "e1TiTW9USSP8HBdOkHle",
        "firstname_nepali": "ममता",
        "order": 1,
        "id": "9YuHhwr1JET7XNL4gJHP"
    },
    {
        "generation": 26,
        "firstname": "Dhanmoti",
        "gender": "female",
        "surname": "neyong",
        "father": "",
        "spouse": "7r6MWXFKNvpwHDR6seQN",
        "firstname_nepali": "धनमोती",
        "order": 1,
        "id": "9epkCB0brMqi6gQpjPno"
    },
    {
        "generation": 30,
        "firstname": "Manjila",
        "gender": "female",
        "surname": "Sawa",
        "father": "",
        "spouse": "46WnSvKxL1rPJwBRvQ9h",
        "firstname_nepali": "मञ्जिला",
        "order": 1,
        "id": "9hCzp7un2M0suIVlZtbS"
    },
    {
        "generation": 30,
        "firstname": "Manmaya",
        "gender": "female",
        "surname": "Shreng",
        "father": "",
        "spouse": "3YLvHWCWdU1XtyPUbQ90",
        "firstname_nepali": "मनमाया",
        "order": 1,
        "id": "9qZ95wB1pWegfSI5LqNm"
    },
    {
        "generation": 30,
        "firstname": "Dina",
        "gender": "female",
        "surname": "neyong",
        "father": "gEFDJ1HrpPxoVZbXqjjS",
        "firstname_nepali": "दिना",
        "order": 1,
        "id": "A2PJF8Iul2nbokDwMwtE"
    },
    {
        "generation": 30,
        "firstname": "Aaitamaya",
        "gender": "female",
        "surname": "Thopra",
        "father": "",
        "spouse": "JQhJS0JoOub4wEDU26Yw",
        "firstname_nepali": "आइतमाया",
        "order": 1,
        "id": "ACcC0rYdvDwjMAryF0zJ"
    },
    {
        "generation": 31,
        "firstname": "Nusemi",
        "gender": "female",
        "surname": "neyong",
        "father": "Q2nWgOPjLsVzQzFPzzUW",
        "firstname_nepali": "नुसेमी",
        "order": 1,
        "id": "ADCdQ2Ab7ydz2yRivcb4"
    },
    {
        "generation": 30,
        "firstname": "Khadka Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "dYahEBO0s8A4U63VZfoc",
        "firstname_nepali": "खड्कबहादुर",
        "order": 1,
        "id": "AL7VKTpdbjDTc8nup4J1"
    },
    {
        "generation": 32,
        "firstname": "Rukson",
        "gender": "male",
        "surname": "neyong",
        "father": "cEWRYmoox7UadMNhHeze",
        "firstname_nepali": "रुक्सन",
        "order": 1,
        "id": "ATT1dzun9FFJaQAqm72f"
    },
    {
        "generation": 31,
        "firstname": "Bishal",
        "gender": "male",
        "surname": "neyong",
        "father": "vPJscrn8uDcIxyqnFaZk",
        "firstname_nepali": "बिशाल",
        "order": 1,
        "id": "AtNM5Dz045YELSioLryn"
    },
    {
        "generation": 31,
        "firstname": "Nitesh",
        "gender": "male",
        "surname": "neyong",
        "father": "Z23KepFq1svmYR7FmGz5",
        "firstname_nepali": "नितेश",
        "order": 1,
        "id": "BAUXxiJCtdvQgUldVL5h"
    },
    {
        "generation": 29,
        "firstname": "Uttam",
        "gender": "male",
        "surname": "neyong",
        "father": "lsyFjL97gAHZ9uRlB1L2",
        "firstname_nepali": "उत्तम",
        "order": 1,
        "id": "BAUwIFAOsZNMakNXx3W0"
    },
    {
        "generation": 28,
        "firstname": "Manlachi",
        "gender": "female",
        "surname": "Mangden",
        "father": "",
        "spouse": "InqYU3gbNlXbuagAYZVM",
        "firstname_nepali": "मनलाछी",
        "order": 1,
        "id": "BEN6v1F0luVob9zhPHE0"
    },
    {
        "generation": 29,
        "firstname": "Gajendra",
        "gender": "male",
        "surname": "neyong",
        "father": "dQxa13Ho5vQJGuMiKV2D",
        "firstname_nepali": "गजेन्द्र",
        "order": 1,
        "id": "BUxXzy0dJK8jQYWWZnCT"
    },
    {
        "generation": 32,
        "firstname": "Kalpana",
        "gender": "female",
        "surname": "Sambahamfe",
        "father": "",
        "spouse": "cEWRYmoox7UadMNhHeze",
        "firstname_nepali": "कल्पना",
        "order": 1,
        "id": "BlitfSwjYtEirZQ1kkhr"
    },
    {
        "generation": 29,
        "firstname": "Bishal",
        "gender": "male",
        "surname": "neyong",
        "father": "tHJfnfHlXZjOmPQjbfST",
        "firstname_nepali": "बिशाल",
        "order": 1,
        "id": "C4Hbyl1OjyZlo9byidsM"
    },
    {
        "generation": 31,
        "firstname": "Bulbul",
        "gender": "female",
        "surname": "neyong",
        "father": "IdBmWceSURfNlN1qwGLV",
        "firstname_nepali": "बुलबुल",
        "order": 1,
        "id": "CAHI2UPvIQVzkanc4ARR"
    },
    {
        "generation": 31,
        "firstname": "Mankumar",
        "gender": "male",
        "surname": "neyong",
        "father": "Yaav4K0D7iLo7WPTeLlx",
        "firstname_nepali": "मनकुमार",
        "order": 1,
        "id": "CBy6iQssxc7x7OoKa0fd"
    },
    {
        "generation": 30,
        "firstname": "Nita",
        "gender": "female",
        "surname": "Sambahamphe",
        "father": "",
        "spouse": "gEFDJ1HrpPxoVZbXqjjS",
        "firstname_nepali": "नीता",
        "order": 1,
        "id": "CDmSOLhWYStEY3rwPCoB"
    },
    {
        "generation": 30,
        "firstname": "Senjen",
        "gender": "male",
        "surname": "neyong",
        "father": "hQyPAM4dDLfI5ShleBx2",
        "firstname_nepali": "सेन्जेन",
        "order": 1,
        "id": "CV9WJRCYNT3IdDYz33G0"
    },
    {
        "generation": 27,
        "firstname": "Shreelal",
        "gender": "male",
        "surname": "neyong",
        "father": "exx5e7ZeKORqWUlq50nv",
        "firstname_nepali": "श्रीलाल",
        "order": 1,
        "id": "CuMwzHx4dHOkCKMfS3Kq"
    },
    {
        "generation": 30,
        "firstname": "Sukh Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "dfPoFsd66CVmbvKE5kSs",
        "firstname_nepali": "सुखबहादुर",
        "order": 1,
        "id": "CyNHSDAhhCsVwZyzK0zu"
    },
    {
        "generation": 31,
        "firstname": "Mankumari",
        "gender": "female",
        "surname": "Nalwo",
        "father": "",
        "spouse": "Q2nWgOPjLsVzQzFPzzUW",
        "firstname_nepali": "मनकुमारी",
        "order": 1,
        "id": "D9GEvrxwOmIeI0FiRkgG"
    },
    {
        "generation": 30,
        "firstname": "Barsha",
        "gender": "female",
        "surname": "Sambahamphe",
        "father": "",
        "spouse": "eJtNxXWWM6QeqBVCA9x4",
        "firstname_nepali": "बर्षा",
        "order": 1,
        "id": "DVJLn8LFJc3aSdlgDbLr"
    },
    {
        "generation": 27,
        "firstname": "sangdal (naaksade)",
        "gender": "male",
        "surname": "neyong",
        "father": "Weft1rWYsac8B7RlO9LL",
        "firstname_nepali": "संगदल (नाक्सोडे)",
        "order": 1,
        "id": "DXyM4QUXaltfQGF7rLZT"
    },
    {
        "generation": 29,
        "firstname": "Manmaya/Fiprani/Purnamaya",
        "gender": "female",
        "surname": "Sawa",
        "father": "",
        "spouse": "IWuqwgwdrs0s9uGJTP95",
        "firstname_nepali": "मनमाया/फिप्रानी/पूर्णमाया",
        "order": 1,
        "id": "DdkcisCTxWepQtXuguOd"
    },
    {
        "generation": 30,
        "firstname": "Dilkumari",
        "gender": "female",
        "surname": "Sambahamphe",
        "father": "",
        "spouse": "5st3NwcppZWvJGlqoFz1",
        "firstname_nepali": "दिलकुमारी",
        "order": 1,
        "id": "Dt3uj0nRXhhi2PYLOcE4"
    },
    {
        "generation": 31,
        "firstname": "Aadesh",
        "gender": "male",
        "surname": "neyong",
        "father": "SVUYjvlN8CYKYa6LiHKQ",
        "firstname_nepali": "आदेश",
        "order": 1,
        "id": "EOMiuVg8x9YIABwSLClz"
    },
    {
        "generation": 28,
        "firstname": "Sal bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "qESM7BN6tjwDD9nhQGT3",
        "firstname_nepali": "सालबहादुर",
        "order": 1,
        "id": "EdA9PnqNWSa3kR0mDMwC"
    },
    {
        "generation": 27,
        "firstname": "Jamashi",
        "gender": "male",
        "surname": "neyong",
        "father": "2ACsb6YKCCyXefmfgN0q",
        "firstname_nepali": "जमाशी",
        "order": 1,
        "id": "ElxKj4EA459ijYl9IW1K"
    },
    {
        "generation": 28,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "",
        "spouse": "qESM7BN6tjwDD9nhQGT3",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "EqswYrqCIDHY9XQZ9Gpk"
    },
    {
        "generation": 28,
        "firstname": "Dhanmaya",
        "gender": "female",
        "surname": "Okhrabu",
        "father": "ZotaxQ2AZ50SaSBP7UfA",
        "firstname_nepali": "धनमाया",
        "order": 1,
        "id": "FAC7gszSoiDVz1exCM7E"
    },
    {
        "generation": 30,
        "firstname": "Ratna Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "nMftfgKM92GtIvQnUN8Y",
        "firstname_nepali": "रत्नबहादुर",
        "order": 1,
        "id": "FDuIt3kIlkgc71RRxtLm"
    },
    {
        "generation": 31,
        "firstname": "Roshan",
        "gender": "male",
        "surname": "neyong",
        "father": "SmvCiXMbxWGrPvxueSXU",
        "firstname_nepali": "रोशन",
        "order": 1,
        "id": "FStKUEz6kIO6VmdNhHIB"
    },
    {
        "generation": 31,
        "firstname": "Samundra",
        "gender": "male",
        "surname": "neyong",
        "father": "mALZzIFULNi9IhNMAXr3",
        "firstname_nepali": "समुद्र",
        "order": 2,
        "id": "9N66ltabsuVEhzWGQ444"
    },
    {
        "generation": 30,
        "firstname": "Kurnakumar",
        "gender": "male",
        "surname": "neyong",
        "father": "OwV0mfHCf1GVYHiL7E4x",
        "firstname_nepali": "कुर्णकुमार",
        "order": 2,
        "id": "ABwWIDQiWoRqXAHxoXbw"
    },
    {
        "generation": 30,
        "firstname": "Puja",
        "gender": "female",
        "surname": "neyong",
        "father": "re3rudyfMy7PkrwnU1j3",
        "firstname_nepali": "पुजा",
        "order": 2,
        "id": "ANC2I9Jb7sou1d21sMqZ"
    },
    {
        "generation": 29,
        "firstname": "Jyoti",
        "gender": "female",
        "surname": "Nayong",
        "father": "OM9MJEJFRT2bKOAo3iUi",
        "firstname_nepali": "ज्योति",
        "order": 2,
        "id": "AnHAR5KNHZQqiZdpVQMN"
    },
    {
        "generation": 27,
        "firstname": "Chandrabir",
        "gender": "male",
        "surname": "neyong",
        "father": "Tc40zxg3u4FxyFEqBH1R",
        "firstname_nepali": "चन्द्रवीर",
        "order": 2,
        "id": "BNIMygjbAj4nQvDxUcfJ"
    },
    {
        "generation": 28,
        "firstname": "harkadhoj",
        "gender": "male",
        "surname": "neyong",
        "firstname_nepali": "हर्कधोज",
        "order": "2",
        "father": "jTIi0r5aQKbt6E2HCLjb",
        "id": "BfbZgRLMtIE0ayUsO7ax"
    },
    {
        "generation": 30,
        "firstname": "Aayosh",
        "gender": "male",
        "surname": "neyong",
        "father": "qcsZQblaYoGvkRwVHwaF",
        "firstname_nepali": "आयोस",
        "order": 2,
        "id": "BiSiAAR9nlIEvxmUftPM"
    },
    {
        "generation": 28,
        "firstname": "Ranamati",
        "gender": "female",
        "surname": "neyong",
        "father": "ElxKj4EA459ijYl9IW1K",
        "firstname_nepali": "रणमती",
        "order": 2,
        "id": "DQcTDXTF724j6rgORBT0"
    },
    {
        "generation": 30,
        "firstname": "Purnamaya",
        "gender": "female",
        "surname": "H.Chongbang",
        "father": "a56rJXA0e9D9degC1yWe",
        "firstname_nepali": "पूर्णमाया",
        "order": 2,
        "id": "Dh7Jh4jnHKPo3QQCvkPS"
    },
    {
        "generation": 30,
        "firstname": "Pawimaya",
        "gender": "female",
        "surname": "Maswa",
        "father": "",
        "spouse": "lvJOdENSgGMIGztLY2cU",
        "firstname_nepali": "पनिमाया",
        "order": 3,
        "id": "9HRXyU05Da3Fn2kEKIMi"
    },
    {
        "generation": 30,
        "firstname": "Sanjeev",
        "gender": "male",
        "surname": "neyong",
        "father": "WklMNwi1KB861pJ1JV8H",
        "firstname_nepali": "संजिव",
        "order": 3,
        "id": "AzQdO2HFC7o3LRmY4lq0"
    },
    {
        "generation": 30,
        "firstname": "Durga",
        "gender": "female",
        "surname": "neyong",
        "father": "ikiGkJgxhaFwSdYUeXQ1",
        "firstname_nepali": "दुर्गा",
        "order": 3,
        "id": "BIygFJkGj32azKHMsW70"
    },
    {
        "generation": 29,
        "firstname": "Fulmaya",
        "gender": "female",
        "surname": "neyong",
        "father": "txwvFLftgmHOR0RxlO9A",
        "firstname_nepali": "फुलमाया",
        "order": 3,
        "id": "Cec6AOhZeOLmJ5C9Mbvn"
    },
    {
        "generation": 30,
        "firstname": "Chakra Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "ZagHQ0QrPX2qEzX7GGNG",
        "firstname_nepali": "चक्रबहादुर",
        "order": 3,
        "id": "CvKcqfd9UKNrVzNytuVL"
    },
    {
        "generation": 30,
        "firstname": "Kumari",
        "gender": "female",
        "surname": "Abheng",
        "father": "OwV0mfHCf1GVYHiL7E4x",
        "firstname_nepali": "कुमारी",
        "order": 4,
        "id": "9XTeIBo7MrM9FYATAUX4"
    },
    {
        "generation": 29,
        "firstname": "Purna Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "kNtaIpShWQZl4KPsu6l3",
        "firstname_nepali": "पूर्णनहादुर",
        "order": 4,
        "id": "9bNY6EVA4CbzcXIIRNS9"
    },
    {
        "generation": 29,
        "firstname": "Amrit",
        "gender": "male",
        "surname": "neyong",
        "father": "n52s3uDsPp3A2XdaNaay",
        "firstname_nepali": "अमृत",
        "order": 4,
        "id": "ADtV5SYBkHcb9Bjc9JEb"
    },
    {
        "generation": 28,
        "firstname": "Mohan",
        "gender": "male",
        "surname": "neyong",
        "father": "u5mt0MbVXnMOpYT1sn9h",
        "firstname_nepali": "मोहन",
        "order": 4,
        "id": "BnSgPFBxSRfQMVJyfPsy"
    },
    {
        "generation": 28,
        "firstname": "Dumber Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "InqYU3gbNlXbuagAYZVM",
        "firstname_nepali": "डम्बर बहादुर",
        "order": 4,
        "id": "CW1DVD1thlNpirgChFcz"
    },
    {
        "generation": 29,
        "firstname": "Pradip",
        "gender": "male",
        "surname": "neyong",
        "father": "IWuqwgwdrs0s9uGJTP95",
        "firstname_nepali": "प्रदीप",
        "order": 5,
        "id": "DIpBQ9rhv9Wdxqy16Elw"
    },
    {
        "generation": 31,
        "firstname": "Kaushila",
        "gender": "female",
        "surname": "Maden",
        "father": "6hdWyig4xeRlNlykDwun",
        "firstname_nepali": "कौशिला",
        "order": 6,
        "id": "D2NsGOZ0L5Bpud7vcNmX"
    },
    {
        "generation": 29,
        "firstname": "Chanda",
        "gender": "female",
        "surname": "Rai",
        "father": "qGZDF5Wc1bgWkE0OC74L",
        "firstname_nepali": "चन्दा",
        "order": 7,
        "id": "AfCCdiNYsVgqkDM5EJfp"
    },
    {
        "generation": 28,
        "firstname": "unknown",
        "gender": "female",
        "surname": "Feyang Saba",
        "father": "",
        "spouse": "CuMwzHx4dHOkCKMfS3Kq",
        "firstname_nepali": "unknown",
        "id": "DqSS1u8LD4NXzeGyQKLJ"
    },
    {
        "generation": 28,
        "firstname": "Nara Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "Yyk6rkgn77Gp8JLUSx97",
        "firstname_nepali": "नरबहादुर",
        "order": 2,
        "id": "Dt6LJ1nYTuaOj6ZwSW5z"
    },
    {
        "generation": 7,
        "firstname": "mandevahang",
        "gender": "male",
        "surname": "neyong",
        "father": "FusTqUC20qHDR0UugPAJ",
        "firstname_nepali": "मान्देवाहाङ",
        "id": "DtFoxOvaOvH15NXcPHK7"
    },
    {
        "generation": 29,
        "firstname": "Balimaya",
        "gender": "female",
        "surname": "neyong",
        "father": "IWuqwgwdrs0s9uGJTP95",
        "firstname_nepali": "बलिमाया",
        "order": 1,
        "id": "EDROEsixFs0co3wHValS"
    },
    {
        "generation": 30,
        "firstname": "Filimaya",
        "gender": "female",
        "surname": "Chemjong",
        "father": "",
        "spouse": "Y2XKqKOd49y5XZT9iXDV",
        "firstname_nepali": "फिलिमाया",
        "order": 1,
        "id": "FWE1YxmKpoDOreSX9cdz"
    },
    {
        "generation": 30,
        "firstname": "Sanjukala",
        "gender": "female",
        "surname": "neyong",
        "father": "ImkXMy2dTkAhNihvm6zk",
        "firstname_nepali": "सन्जुकला",
        "order": 1,
        "id": "FjQJNSZGUl5BQJmcIb26"
    },
    {
        "generation": 30,
        "firstname": "Shukmaya",
        "gender": "female",
        "surname": "Rai",
        "father": "",
        "spouse": "re3rudyfMy7PkrwnU1j3",
        "firstname_nepali": "शुक्माया",
        "order": 1,
        "id": "FtiC0AtMQdKDf4qSxATI"
    },
    {
        "generation": 31,
        "firstname": "Tila",
        "gender": "female",
        "surname": "Chhetri",
        "father": "",
        "spouse": "4K3qHOoZR3daXcJByv27",
        "firstname_nepali": "तिला",
        "order": 1,
        "id": "FxaoQDIPGmj6Hgz6xrrn"
    },
    {
        "generation": 31,
        "firstname": "suman",
        "gender": "male",
        "surname": "neyong",
        "father": "HDq5cGMBYMBBk0wLZNZ8",
        "firstname_nepali": "सुमन",
        "order": 1,
        "id": "FxsfPZYPHZhQGwpuMbFo"
    },
    {
        "generation": 28,
        "firstname": "Shyammati",
        "gender": "female",
        "surname": "Abheng",
        "father": "",
        "spouse": "SGx7IrnzwUx2XvIhDxzG",
        "firstname_nepali": "श्यामति",
        "order": 1,
        "id": "G2pikK3RRJoES25kOMvR"
    },
    {
        "generation": 30,
        "firstname": "Indramaya ",
        "gender": "female",
        "surname": "Gurung",
        "father": "",
        "spouse": "ZZ6ATmUNwWYnngLRMoQ9",
        "firstname_nepali": "इन्द्रमाया",
        "order": 1,
        "id": "G8mQGTb0uWIdLyqtWIdL"
    },
    {
        "generation": 31,
        "firstname": "Binduwala",
        "gender": "female",
        "surname": "Rai",
        "father": "",
        "spouse": "qPh9ANBRU7jXYH8cBZOr",
        "firstname_nepali": "विन्दुवाला",
        "order": 1,
        "id": "G8no7npwdXkldMeQAm58"
    },
    {
        "generation": 29,
        "firstname": "Sarita",
        "gender": "female",
        "surname": "F.Chongbang",
        "father": "dQxa13Ho5vQJGuMiKV2D",
        "firstname_nepali": "सरिता",
        "order": 1,
        "id": "GLCMp9DpfrPXlUFrS2m4"
    },
    {
        "generation": 29,
        "firstname": "Shantikumari",
        "gender": "female",
        "surname": "Meyangbo",
        "father": "txwvFLftgmHOR0RxlO9A",
        "firstname_nepali": "शान्तिकुमारी",
        "order": 1,
        "id": "GTCXvP8M3551M4Ra27rw"
    },
    {
        "generation": 28,
        "firstname": "Bhaktimaya",
        "gender": "female",
        "surname": "Maswa",
        "father": "SGx7IrnzwUx2XvIhDxzG",
        "firstname_nepali": "भक्तिमाया",
        "order": 1,
        "id": "GTttxrqLaLFlhdblUjqR"
    },
    {
        "generation": 29,
        "firstname": "Bina",
        "gender": "female",
        "surname": "neyong",
        "father": "tHJfnfHlXZjOmPQjbfST",
        "firstname_nepali": "बिना",
        "order": 2,
        "id": "E1IgXySXQDVe8cRJJDCd"
    },
    {
        "generation": 30,
        "firstname": "Sushil",
        "gender": "male",
        "surname": "neyong",
        "father": "wXoi6mCx1t64iFs1E04j",
        "firstname_nepali": "सुशील",
        "order": 2,
        "id": "EB1C8UOoa0YeWC6SbBOu"
    },
    {
        "generation": 29,
        "firstname": "santabir",
        "gender": "male",
        "surname": "neyong",
        "father": "BfbZgRLMtIE0ayUsO7ax",
        "firstname_nepali": "सन्तवीर",
        "order": 2,
        "id": "EUV9t8FpK21GDWNL11Ic"
    },
    {
        "generation": 30,
        "firstname": "Unknown",
        "gender": "male",
        "surname": "neyong",
        "father": "qZq4Ynfi4NIXYGFOzvfy",
        "firstname_nepali": "Unknown",
        "order": 2,
        "id": "EpXT6tXG7v5gyX5Zlqxt"
    },
    {
        "generation": 29,
        "firstname": "run bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "BfbZgRLMtIE0ayUsO7ax",
        "firstname_nepali": "रनबहादुर",
        "order": 3,
        "id": "EPO3fxxAifva1MoAlwzr"
    },
    {
        "generation": 27,
        "firstname": "Deupati",
        "gender": "male",
        "surname": "neyong",
        "father": "OoNWKOdqDioDWMqkiCCT",
        "firstname_nepali": "देउपति",
        "order": 3,
        "id": "Fg2kKyyZb5vWv3118HpK"
    },
    {
        "generation": 25,
        "firstname": "Notabir",
        "gender": "male",
        "surname": "neyong",
        "father": "oEafKRqTRSz0eMgYR9jK",
        "firstname_nepali": "नोटबिर",
        "order": 3,
        "id": "G273ZuT2l3ZV4MxJBnyp"
    },
    {
        "generation": 28,
        "firstname": "Pabitra",
        "gender": "female",
        "surname": "Aangbuhang",
        "father": "WX97sNzmxAYBl5FKB3gT",
        "firstname_nepali": "पवित्रा",
        "order": 3,
        "id": "GjS1zC5LrUHDBPSM4Qhq"
    },
    {
        "generation": 31,
        "firstname": "Benjana",
        "gender": "female",
        "surname": "neyong",
        "father": "VFchMsM9H5j3ikGSkG5h",
        "firstname_nepali": "बेन्जना",
        "order": 6,
        "id": "DukZzjRJOm5f2I173p2D"
    },
    {
        "firstname": "jaharshi",
        "gender": "male",
        "surname": "neyong",
        "father": "woek7DK1CgxJ24U25aBM",
        "firstname_nepali": "जाहारशी",
        "generation": 3,
        "id": "EBMUVNbw9HHtKwEZZyPx"
    },
    {
        "generation": 30,
        "firstname": "Lagehang",
        "gender": "male",
        "surname": "neyong",
        "father": "t5MeCY6EJVRvW7u70xxz",
        "firstname_nepali": "लागेहाङ्",
        "order": 2,
        "id": "F8fz7y3GPB7T06yzRT9O"
    },
    {
        "generation": 30,
        "firstname": "Dhanmaya",
        "gender": "female",
        "surname": "Chongbang",
        "father": "",
        "spouse": "pLnkJiXFiQcdylhCcZcB",
        "firstname_nepali": "धनमय",
        "order": 2,
        "id": "FQ0MflIZVApb14siSbYM"
    },
    {
        "generation": 30,
        "firstname": "anandakumar",
        "gender": "male",
        "surname": "neyong",
        "father": "EUV9t8FpK21GDWNL11Ic",
        "firstname_nepali": "आनन्दकुमार",
        "order": 3,
        "id": "FbJN4FYj4mfzC4Jq1iEq"
    },
    {
        "generation": 28,
        "firstname": "Dhanmaya",
        "gender": "female",
        "surname": "H.Chongbang",
        "father": "u5mt0MbVXnMOpYT1sn9h",
        "firstname_nepali": "धनमाया",
        "order": 3,
        "id": "FoKgUUQvHf4cViipf9Wl"
    },
    {
        "generation": 28,
        "firstname": "Thakuri",
        "gender": "female",
        "surname": "Thutlung",
        "father": "Yyk6rkgn77Gp8JLUSx97",
        "firstname_nepali": "ठाकुरी",
        "order": 4,
        "id": "GYhd6ngyA3InPJSjlOw0"
    },
    {
        "generation": 27,
        "firstname": "Gajbahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "3bibwxYc96UIXF2UGCkt",
        "firstname_nepali": "गजबहादुर",
        "order": 5,
        "id": "GAEABfATbf0FabIDXRq9"
    },
    {
        "generation": 30,
        "firstname": "Kopila",
        "gender": "female",
        "surname": "neyong",
        "father": "WklMNwi1KB861pJ1JV8H",
        "firstname_nepali": "कोपिला",
        "order": 6,
        "id": "EpghwOs1gvy4j704y2i8"
    },
    {
        "generation": 30,
        "firstname": "Sumita",
        "gender": "female",
        "surname": "Liwang",
        "father": "ql3VFANib5uzCyGbKzGB",
        "firstname_nepali": "सुमिता",
        "order": 6,
        "id": "FepKx5hcaAAQYc7j1rQe"
    },
    {
        "generation": 6,
        "firstname": "bhimtong",
        "gender": "male",
        "surname": "neyong",
        "father": "r5088R2zbGzMSD7iN2a9",
        "firstname_nepali": "भिमतोङ्ग",
        "id": "FusTqUC20qHDR0UugPAJ"
    },
    {
        "generation": 29,
        "firstname": "Sher Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "72xTPRMzTjPPQQVDjSSB",
        "firstname_nepali": "शेरबहादुर",
        "order": 1,
        "id": "H1hPhhDlxQX6AvChBLt7"
    },
    {
        "generation": 30,
        "firstname": "Ellen",
        "gender": "male",
        "surname": "neyong",
        "father": "eMgtRHWQXym72VbqwpH6",
        "firstname_nepali": "एलेन",
        "order": 1,
        "id": "H4KMDruFOmRYXVG7iRKp"
    },
    {
        "generation": 30,
        "firstname": "Jasmaya",
        "gender": "female",
        "surname": "Lingthep",
        "father": "",
        "spouse": "lvJOdENSgGMIGztLY2cU",
        "firstname_nepali": "जसमाया",
        "order": 1,
        "id": "H4ONjx9JJCAGkVtwZR5u"
    },
    {
        "generation": 29,
        "firstname": "Jhalkumari",
        "gender": "female",
        "surname": "neyong",
        "father": "wqBPds94CS3LmfwG6uN9",
        "firstname_nepali": "झलकुमारी",
        "order": 1,
        "id": "H5HIa7syOiXbkJ02EMwP"
    },
    {
        "generation": 31,
        "firstname": "Anita",
        "gender": "female",
        "surname": "neyong",
        "father": "vPJscrn8uDcIxyqnFaZk",
        "firstname_nepali": "अनिता",
        "order": 1,
        "id": "HMaAD7qbpsZIkbgXHLTP"
    },
    {
        "generation": 28,
        "firstname": "Aaitarani",
        "gender": "female",
        "surname": "Chongbang Kyak",
        "father": "",
        "spouse": "ZotaxQ2AZ50SaSBP7UfA",
        "firstname_nepali": "आइतरानी",
        "order": 1,
        "id": "HQBdN4yWTKgieJzemy2M"
    },
    {
        "generation": 29,
        "firstname": "Mandrani",
        "gender": "female",
        "surname": "Okhrabu",
        "father": "",
        "spouse": "OM9MJEJFRT2bKOAo3iUi",
        "firstname_nepali": "मन्द्रनी",
        "order": 1,
        "id": "HnhP6DG8EHQBqTCBXcJb"
    },
    {
        "generation": 30,
        "firstname": "Shobha",
        "gender": "female",
        "surname": "Rai",
        "father": "",
        "spouse": "ySuEYexhhmyHiituMT18",
        "firstname_nepali": "शोभा",
        "order": 1,
        "id": "HzxDE7Mjb7jtazuDP3iJ"
    },
    {
        "generation": 30,
        "firstname": "Man Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "pLnkJiXFiQcdylhCcZcB",
        "firstname_nepali": "मानबहादुर",
        "order": 1,
        "id": "I2Ri8A10eCMMJWHWXkg2"
    },
    {
        "generation": 31,
        "firstname": "Natasha",
        "gender": "female",
        "surname": "neyong",
        "father": "ABwWIDQiWoRqXAHxoXbw",
        "firstname_nepali": "नातासा",
        "order": 1,
        "id": "ICZqSStQ8tG4ufSagcOK"
    },
    {
        "generation": 31,
        "firstname": "Bikram",
        "gender": "male",
        "surname": "neyong",
        "father": "myeaPpjzfdFflJDYBXqd",
        "firstname_nepali": "बिक्रम",
        "order": 1,
        "id": "IThw4TB777wfnYfdvqOS"
    },
    {
        "generation": 31,
        "firstname": "Saraswati",
        "gender": "female",
        "surname": "Kulung Rai",
        "father": "",
        "spouse": "44l5dzUKfMqmFiM6WPw1",
        "firstname_nepali": "सरस्वती",
        "order": 1,
        "id": "IXRcj0eCsyzUo2hlmiKw"
    },
    {
        "firstname": "chatrashi",
        "gender": "male",
        "surname": "neyong",
        "father": "uDjUwINr0vKxU8oUwvBr",
        "firstname_nepali": "छत्रशी",
        "order": 1,
        "generation": 26,
        "id": "IYCDjWRH1AC4Xnt2V5E0"
    },
    {
        "generation": 30,
        "firstname": "Dumber",
        "gender": "male",
        "surname": "neyong",
        "father": "OwV0mfHCf1GVYHiL7E4x",
        "firstname_nepali": "डम्बर",
        "order": 1,
        "id": "IdBmWceSURfNlN1qwGLV"
    },
    {
        "generation": 29,
        "firstname": "Mahiba",
        "gender": "male",
        "surname": "neyong",
        "father": "Oncg6ZoiciU8fyA610i9",
        "firstname_nepali": "महिबा",
        "order": 1,
        "id": "IkJG1NQEGQZM8h7VZeYh"
    },
    {
        "generation": 28,
        "firstname": "Indrajung(Laljetha)",
        "gender": "male",
        "surname": "neyong",
        "father": "YbfnmnrCBHCa4fd8FOHH",
        "firstname_nepali": "इन्द्रजङ (लालजेठा)",
        "order": 1,
        "id": "Imc5TtSjmhclodSnd4M1"
    },
    {
        "generation": 27,
        "firstname": "Rinjin",
        "gender": "female",
        "surname": "Sherpa",
        "father": "",
        "spouse": "1SvWh2eWbKIU9uRgAYdS",
        "firstname_nepali": "रिन्जिन",
        "order": 1,
        "id": "J3JskDKqYmAWM9d4ZxO3"
    },
    {
        "generation": 29,
        "firstname": "Jamaanshi",
        "gender": "male",
        "surname": "neyong",
        "father": "n9tJfeBxMuhTWbJGBIYt",
        "firstname_nepali": "जमानशी",
        "order": 1,
        "id": "J8NYTzwH2bOjqgN9DIri"
    },
    {
        "generation": 29,
        "firstname": "Durga",
        "gender": "female",
        "surname": "Pangthak",
        "father": "5alf3Nw8bulym1dR6cxr",
        "firstname_nepali": "दुर्गा",
        "order": 1,
        "id": "JFzBpg4ivAZ1V5pbpqRB"
    },
    {
        "generation": 30,
        "firstname": "Renuka",
        "gender": "female",
        "surname": "neyong",
        "father": "xYxZwzugiHlHI3Rc4yeK",
        "firstname_nepali": "रेणुका",
        "order": 1,
        "id": "JJK7Qdhuhz8dWD8l8GJK"
    },
    {
        "generation": 31,
        "firstname": "Dhanmaya",
        "gender": "female",
        "surname": "Wanem",
        "father": "",
        "spouse": "VFchMsM9H5j3ikGSkG5h",
        "firstname_nepali": "धनमाया",
        "order": 1,
        "id": "JRV7d9rvoatt6UJGMFSr"
    },
    {
        "generation": 29,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "",
        "spouse": "kf4un20w1ssu3R7xYu3Y",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "JYtudhdbvyOJ5GrpnziU"
    },
    {
        "generation": 30,
        "firstname": "Babita",
        "gender": "female",
        "surname": "Thangden",
        "father": "",
        "spouse": "XGfojZ421gtdVs7HspEj",
        "firstname_nepali": "बबिता",
        "order": 1,
        "id": "JfOQEyMNzj9qolEk1Tqq"
    },
    {
        "generation": 30,
        "firstname": "dinesh",
        "gender": "male",
        "surname": "neyong",
        "father": "zGv5Oqm4WMll6hXgZLv5",
        "firstname_nepali": "दिनेश",
        "order": 2,
        "id": "HDq5cGMBYMBBk0wLZNZ8"
    },
    {
        "generation": 29,
        "firstname": "Birbal",
        "gender": "male",
        "surname": "neyong",
        "father": "n52s3uDsPp3A2XdaNaay",
        "firstname_nepali": "बीरबल",
        "order": 2,
        "id": "IBwtFhGQZdSzQeIe9v3R"
    },
    {
        "generation": 29,
        "firstname": "Suja",
        "gender": "female",
        "surname": "Sawa",
        "father": "KNIGo4xoVGVuLnV5pMIj",
        "firstname_nepali": "सुजा",
        "order": 3,
        "id": "Gz8c280mt38q65NHHuvi"
    },
    {
        "generation": 29,
        "firstname": "Biren",
        "gender": "male",
        "surname": "neyong",
        "father": "IWuqwgwdrs0s9uGJTP95",
        "firstname_nepali": "बिरेन",
        "order": 4,
        "id": "Gv8Vod1jzQTbetCtbkIQ"
    },
    {
        "generation": 30,
        "firstname": "Punam",
        "gender": "female",
        "surname": "neyong",
        "father": "kXbMroxtUzS2HS1OUeWK",
        "firstname_nepali": "पुनम",
        "order": 4,
        "id": "GxVGXN0pGGSDOnBikvZN"
    },
    {
        "generation": 30,
        "firstname": "santakumar",
        "gender": "male",
        "surname": "neyong",
        "father": "zGv5Oqm4WMll6hXgZLv5",
        "firstname_nepali": "सन्तकुमार",
        "order": 4,
        "id": "HKMU42YHVmnayCliIW1k"
    },
    {
        "generation": 8,
        "firstname": "pipyung",
        "gender": "male",
        "surname": "neyong",
        "father": "m6BYlslNndD2N5fCBGKr",
        "firstname_nepali": "पिपयुंङ",
        "id": "HTqtcWLhJw3DbTWSjutU"
    },
    {
        "firstname": "namnam",
        "gender": "male",
        "surname": "neyong",
        "father": "EBMUVNbw9HHtKwEZZyPx",
        "firstname_nepali": "नमनम",
        "generation": 4,
        "id": "HW5K9vP9C72U9oXMAonW"
    },
    {
        "generation": 28,
        "firstname": "Sukmaya",
        "gender": "female",
        "surname": "neyong",
        "father": "ElxKj4EA459ijYl9IW1K",
        "firstname_nepali": "सुकमाया",
        "id": "Ha7koK19BZRo5JAhESCn"
    },
    {
        "generation": 30,
        "firstname": "Deumaya (Jethi)",
        "gender": "female",
        "surname": "Sreng",
        "father": "",
        "spouse": "WklMNwi1KB861pJ1JV8H",
        "firstname_nepali": "देउमाय ( जेठी)",
        "id": "HoStJ2DzBxblYJTriCOC"
    },
    {
        "generation": 29,
        "firstname": "Chandrakala(Srijana)",
        "gender": "female",
        "surname": "Rai/Wanem",
        "father": "",
        "spouse": "7owTCC5XAFs3GtNO7DKV",
        "firstname_nepali": "चन्द्रकला (सृजना)",
        "order": 1,
        "id": "JNmLGLibEccsS67p8Jys"
    },
    {
        "generation": 29,
        "firstname": "Ningma",
        "gender": "female",
        "surname": "Sherpa",
        "father": "",
        "spouse": "n52s3uDsPp3A2XdaNaay",
        "firstname_nepali": "निङ्मा",
        "order": 1,
        "id": "JgSOWD4FxRpMeRT7G74z"
    },
    {
        "generation": 30,
        "firstname": "Sesehang",
        "gender": "male",
        "surname": "neyong",
        "father": "OpNdM7ps81MYFK1wT3yC",
        "firstname_nepali": "सेसेहाङ",
        "order": 1,
        "id": "JlGRDW9qXh8MkVt3TdJQ"
    },
    {
        "generation": 30,
        "firstname": "Devi Maya",
        "gender": "female",
        "surname": "Pangthak",
        "father": "",
        "spouse": "Yht84zfwSdRGQIKzTNh4",
        "firstname_nepali": "देवीमाया",
        "order": 1,
        "id": "JrSiclgxw0xazlTmxncZ"
    },
    {
        "generation": 30,
        "firstname": "Manu",
        "gender": "female",
        "surname": "Thebe",
        "father": "",
        "spouse": "OpNdM7ps81MYFK1wT3yC",
        "firstname_nepali": "मनु",
        "order": 1,
        "id": "Jt59D8HEgyLoiuqe2i9v"
    },
    {
        "generation": 31,
        "firstname": "Brian",
        "gender": "male",
        "surname": "neyong",
        "father": "qPh9ANBRU7jXYH8cBZOr",
        "firstname_nepali": "ब्राइन",
        "order": 1,
        "id": "JwndoFUhwXc5bZYIf8jy"
    },
    {
        "generation": 29,
        "firstname": "Tirthaa",
        "gender": "female",
        "surname": "neyong",
        "father": "BnSgPFBxSRfQMVJyfPsy",
        "firstname_nepali": "तीर्था",
        "order": 1,
        "id": "K2WbsIhYbba7E4tA8X0i"
    },
    {
        "generation": 28,
        "firstname": "Indraman",
        "gender": "male",
        "surname": "neyong",
        "father": "dv7viJovtWgjXcID9hqt",
        "firstname_nepali": "इन्द्रमान",
        "order": 1,
        "id": "KNIGo4xoVGVuLnV5pMIj"
    },
    {
        "generation": 28,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "",
        "spouse": "efEWP3DLaCN0MxeRZt2M",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "KrzEl0bIVQ9Pml3nS6jC"
    },
    {
        "generation": 29,
        "firstname": "Umesh",
        "gender": "male",
        "surname": "neyong",
        "father": "pFAAWd1xpo44DHuts3G2",
        "firstname_nepali": "उमेश",
        "order": 1,
        "id": "KwKz6WVtB1OM3JIoEDf9"
    },
    {
        "generation": 30,
        "firstname": "Unknown",
        "gender": "male",
        "surname": "neyong",
        "father": "gEFDJ1HrpPxoVZbXqjjS",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "LAG77HJXCoJoUEAKv8iN"
    },
    {
        "generation": 27,
        "firstname": "Santabir",
        "gender": "male",
        "surname": "neyong",
        "father": "PS4ie7L4LmJ8cP1qiqZM",
        "firstname_nepali": "सन्ताबीर",
        "order": 1,
        "id": "LXwLyNatGKv4iOisO1yF"
    },
    {
        "generation": 30,
        "firstname": "Kabita",
        "gender": "female",
        "surname": "Aangbuhang",
        "father": "kXbMroxtUzS2HS1OUeWK",
        "firstname_nepali": "कविता",
        "order": 1,
        "id": "LhUp1ZNQ0dto97tWZLsp"
    },
    {
        "generation": 30,
        "firstname": "Hangpari",
        "gender": "female",
        "surname": "Hangnam",
        "father": "",
        "spouse": "pLnkJiXFiQcdylhCcZcB",
        "firstname_nepali": "ह्याङ्गपरी",
        "order": 1,
        "id": "M3ek6MYNvQ3GlQLieaw8"
    },
    {
        "generation": 31,
        "firstname": "Laxmi",
        "gender": "female",
        "surname": "Tamang",
        "father": "",
        "spouse": "Jt2YhXThoq9J6ndMYM2B",
        "firstname_nepali": "लक्ष्मी",
        "order": 1,
        "id": "N2sQFR6yJO4o85XsGMrQ"
    },
    {
        "generation": 28,
        "firstname": "Birkha bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "1GepDR9JBG91M6qmNVTG",
        "firstname_nepali": "बिरखबहादुर",
        "order": 1,
        "id": "N9O37TuJcTeet1Ku981Y"
    },
    {
        "generation": 26,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "",
        "spouse": "UXgo4mpm0X4z5PtPciRX",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "NH1HPGF83MEF62sPB3ZT"
    },
    {
        "generation": 30,
        "firstname": "Jiwan",
        "gender": "male",
        "surname": "neyong",
        "father": "WklMNwi1KB861pJ1JV8H",
        "firstname_nepali": "जीवन",
        "order": 1,
        "id": "NRdSlQWYrF3Y2poyK4pn"
    },
    {
        "generation": 31,
        "firstname": "Nandiram",
        "gender": "male",
        "surname": "neyong",
        "father": "6hdWyig4xeRlNlykDwun",
        "firstname_nepali": "नन्दीराम",
        "order": 1,
        "id": "NS5UjGypAI85KX2lse93"
    },
    {
        "generation": 31,
        "firstname": "Durganarayan",
        "gender": "male",
        "surname": "neyong",
        "father": "I2Ri8A10eCMMJWHWXkg2",
        "firstname_nepali": "दुर्गानारायण",
        "order": 1,
        "id": "NV3uzIhNS2AHmxyNUJR3"
    },
    {
        "generation": 31,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "Padma thopra",
        "father": "",
        "spouse": "MaO7WuuLxsy37ha8ogDS",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "NqFf7uYdp3eugvP2Sj5l"
    },
    {
        "generation": 28,
        "firstname": "Umershi",
        "gender": "male",
        "surname": "neyong",
        "father": "dv7viJovtWgjXcID9hqt",
        "firstname_nepali": "उमरशी",
        "order": 2,
        "id": "IWuqwgwdrs0s9uGJTP95"
    },
    {
        "generation": 29,
        "firstname": "Chandra Prasad",
        "gender": "male",
        "surname": "neyong",
        "father": "wkAS2er2VnhS4hpSgltq",
        "firstname_nepali": "चन्द्रप्रसाद",
        "order": 2,
        "id": "ImkXMy2dTkAhNihvm6zk"
    },
    {
        "generation": 29,
        "firstname": "Moti Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "kf4un20w1ssu3R7xYu3Y",
        "firstname_nepali": "मोतीबहादुर",
        "order": 2,
        "id": "JQhJS0JoOub4wEDU26Yw"
    },
    {
        "generation": 30,
        "firstname": "Sagar",
        "gender": "female",
        "surname": "neyong",
        "father": "R2HEcMIqsvQa7kON8pm1",
        "firstname_nepali": "सागर",
        "order": 2,
        "id": "JpJJvd76j5HVvSNcpjkp"
    },
    {
        "generation": 28,
        "firstname": "Devkimaya",
        "gender": "female",
        "surname": "Thengden",
        "father": "SGx7IrnzwUx2XvIhDxzG",
        "firstname_nepali": "देवकिमाया",
        "order": 2,
        "id": "KFt3DdlKbqhj4qLGV45Z"
    },
    {
        "generation": 31,
        "firstname": "Pari",
        "gender": "female",
        "surname": "neyong",
        "father": "5x8aVF3lmWNp0yGiXeWV",
        "firstname_nepali": "परी",
        "order": 2,
        "id": "KnarsW31kvSlwkqUHiAM"
    },
    {
        "generation": 30,
        "firstname": "Premkumari",
        "gender": "female",
        "surname": "neyong",
        "father": "pLnkJiXFiQcdylhCcZcB",
        "firstname_nepali": "प्रेमकुमारी",
        "order": 2,
        "id": "LDdhYaaqsHu7FpBMg7zJ"
    },
    {
        "generation": 29,
        "firstname": "Sunil",
        "gender": "male",
        "surname": "neyong",
        "father": "J2ac0kCcTxVQjo4TbSqr",
        "firstname_nepali": "सुनिल",
        "order": 2,
        "id": "LJMbisWWdSJkl5ix5EDr"
    },
    {
        "generation": 27,
        "firstname": "Shuk bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "2ACsb6YKCCyXefmfgN0q",
        "firstname_nepali": "शुकबहादुर",
        "order": 2,
        "id": "LKOWCPUeN6e4PHwl2L3O"
    },
    {
        "generation": 26,
        "firstname": "Dhanhaang",
        "gender": "male",
        "surname": "neyong",
        "father": "adZmBL6zCCbLDwbTrg1Z",
        "firstname_nepali": "धनहाङ",
        "order": 2,
        "id": "LfSWWYnJz14nXeCU3EwM"
    },
    {
        "generation": 28,
        "firstname": "Narmaya",
        "gender": "female",
        "surname": "Shreng",
        "father": "u5mt0MbVXnMOpYT1sn9h",
        "firstname_nepali": "नरमाया",
        "order": 2,
        "id": "LnFpB4yU0awYbaBMlgxn"
    },
    {
        "generation": 30,
        "firstname": "Bittu",
        "gender": "male",
        "surname": "neyong",
        "father": "kXbMroxtUzS2HS1OUeWK",
        "firstname_nepali": "बिटु",
        "order": 2,
        "id": "M9zZvvSVkIekXwy4BgJG"
    },
    {
        "generation": 30,
        "firstname": "Jus Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "Yht84zfwSdRGQIKzTNh4",
        "firstname_nepali": "जसबहादुर",
        "order": 2,
        "id": "MaO7WuuLxsy37ha8ogDS"
    },
    {
        "generation": 29,
        "firstname": "Bishnumati",
        "gender": "female",
        "surname": "Wanem",
        "father": "eXOIzWaaOWilxGaoNY1q",
        "firstname_nepali": "विष्णुमती",
        "order": 2,
        "id": "NEwjfUBbaJqQl0DMTdIo"
    },
    {
        "generation": 30,
        "firstname": "Binod",
        "gender": "male",
        "surname": "neyong",
        "father": "JQhJS0JoOub4wEDU26Yw",
        "firstname_nepali": "बिनोद",
        "order": 2,
        "id": "NS9OiMxHMOx6i8nrp3kz"
    },
    {
        "generation": 25,
        "firstname": "Jawaray",
        "gender": "male",
        "surname": "neyong",
        "father": "yQtDCK1tH6s4kX53TfY2",
        "firstname_nepali": "जवरे",
        "order": 3,
        "id": "I0aD2nQo33kxBkt08tjj"
    },
    {
        "generation": 29,
        "firstname": "Meghraaj",
        "gender": "male",
        "surname": "neyong",
        "father": "pFAAWd1xpo44DHuts3G2",
        "firstname_nepali": "मेघराज",
        "order": 3,
        "id": "Iys8ETkwJrotN0yqa7Pe"
    },
    {
        "generation": 31,
        "firstname": "mina",
        "gender": "female",
        "surname": "neyong",
        "father": "YBL7z5EuHgONVjBj4m1O",
        "firstname_nepali": "मिना",
        "order": 3,
        "id": "KHeaIef2fCEA6wTTQhiH"
    },
    {
        "generation": 30,
        "firstname": "Durga",
        "gender": "female",
        "surname": "neyong",
        "father": "kXbMroxtUzS2HS1OUeWK",
        "firstname_nepali": "अन्जु",
        "order": 3,
        "id": "L16u4JTuWxNeWG3W9xxP"
    },
    {
        "generation": 30,
        "firstname": "Anju",
        "gender": "female",
        "surname": "neyong",
        "father": "t5MeCY6EJVRvW7u70xxz",
        "firstname_nepali": "अन्जु",
        "order": 3,
        "id": "LyvLRvZzdSur9mTT8m2q"
    },
    {
        "generation": 28,
        "firstname": "Purnamaya",
        "gender": "female",
        "surname": "Abheng",
        "father": "dv7viJovtWgjXcID9hqt",
        "firstname_nepali": "पूर्णमाया",
        "order": 3,
        "id": "M5Jj06r7slaVSkEqOWnE"
    },
    {
        "generation": 30,
        "firstname": "Manita",
        "gender": "female",
        "surname": "neyong",
        "father": "XGfojZ421gtdVs7HspEj",
        "firstname_nepali": "मनिता",
        "order": 3,
        "id": "MaodisrtIWlSsdGyEIDH"
    },
    {
        "generation": 25,
        "firstname": "saahaardhoj",
        "gender": "male",
        "surname": "neyong",
        "father": "7OBd3Izm7KcYOBJABNRS",
        "firstname_nepali": "साहारधोज",
        "order": 3,
        "id": "Mi9dyKxhyvikZ7kKKa7F"
    },
    {
        "generation": 30,
        "firstname": "Pyari",
        "gender": "female",
        "surname": "Gurung",
        "father": "WklMNwi1KB861pJ1JV8H",
        "firstname_nepali": "प्यारी",
        "order": 4,
        "id": "I3TafWJgC912vVAdRDLZ"
    },
    {
        "generation": 16,
        "firstname": "chamyangla",
        "gender": "male",
        "surname": "neyong",
        "father": "3IWRqWgEVwrcz3noFReA",
        "firstname_nepali": "चम्याङला",
        "id": "I8wFoAUdSYu4Nd0hLezu"
    },
    {
        "generation": 9,
        "firstname": "maafyung",
        "gender": "male",
        "surname": "neyong",
        "father": "HTqtcWLhJw3DbTWSjutU",
        "firstname_nepali": "माफ्युंङ",
        "id": "IJw5MAlspH97Qm8ClSzi"
    },
    {
        "generation": 27,
        "firstname": "Jaswanta",
        "gender": "male",
        "surname": "neyong",
        "father": "8ALJvsu4rCbrreGPywFx",
        "firstname_nepali": "जसवन्त",
        "order": 3,
        "id": "InqYU3gbNlXbuagAYZVM"
    },
    {
        "generation": 28,
        "firstname": "Dil Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "SGx7IrnzwUx2XvIhDxzG",
        "firstname_nepali": "दिलबहादुर",
        "order": 4,
        "id": "J2ac0kCcTxVQjo4TbSqr"
    },
    {
        "generation": 31,
        "firstname": "Fulmaya",
        "gender": "female",
        "surname": "",
        "father": "",
        "spouse": "Z23KepFq1svmYR7FmGz5",
        "firstname_nepali": "फुलमाया",
        "id": "JYlEdlQRb50KNcPhtepX"
    },
    {
        "generation": 30,
        "firstname": "Anjali",
        "gender": "female",
        "surname": "neyong",
        "father": "re3rudyfMy7PkrwnU1j3",
        "firstname_nepali": "अञ्जली",
        "order": 4,
        "id": "JcYOkrWuesvhomAjZ4VS"
    },
    {
        "generation": 29,
        "firstname": "Ananda Prasad",
        "gender": "male",
        "surname": "neyong",
        "father": "sSRNStHwtiSf582KrlVl",
        "firstname_nepali": "आनन्दप्रसाद",
        "order": 4,
        "id": "JmQAJQarEcVQ8v9jXrF9"
    },
    {
        "generation": 30,
        "firstname": "Yeskumar",
        "gender": "male",
        "surname": "neyong",
        "father": "ikiGkJgxhaFwSdYUeXQ1",
        "firstname_nepali": "यशकुमार",
        "order": 4,
        "id": "Jt2YhXThoq9J6ndMYM2B"
    },
    {
        "generation": 29,
        "firstname": "Sabin",
        "gender": "male",
        "surname": "neyong",
        "father": "5alf3Nw8bulym1dR6cxr",
        "firstname_nepali": "सबिन",
        "order": 4,
        "id": "N6CdLHdniDJUuWQooOnH"
    },
    {
        "generation": 29,
        "firstname": "Yamkumari",
        "gender": "female",
        "surname": "Rai",
        "father": "IWuqwgwdrs0s9uGJTP95",
        "firstname_nepali": "यमकुमारी",
        "order": 5,
        "id": "JZu41mOkMMjeHm6xyqJw"
    },
    {
        "generation": 30,
        "firstname": "Binita",
        "gender": "female",
        "surname": "neyong",
        "father": "t5MeCY6EJVRvW7u70xxz",
        "firstname_nepali": "बिनिता",
        "order": 5,
        "id": "M8E7vjwdNawCJq2etS1A"
    },
    {
        "generation": 30,
        "firstname": "Purnakumari",
        "gender": "female",
        "surname": "Kandawa",
        "father": "lvJOdENSgGMIGztLY2cU",
        "firstname_nepali": "पूर्णकुमारी",
        "order": 6,
        "id": "MOBFNi5uLJlV9Vq1I8la"
    },
    {
        "generation": 30,
        "firstname": "Gunkumari",
        "gender": "female",
        "surname": "Pangma",
        "father": "lvJOdENSgGMIGztLY2cU",
        "firstname_nepali": "गुणकुमारी",
        "order": 7,
        "id": "KGEFm14uxEoNCNWyPhe8"
    },
    {
        "generation": 26,
        "firstname": "unknown",
        "gender": "female",
        "surname": "Chereng Chongbang",
        "father": "",
        "spouse": "Mi9dyKxhyvikZ7kKKa7F",
        "firstname_nepali": "unknown",
        "id": "Ng3IYYtt9SjVwYr10Bih"
    },
    {
        "generation": 29,
        "firstname": "BuddhaMaya",
        "gender": "female",
        "surname": "Singyaba",
        "father": "",
        "spouse": "wkAS2er2VnhS4hpSgltq",
        "firstname_nepali": "बुधमाया",
        "id": "NjRRsbMQSgEnj9BxLn72"
    },
    {
        "generation": 30,
        "firstname": "Kumari (Kanchi)",
        "gender": "female",
        "surname": "Rai",
        "father": "",
        "spouse": "WklMNwi1KB861pJ1JV8H",
        "firstname_nepali": "कुमारी ( कान्छी )",
        "id": "NpjnclbrVkGFMWlW0YcU"
    },
    {
        "generation": 25,
        "firstname": "Tatabaa",
        "gender": "male",
        "surname": "neyong",
        "father": "jah7fN311IS4msHcF6Tk",
        "firstname_nepali": "ततबा",
        "order": 1,
        "id": "Nz20cbg29J8IF1AEQi0a"
    },
    {
        "generation": 25,
        "firstname": "Bhagihang",
        "gender": "male",
        "surname": "neyong",
        "father": "yQtDCK1tH6s4kX53TfY2",
        "firstname_nepali": "भागीहाङ",
        "order": 1,
        "id": "O60Ygvjy8PwSkcwHQCHc"
    },
    {
        "generation": 30,
        "firstname": "Bishnukhajum",
        "gender": "female",
        "surname": "neyong",
        "father": "",
        "spouse": "PBfA6yIfHlRwzTqzXRCa",
        "firstname_nepali": "विष्णुखजुम",
        "order": 1,
        "id": "O9j18v2RZJsQzyXn1hOr"
    },
    {
        "generation": 30,
        "firstname": "Chandrashi",
        "gender": "male",
        "surname": "neyong",
        "father": "Yht84zfwSdRGQIKzTNh4",
        "firstname_nepali": "चन्द्रसिं",
        "order": 1,
        "id": "OEo707EqYXnJUtdqeh2v"
    },
    {
        "generation": 29,
        "firstname": "Subharani",
        "gender": "female",
        "surname": "Thopra",
        "father": "",
        "spouse": "eXOIzWaaOWilxGaoNY1q",
        "firstname_nepali": "शुभरानी",
        "order": 1,
        "id": "OF7o3AIw0h2EmIyPLdUt"
    },
    {
        "generation": 29,
        "firstname": "Shanta",
        "gender": "female",
        "surname": "Yakso",
        "father": "",
        "spouse": "lsyFjL97gAHZ9uRlB1L2",
        "firstname_nepali": "शान्ता",
        "order": 1,
        "id": "OKrm7nB36sU186SH1Guf"
    },
    {
        "generation": 28,
        "firstname": "Padam Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "SGx7IrnzwUx2XvIhDxzG",
        "firstname_nepali": "पदमबहादुर",
        "order": 1,
        "id": "OM9MJEJFRT2bKOAo3iUi"
    },
    {
        "generation": 27,
        "firstname": "Harkamaya",
        "gender": "female",
        "surname": "neyong",
        "father": "1SvWh2eWbKIU9uRgAYdS",
        "firstname_nepali": "हर्कमाया",
        "order": 1,
        "id": "OV4N2IPbqdc4fcCpPbI8"
    },
    {
        "generation": 26,
        "firstname": "Ganjashi",
        "gender": "male",
        "surname": "neyong",
        "father": "1UXPIm8YAP88TZ9jxl3f",
        "firstname_nepali": "गन्जशी",
        "order": 1,
        "id": "OoNWKOdqDioDWMqkiCCT"
    },
    {
        "generation": 29,
        "firstname": "Ojahanng",
        "gender": "male",
        "surname": "neyong",
        "father": "KNIGo4xoVGVuLnV5pMIj",
        "firstname_nepali": "ओझाङ",
        "order": 1,
        "id": "OpNdM7ps81MYFK1wT3yC"
    },
    {
        "generation": 29,
        "firstname": "Jit Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "kNtaIpShWQZl4KPsu6l3",
        "firstname_nepali": "जीतबहादुर",
        "order": 1,
        "id": "OwV0mfHCf1GVYHiL7E4x"
    },
    {
        "generation": 30,
        "firstname": "ashok prakash",
        "gender": "male",
        "surname": "neyong",
        "firstname_nepali": "अशोकप्रकाश",
        "order": 1,
        "father": "EUV9t8FpK21GDWNL11Ic",
        "id": "Ows4I1rXmr71R5u8qFzX"
    },
    {
        "generation": 28,
        "firstname": "ladhunath",
        "gender": "male",
        "surname": "neyong",
        "father": "jTIi0r5aQKbt6E2HCLjb",
        "order": "1",
        "firstname_nepali": "लधुनाथ",
        "id": "P4Ad5J4jIDcrNpV1oIHJ"
    },
    {
        "generation": 29,
        "firstname": "Krishna Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "KNIGo4xoVGVuLnV5pMIj",
        "firstname_nepali": "कृष्णबहादुर",
        "order": 1,
        "id": "PBfA6yIfHlRwzTqzXRCa"
    },
    {
        "generation": 28,
        "firstname": "Mati Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "InqYU3gbNlXbuagAYZVM",
        "firstname_nepali": "मतिबहादुर",
        "order": 1,
        "id": "PJ5NiF5Q9kKYhbUQMmwd"
    },
    {
        "generation": 26,
        "firstname": "Nardhoj",
        "gender": "male",
        "surname": "neyong",
        "father": "G273ZuT2l3ZV4MxJBnyp",
        "firstname_nepali": "नरधोज",
        "order": 1,
        "id": "PS4ie7L4LmJ8cP1qiqZM"
    },
    {
        "generation": 26,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "",
        "spouse": "1UXPIm8YAP88TZ9jxl3f",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "PYtzEequrj9Bj7729dZ2"
    },
    {
        "generation": 23,
        "firstname": "fayaak",
        "gender": "male",
        "surname": "neyong",
        "father": "o7YMAqEasqeSQ0fNZGpp",
        "firstname_nepali": "फयाक",
        "order": 1,
        "id": "PoTRFDxUu9kJRxkv5ok7"
    },
    {
        "generation": 30,
        "firstname": "Sutraraaj",
        "gender": "male",
        "surname": "neyong",
        "father": "9bNY6EVA4CbzcXIIRNS9",
        "firstname_nepali": "सूत्रराज",
        "order": 1,
        "id": "PyAXyeBwyxt1k0ZlkUe8"
    },
    {
        "generation": 28,
        "firstname": "Harkamati",
        "gender": "female",
        "surname": "Okhrabu",
        "father": "",
        "spouse": "dv7viJovtWgjXcID9hqt",
        "firstname_nepali": "हर्कमती",
        "order": 1,
        "id": "Q4hm2QrIG10QJMuqyZS0"
    },
    {
        "generation": 26,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "Unknown",
        "father": "",
        "spouse": "zcHM10yqmUAAo2MiHU0j",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "Q7PH6LPnseVeUXlMmPAB"
    },
    {
        "generation": 31,
        "firstname": "Bhimkumar",
        "gender": "male",
        "surname": "neyong",
        "father": "VFchMsM9H5j3ikGSkG5h",
        "firstname_nepali": "भीमकुमार",
        "order": 1,
        "id": "QAfsfOC2FHOOce7xmu8L"
    },
    {
        "generation": 28,
        "firstname": "Dikkumari",
        "gender": "female",
        "surname": "F.Chongbang",
        "father": "",
        "spouse": "u5mt0MbVXnMOpYT1sn9h",
        "firstname_nepali": "डिककुमारी",
        "order": 1,
        "id": "QEWpAXpsNIhfjvG97NeG"
    },
    {
        "generation": 30,
        "firstname": "Mankumari",
        "gender": "female",
        "surname": "Liwang",
        "father": "",
        "spouse": "11U7zezKhLd8xGqhBiOl",
        "firstname_nepali": "मानकुमारी",
        "order": 1,
        "id": "QLJg2chh0mcwSAfnUbU4"
    },
    {
        "generation": 27,
        "firstname": "Abheng",
        "gender": "female",
        "surname": "Thopra",
        "father": "",
        "spouse": "LfSWWYnJz14nXeCU3EwM",
        "firstname_nepali": "अभेङ",
        "order": 1,
        "id": "QPA2bb2hWrLuaM1NAhWO"
    },
    {
        "generation": 29,
        "firstname": "Gauriprasad",
        "gender": "male",
        "surname": "neyong",
        "father": "OM9MJEJFRT2bKOAo3iUi",
        "firstname_nepali": "गौरीप्रसाद",
        "order": 1,
        "id": "QRWYRJQVys6Kmf3z3o5n"
    },
    {
        "generation": 31,
        "firstname": "Unknown",
        "gender": "male",
        "surname": "neyong",
        "father": "OEo707EqYXnJUtdqeh2v",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "QWkPNPRkHGabfaS6cf9z"
    },
    {
        "generation": 29,
        "firstname": "Bidhya",
        "gender": "female",
        "surname": "neyong",
        "father": "lsyFjL97gAHZ9uRlB1L2",
        "firstname_nepali": "विद्या",
        "order": 1,
        "id": "QcqgdQhbkubiNSByRgUq"
    },
    {
        "generation": 30,
        "firstname": "Sabina",
        "gender": "female",
        "surname": "Gurung",
        "father": "",
        "spouse": "DIpBQ9rhv9Wdxqy16Elw",
        "firstname_nepali": "सबिना",
        "order": 1,
        "id": "QlbeX7ZZvUXX7nTvwOHW"
    },
    {
        "generation": 29,
        "firstname": "Dumber bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "eXOIzWaaOWilxGaoNY1q",
        "firstname_nepali": "डम्बरबहादुर",
        "order": 1,
        "id": "R2HEcMIqsvQa7kON8pm1"
    },
    {
        "generation": 30,
        "firstname": "Hemkumari",
        "gender": "female",
        "surname": "Rai",
        "father": "",
        "spouse": "JmQAJQarEcVQ8v9jXrF9",
        "firstname_nepali": "हेमकुमारी",
        "order": 1,
        "id": "RLUOH6kp0o6IkLr35l9K"
    },
    {
        "generation": 30,
        "firstname": "Sandip",
        "gender": "male",
        "surname": "neyong",
        "father": "11U7zezKhLd8xGqhBiOl",
        "firstname_nepali": "सन्दिप",
        "order": 1,
        "id": "RVHlp6NRmpxzQaIS8O6m"
    },
    {
        "generation": 29,
        "firstname": "Sonraaj",
        "gender": "male",
        "surname": "neyong",
        "father": "txwvFLftgmHOR0RxlO9A",
        "firstname_nepali": "सोनराज",
        "order": 2,
        "id": "OCe3eC0PP5hp7Lmmv4q6"
    },
    {
        "generation": 28,
        "firstname": "Maan Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "efEWP3DLaCN0MxeRZt2M",
        "firstname_nepali": "मानबहादुर",
        "order": 2,
        "id": "OGxM3XZ1TnNRrbtgVOSD"
    },
    {
        "generation": 30,
        "firstname": "om prakash",
        "gender": "male",
        "surname": "neyong",
        "father": "EUV9t8FpK21GDWNL11Ic",
        "firstname_nepali": "ओमप्रकाश",
        "order": 2,
        "id": "OomtiIY49ZNttNR7JjVt"
    },
    {
        "generation": 29,
        "firstname": "Sanchamaya",
        "gender": "female",
        "surname": "Samsuhang",
        "father": "txwvFLftgmHOR0RxlO9A",
        "firstname_nepali": "संचमाया",
        "order": 2,
        "id": "PHqtdO0ghOWA1vICmefX"
    },
    {
        "generation": 29,
        "firstname": "Buddhamaya",
        "gender": "female",
        "surname": "Okhrabu",
        "father": "",
        "spouse": "eXOIzWaaOWilxGaoNY1q",
        "firstname_nepali": "बुद्धमाया",
        "order": 2,
        "id": "PHuq8MplnCTltRWerlRy"
    },
    {
        "generation": 30,
        "firstname": "Nirmala",
        "gender": "female",
        "surname": "neyong",
        "father": "JQhJS0JoOub4wEDU26Yw",
        "firstname_nepali": "निर्मला",
        "order": 2,
        "id": "PbhCPQ9KQVIyYqhZ4nUM"
    },
    {
        "generation": 32,
        "firstname": "Sesehang",
        "gender": "male",
        "surname": "neyong",
        "father": "QAfsfOC2FHOOce7xmu8L",
        "firstname_nepali": "सेसेहाङ",
        "order": 2,
        "id": "PdOCrMatX661mTH1aZKq"
    },
    {
        "generation": 26,
        "firstname": "Batase",
        "gender": "male",
        "surname": "neyong",
        "father": "UXgo4mpm0X4z5PtPciRX",
        "firstname_nepali": "बतासे",
        "order": 2,
        "id": "Q38E4d64LceyTcnqdJAF"
    },
    {
        "generation": 31,
        "firstname": "Bir Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "6hdWyig4xeRlNlykDwun",
        "firstname_nepali": "वीरबहादुर",
        "order": 3,
        "id": "OKopBHTikBJwAXdbAgrn"
    },
    {
        "generation": 30,
        "firstname": "Tum Prakash",
        "gender": "male",
        "surname": "neyong",
        "father": "ql3VFANib5uzCyGbKzGB",
        "firstname_nepali": "टमप्रकास",
        "order": 3,
        "id": "Q2nWgOPjLsVzQzFPzzUW"
    },
    {
        "generation": 28,
        "firstname": "Bhaarat",
        "gender": "male",
        "surname": "neyong",
        "father": "u5mt0MbVXnMOpYT1sn9h",
        "firstname_nepali": "भारत",
        "order": 5,
        "id": "Oncg6ZoiciU8fyA610i9"
    },
    {
        "generation": 30,
        "firstname": "Amrit",
        "gender": "female",
        "surname": "Rai",
        "father": "WklMNwi1KB861pJ1JV8H",
        "firstname_nepali": "अमृत",
        "order": 5,
        "id": "PEqicCnK1TmxxO467I30"
    },
    {
        "generation": 11,
        "firstname": "besari",
        "gender": "male",
        "surname": "neyong",
        "father": "rh6vJyZ6GbEIVtsjRyxN",
        "firstname_nepali": "बेसरी",
        "id": "PHuuwvJDvJYAXq8DLbYE"
    },
    {
        "generation": 29,
        "firstname": "Prabesh",
        "gender": "male",
        "surname": "neyong",
        "father": "OM9MJEJFRT2bKOAo3iUi",
        "firstname_nepali": "प्रवेश",
        "order": 5,
        "id": "QhIzOEe4OvjPZFvPgV1t"
    },
    {
        "generation": 14,
        "firstname": "sampathang",
        "gender": "male",
        "surname": "neyong",
        "father": "73lMqvlywWogOLIpmWoB",
        "firstname_nepali": "साम्पथांङ",
        "id": "QlQuLcv7cRn31nMMMIiU"
    },
    {
        "generation": 27,
        "firstname": "Manuma",
        "gender": "female",
        "surname": "Mangyung",
        "father": "",
        "spouse": "PS4ie7L4LmJ8cP1qiqZM",
        "firstname_nepali": "मनुमा",
        "order": 1,
        "id": "RVMdEoJE9rN4Wcus253J"
    },
    {
        "generation": 29,
        "firstname": "Narmaya",
        "gender": "female",
        "surname": "Abheng",
        "father": "",
        "spouse": "wPQMm9eZcmVTM3W7ZQY8",
        "firstname_nepali": "नर्मया",
        "order": 1,
        "id": "Rna652ptf8GSiQgjcxRY"
    },
    {
        "generation": 29,
        "firstname": "Sansar",
        "gender": "male",
        "surname": "neyong",
        "father": "BnSgPFBxSRfQMVJyfPsy",
        "firstname_nepali": "संसार",
        "order": 1,
        "id": "Rzc0EkgtyHPuA8wMSnfI"
    },
    {
        "generation": 29,
        "firstname": "Mechali",
        "gender": "female",
        "surname": "Sambahamphe",
        "father": "EdA9PnqNWSa3kR0mDMwC",
        "firstname_nepali": "मेचाली",
        "order": 1,
        "id": "S3qPfwOmlN525dviWu0o"
    },
    {
        "generation": 27,
        "firstname": "Prithivi Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "fqdqLVyNkvlGvTXTMBIz",
        "firstname_nepali": "पृथ्वीबहादुर",
        "order": 1,
        "id": "SGx7IrnzwUx2XvIhDxzG"
    },
    {
        "generation": 29,
        "firstname": "Ganesh",
        "gender": "male",
        "surname": "neyong",
        "father": "n52s3uDsPp3A2XdaNaay",
        "firstname_nepali": "गणेश",
        "order": 1,
        "id": "SOtnxNl5OxCaNY8ZcAgy"
    },
    {
        "generation": 31,
        "firstname": "priti",
        "gender": "female",
        "surname": "Gurung",
        "father": "",
        "spouse": "ABwWIDQiWoRqXAHxoXbw",
        "firstname_nepali": "प्रीति",
        "order": 1,
        "id": "SRYgZwefSJkv02QQrCH3"
    },
    {
        "generation": 27,
        "firstname": "Nyaboti",
        "gender": "female",
        "surname": "neyong",
        "father": "fqdqLVyNkvlGvTXTMBIz",
        "firstname_nepali": "न्याबोटी",
        "order": 1,
        "id": "SSlGI6XGVPcgKzx4kTOX"
    },
    {
        "generation": 28,
        "firstname": "Harkamati",
        "gender": "female",
        "surname": "Okhrabu",
        "father": "",
        "spouse": "dv7viJovtWgjXcID9hqt",
        "firstname_nepali": "हर्कमती",
        "order": 1,
        "id": "SfFuv9I5sXjFfIt2TGT4"
    },
    {
        "generation": 29,
        "firstname": "Basundhara",
        "gender": "female",
        "surname": "Shreng Chongbang",
        "father": "",
        "spouse": "Imc5TtSjmhclodSnd4M1",
        "firstname_nepali": "बसुन्धरा",
        "order": 1,
        "id": "SshGhsCB6cdmF9EuKk45"
    },
    {
        "generation": 30,
        "firstname": "Gyankumari",
        "gender": "female",
        "surname": "sambahamphe",
        "father": "",
        "spouse": "ql3VFANib5uzCyGbKzGB",
        "firstname_nepali": "ज्ञानकुमारी",
        "order": 1,
        "id": "TFGj6cDAXP7jZMic1Vj4"
    },
    {
        "generation": 30,
        "firstname": "Sita",
        "gender": "female",
        "surname": "Sendang",
        "father": "",
        "spouse": "xkZl7TDiW90pUD4lWAqJ",
        "firstname_nepali": "सीता",
        "order": 1,
        "id": "TKu27djBU07twDzFOuBY"
    },
    {
        "generation": 26,
        "firstname": "Meghbir",
        "gender": "male",
        "surname": "neyong",
        "father": "zcHM10yqmUAAo2MiHU0j",
        "firstname_nepali": "मेघवीर",
        "order": 1,
        "id": "Tc40zxg3u4FxyFEqBH1R"
    },
    {
        "generation": 30,
        "firstname": "Tekmaya",
        "gender": "female",
        "surname": "Pangthak",
        "father": "",
        "spouse": "UNiRE5Asje4wUBao6Ovt",
        "firstname_nepali": "टेकमाया",
        "order": 1,
        "id": "Tm0k8LDo426vJ5bgwTTH"
    },
    {
        "generation": 28,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "",
        "spouse": "sYK5xBb6KRk4rX3iff0Y",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "U2LnejtbnYFWaSG15nUW"
    },
    {
        "generation": 30,
        "firstname": "Aren",
        "gender": "male",
        "surname": "neyong",
        "father": "QRWYRJQVys6Kmf3z3o5n",
        "firstname_nepali": "एरेन",
        "order": 1,
        "id": "U3VBbegt8x0qHPb2siqu"
    },
    {
        "generation": 28,
        "firstname": "Ganga",
        "gender": "female",
        "surname": "Pangma",
        "father": "u5mt0MbVXnMOpYT1sn9h",
        "firstname_nepali": "गंगा",
        "order": 1,
        "id": "U66oSWO0nNXbo700WvDc"
    },
    {
        "generation": 30,
        "firstname": "Parimala(Jayanti)",
        "gender": "female",
        "surname": "Shrestha",
        "father": "9bNY6EVA4CbzcXIIRNS9",
        "firstname_nepali": "परिमला(जयन्ती)",
        "order": 1,
        "id": "UXBnAJ1uUlHECYRShyph"
    },
    {
        "generation": 30,
        "firstname": "SherBahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "vXrdn3yKjJilj2N4Sa1Z",
        "firstname_nepali": "शेरबहादुर",
        "order": 1,
        "id": "UhVKsx9gZx4cHf3WF3j4"
    },
    {
        "generation": 31,
        "firstname": "sandip",
        "gender": "male",
        "surname": "neyong",
        "father": "11PRMH2SDGaWGJxTpfpM",
        "firstname_nepali": "सन्दीप",
        "order": 1,
        "id": "UolN6q9hkJAADwwnQ92U"
    },
    {
        "generation": 30,
        "firstname": "Mabin",
        "gender": "male",
        "surname": "neyong",
        "father": "tH7iv918UMwJjif31eSg",
        "firstname_nepali": "माबिन",
        "order": 1,
        "id": "Uq2aUIx2Jjf58mK1MoCo"
    },
    {
        "generation": 30,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "",
        "spouse": "6EsKckwf0hIIRmTH02kk",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "V4ZePZ3ABqm5cLLReIji"
    },
    {
        "generation": 30,
        "firstname": "Anjana",
        "gender": "female",
        "surname": "neyong",
        "father": "5st3NwcppZWvJGlqoFz1",
        "firstname_nepali": "अञ्जना",
        "order": 1,
        "id": "V6TvbAT7qGosooiIcid5"
    },
    {
        "generation": 30,
        "firstname": "Chhummani",
        "gender": "female",
        "surname": "aveng",
        "father": "",
        "spouse": "lvJOdENSgGMIGztLY2cU",
        "firstname_nepali": "चुम्मानी",
        "order": 2,
        "id": "RvaVTSjGwsVGoTR8UlLX"
    },
    {
        "generation": 30,
        "firstname": "Bikram",
        "gender": "male",
        "surname": "neyong",
        "father": "tH7iv918UMwJjif31eSg",
        "firstname_nepali": "बिक्रम",
        "order": 2,
        "id": "SebnjnPHaPj0q2Luc6kL"
    },
    {
        "generation": 30,
        "firstname": "Ratna Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "ikiGkJgxhaFwSdYUeXQ1",
        "firstname_nepali": "रत्नबहादुर",
        "order": 2,
        "id": "SmvCiXMbxWGrPvxueSXU"
    },
    {
        "generation": 31,
        "firstname": "Mahendra",
        "gender": "male",
        "surname": "neyong",
        "father": "SmvCiXMbxWGrPvxueSXU",
        "firstname_nepali": "महेन्द्र",
        "order": 2,
        "id": "Tay8N5S6O6msuc6SLUVE"
    },
    {
        "generation": 25,
        "firstname": "Chachal",
        "gender": "male",
        "surname": "neyong",
        "father": "oEafKRqTRSz0eMgYR9jK",
        "firstname_nepali": "चाचल",
        "order": 2,
        "id": "UXgo4mpm0X4z5PtPciRX"
    },
    {
        "generation": 30,
        "firstname": "Sarnashi",
        "gender": "male",
        "surname": "neyong",
        "father": "lvJOdENSgGMIGztLY2cU",
        "firstname_nepali": "सर्नसिं",
        "order": 2,
        "id": "VFchMsM9H5j3ikGSkG5h"
    },
    {
        "generation": 28,
        "firstname": "Seti",
        "gender": "female",
        "surname": "F. Liwang ",
        "father": "u3QHjXwD0a1n35GBCQuR",
        "firstname_nepali": "सेती",
        "order": 2,
        "id": "VbLrJRm4KuaRmxUlVEmv"
    },
    {
        "generation": 29,
        "firstname": "Gangaram",
        "gender": "male",
        "surname": "neyong",
        "father": "txwvFLftgmHOR0RxlO9A",
        "firstname_nepali": "गंगाराम",
        "order": 3,
        "id": "ReBeLoVQ8erbCu7RM6mG"
    },
    {
        "generation": 30,
        "firstname": "Kanchan",
        "gender": "female",
        "surname": "Khatiwada",
        "father": "JQhJS0JoOub4wEDU26Yw",
        "firstname_nepali": "कञ्चन",
        "order": 3,
        "id": "Rot6LvtGZjTV29fFOAD5"
    },
    {
        "generation": 29,
        "firstname": "Pawitra",
        "gender": "female",
        "surname": "neyong",
        "father": "kf4un20w1ssu3R7xYu3Y",
        "firstname_nepali": "पवित्रा",
        "order": 3,
        "id": "Sdl71zMTKbQhZl8HtZi4"
    },
    {
        "generation": 29,
        "firstname": "Kamala",
        "gender": "female",
        "surname": "Pangma",
        "father": "5alf3Nw8bulym1dR6cxr",
        "firstname_nepali": "कमला",
        "order": 3,
        "id": "T0HG0k0NgCyge8iVpoaL"
    },
    {
        "generation": 29,
        "firstname": "Kaushila ",
        "gender": "female",
        "surname": "Rai",
        "father": "Imc5TtSjmhclodSnd4M1",
        "firstname_nepali": "कौशिला",
        "order": 3,
        "id": "T1xtg6FfeTX7YQh6cqRt"
    },
    {
        "generation": 23,
        "firstname": "deshanshi",
        "gender": "male",
        "surname": "neyong",
        "father": "o7YMAqEasqeSQ0fNZGpp",
        "firstname_nepali": "देशानसि",
        "order": 3,
        "id": "U4c2PiRet7rYIXBgKAtd"
    },
    {
        "generation": 27,
        "firstname": "Maya",
        "gender": "female",
        "surname": "Kheba",
        "father": "fqdqLVyNkvlGvTXTMBIz",
        "firstname_nepali": "माया",
        "order": 3,
        "id": "UEDDE3adZ8YXu0fAHGVV"
    },
    {
        "generation": 29,
        "firstname": "Dilkumar",
        "gender": "male",
        "surname": "neyong",
        "father": "4I2vQ1fiu0BmwI0OlbzY",
        "firstname_nepali": "दिलकुमार",
        "order": 3,
        "id": "UNiRE5Asje4wUBao6Ovt"
    },
    {
        "generation": 29,
        "firstname": "Bishal",
        "gender": "male",
        "surname": "neyong",
        "father": "n52s3uDsPp3A2XdaNaay",
        "firstname_nepali": "बिशाल",
        "order": 3,
        "id": "Vfdu3plkfjeN8McErGum"
    },
    {
        "generation": 26,
        "firstname": "Ambare",
        "gender": "male",
        "surname": "neyong",
        "father": "UXgo4mpm0X4z5PtPciRX",
        "firstname_nepali": "अम्बरे",
        "order": 4,
        "id": "S4JhLLU1NWOj4E0Ot1Nd"
    },
    {
        "generation": 29,
        "firstname": "Amrita",
        "gender": "female",
        "surname": "neyong",
        "father": "5alf3Nw8bulym1dR6cxr",
        "firstname_nepali": "अमृता",
        "order": 4,
        "id": "SGpT2yyk3UH8t3rCpEF9"
    },
    {
        "generation": 30,
        "firstname": "Aaita Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "nMftfgKM92GtIvQnUN8Y",
        "firstname_nepali": "आइतबहादुर",
        "order": 4,
        "id": "SVUYjvlN8CYKYa6LiHKQ"
    },
    {
        "generation": 30,
        "firstname": "Narmaya",
        "gender": "male",
        "surname": "neyong",
        "father": "ikiGkJgxhaFwSdYUeXQ1",
        "firstname_nepali": "नरमाया",
        "order": 5,
        "id": "TvCzsuLRFT0MJLkLjfnG"
    },
    {
        "generation": 30,
        "firstname": "Aasha",
        "gender": "female",
        "surname": "Khadwa",
        "father": "OwV0mfHCf1GVYHiL7E4x",
        "firstname_nepali": "आशा",
        "order": 6,
        "id": "RfiFlokV1SER6eYyfyOC"
    },
    {
        "firstname": "yoyo",
        "gender": "male",
        "surname": "neyong",
        "firstname_nepali": "योयो",
        "father": "EBMUVNbw9HHtKwEZZyPx",
        "generation": 4,
        "id": "SsgZfkM9ZfUwy20MuuUN"
    },
    {
        "generation": 23,
        "firstname": "maal",
        "gender": "male",
        "surname": "neyong",
        "father": "o7YMAqEasqeSQ0fNZGpp",
        "firstname_nepali": "मल",
        "order": 4,
        "id": "Ui3cBNlx0exMDrZBxQE1"
    },
    {
        "generation": 29,
        "firstname": "Birkhamati",
        "gender": "female",
        "surname": "Angbuhang",
        "father": "kNtaIpShWQZl4KPsu6l3",
        "firstname_nepali": "विर्खमती",
        "order": 6,
        "id": "T8Vx2Wio7UIpxxyL8ujn"
    },
    {
        "generation": 27,
        "firstname": "unknown",
        "gender": "female",
        "surname": "Pangbat Libang",
        "father": "",
        "spouse": "exx5e7ZeKORqWUlq50nv",
        "firstname_nepali": "unknown",
        "id": "WQOTFiqmveNsm0OgxQmt"
    },
    {
        "generation": 31,
        "firstname": "premkumari",
        "gender": "female",
        "surname": "magar",
        "father": "",
        "spouse": "HDq5cGMBYMBBk0wLZNZ8",
        "firstname_nepali": "प्रेमकुमारी",
        "id": "WisRYHNmRlfhs9p8BnbN"
    },
    {
        "generation": 30,
        "firstname": "Sukmaya",
        "gender": "female",
        "surname": "neyong",
        "father": "pLnkJiXFiQcdylhCcZcB",
        "firstname_nepali": "सुकामाया",
        "order": 3,
        "id": "XxGv3ADbUPsP36UglWaA"
    },
    {
        "generation": 29,
        "firstname": "Birkha bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "wPQMm9eZcmVTM3W7ZQY8",
        "firstname_nepali": "बिरख बहादुर",
        "order": 3,
        "id": "Y2XKqKOd49y5XZT9iXDV"
    },
    {
        "generation": 29,
        "firstname": "Suchi",
        "gender": "female",
        "surname": "Khajum",
        "father": "IWuqwgwdrs0s9uGJTP95",
        "firstname_nepali": "सुची",
        "order": 3,
        "id": "YzW7eVzPxTpAySGqJj5L"
    },
    {
        "generation": 31,
        "firstname": "Jamuna",
        "gender": "female",
        "surname": "neyong",
        "father": "SmvCiXMbxWGrPvxueSXU",
        "firstname_nepali": "जमुना",
        "order": 3,
        "id": "Z9bnzRjB0Wzeo2iYdDhW"
    },
    {
        "generation": 30,
        "firstname": "Kusum",
        "gender": "female",
        "surname": "neyong",
        "father": "qZq4Ynfi4NIXYGFOzvfy",
        "firstname_nepali": "कुसुम",
        "order": 3,
        "id": "ZFQtkqdhmJeFRheCkLtm"
    },
    {
        "generation": 29,
        "firstname": "Hari Prasad",
        "gender": "male",
        "surname": "neyong",
        "father": "sSRNStHwtiSf582KrlVl",
        "firstname_nepali": "हरिप्रसाद",
        "order": 3,
        "id": "ZagHQ0QrPX2qEzX7GGNG"
    },
    {
        "generation": 29,
        "firstname": "Gaj Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "kf4un20w1ssu3R7xYu3Y",
        "firstname_nepali": "गजबहादुर",
        "order": 3,
        "id": "a56rJXA0e9D9degC1yWe"
    },
    {
        "generation": 27,
        "firstname": "Moti Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "3bibwxYc96UIXF2UGCkt",
        "firstname_nepali": "मोतीबहादुर",
        "order": 3,
        "id": "aKjN9UViVltXEmBim2ib"
    },
    {
        "generation": 29,
        "firstname": "Lalumaya",
        "gender": "female",
        "surname": "Maden",
        "father": "IWuqwgwdrs0s9uGJTP95",
        "firstname_nepali": "लालुमाया",
        "order": 4,
        "id": "Wq8IgM02ke5Ysajf9Hbj"
    },
    {
        "generation": 21,
        "firstname": "basantu",
        "gender": "male",
        "surname": "neyong",
        "father": "2VXuIXdhP2Hf5hGXuEk4",
        "firstname_nepali": "बसन्तु",
        "id": "X52wkoaL0xRQtykOJPRR"
    },
    {
        "generation": 30,
        "firstname": "Gumanshi",
        "gender": "male",
        "surname": "neyong",
        "father": "lvJOdENSgGMIGztLY2cU",
        "firstname_nepali": "गुमानसिं",
        "order": 3,
        "id": "Yaav4K0D7iLo7WPTeLlx"
    },
    {
        "generation": 30,
        "firstname": "Bhakta Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "a56rJXA0e9D9degC1yWe",
        "firstname_nepali": "भक्तबहादुर",
        "order": 3,
        "id": "dENfASWKy0eOgkoky8Lq"
    },
    {
        "generation": 28,
        "firstname": "Bhim Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "dv7viJovtWgjXcID9hqt",
        "firstname_nepali": "भीमबहादुर",
        "order": 3,
        "id": "dQxa13Ho5vQJGuMiKV2D"
    },
    {
        "generation": 28,
        "firstname": "Jagan Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "Yyk6rkgn77Gp8JLUSx97",
        "firstname_nepali": "जगनबहादुर",
        "order": 3,
        "id": "eXOIzWaaOWilxGaoNY1q"
    },
    {
        "generation": 26,
        "firstname": "Bardhoj",
        "gender": "male",
        "surname": "neyong",
        "father": "7r6MWXFKNvpwHDR6seQN",
        "firstname_nepali": "बर्धोज",
        "order": 3,
        "id": "fqdqLVyNkvlGvTXTMBIz"
    },
    {
        "generation": 29,
        "firstname": "Bipan",
        "gender": "male",
        "surname": "neyong",
        "father": "5alf3Nw8bulym1dR6cxr",
        "firstname_nepali": "बिपन",
        "order": 3,
        "id": "ldhuaY0jrtXEogWDgh4j"
    },
    {
        "generation": 31,
        "firstname": "Jagat Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "VFchMsM9H5j3ikGSkG5h",
        "firstname_nepali": "जगत",
        "order": 3,
        "id": "lmho1hVBTsCbvopng8ot"
    },
    {
        "generation": 29,
        "firstname": "Bhimkumari",
        "gender": "female",
        "surname": "neyong",
        "father": "72xTPRMzTjPPQQVDjSSB",
        "firstname_nepali": "भीमकुमारी",
        "order": 3,
        "id": "mpvtdqdjhLioKXskM0gc"
    },
    {
        "generation": 28,
        "firstname": "Harkamaya",
        "gender": "female",
        "surname": "Nembang",
        "father": "SGx7IrnzwUx2XvIhDxzG",
        "firstname_nepali": "हर्कमाया",
        "order": 3,
        "id": "n8aab57Cwy8k80r5tKJg"
    },
    {
        "generation": 24,
        "firstname": "saaimaanshi",
        "gender": "male",
        "surname": "neyong",
        "father": "PoTRFDxUu9kJRxkv5ok7",
        "firstname_nepali": "साइमान्शि",
        "order": 3,
        "id": "oEafKRqTRSz0eMgYR9jK"
    },
    {
        "generation": 31,
        "firstname": "Bhojbikram",
        "gender": "male",
        "surname": "neyong",
        "father": "VFchMsM9H5j3ikGSkG5h",
        "firstname_nepali": "भोजविक्रम",
        "order": 4,
        "id": "YVThdqP3AdPYZ4vi7drF"
    },
    {
        "generation": 29,
        "firstname": "Ranmati Okhrabu",
        "gender": "female",
        "surname": "neyong",
        "father": "qGZDF5Wc1bgWkE0OC74L",
        "firstname_nepali": "रनमति ओख्राब",
        "order": 4,
        "id": "Yp9xUGr50EeWmT2STQVH"
    },
    {
        "generation": 28,
        "firstname": "Minakumari",
        "gender": "female",
        "surname": "Kandangwa",
        "father": "SGx7IrnzwUx2XvIhDxzG",
        "firstname_nepali": "मीनाकुमारी",
        "order": 4,
        "id": "a6FdyISZL8yR5sKxxt49"
    },
    {
        "generation": 25,
        "firstname": "Rupsi",
        "gender": "male",
        "surname": "neyong",
        "father": "yQtDCK1tH6s4kX53TfY2",
        "firstname_nepali": "रुपसिं",
        "order": 4,
        "id": "adZmBL6zCCbLDwbTrg1Z"
    },
    {
        "generation": 30,
        "firstname": "Mina",
        "gender": "female",
        "surname": "neyong",
        "father": "qZq4Ynfi4NIXYGFOzvfy",
        "firstname_nepali": "मिना",
        "order": 4,
        "id": "cjoGizPeLhdWjnZ1p2dm"
    },
    {
        "generation": 30,
        "firstname": "Januka",
        "gender": "female",
        "surname": "neyong",
        "father": "t5MeCY6EJVRvW7u70xxz",
        "firstname_nepali": "जानुका",
        "order": 4,
        "id": "cofwwIil1Gp4ytGCOU7D"
    },
    {
        "generation": 29,
        "firstname": "Kabita",
        "gender": "female",
        "surname": "Nayong",
        "father": "eXOIzWaaOWilxGaoNY1q",
        "firstname_nepali": "कविता",
        "order": 4,
        "id": "fIyR0V18HryPxpsBzQ8v"
    },
    {
        "generation": 31,
        "firstname": "Bharat",
        "gender": "male",
        "surname": "neyong",
        "father": "6hdWyig4xeRlNlykDwun",
        "firstname_nepali": "भरत",
        "order": 4,
        "id": "lVjCk4dv2Kl5OL4LLKIU"
    },
    {
        "generation": 30,
        "firstname": "Harka Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "lvJOdENSgGMIGztLY2cU",
        "firstname_nepali": "हर्कबहादुर",
        "order": 4,
        "id": "myeaPpjzfdFflJDYBXqd"
    },
    {
        "generation": 29,
        "firstname": "Sitamaya",
        "gender": "female",
        "surname": "Angbuhang",
        "father": "kNtaIpShWQZl4KPsu6l3",
        "firstname_nepali": "सीतामाया",
        "order": 5,
        "id": "ZGrKnlRHPjMqv28Na2er"
    },
    {
        "generation": 27,
        "firstname": "Runhaang",
        "gender": "male",
        "surname": "neyong",
        "father": "OoNWKOdqDioDWMqkiCCT",
        "firstname_nepali": "रुन्हाङ",
        "order": 5,
        "id": "ZotaxQ2AZ50SaSBP7UfA"
    },
    {
        "generation": 30,
        "firstname": "Shreemaya",
        "gender": "female",
        "surname": "Chreng Chongbang",
        "father": "lvJOdENSgGMIGztLY2cU",
        "firstname_nepali": "श्रीमाया",
        "order": 5,
        "id": "aXWJqSKovy8T5KjYjSg1"
    },
    {
        "generation": 12,
        "gender": "male",
        "surname": "neyong",
        "father": "PHuuwvJDvJYAXq8DLbYE",
        "firstname": "Badhumyang",
        "firstname_nepali": "वदुम्यांङ",
        "id": "at4H7DeCucIeHVt2huDU"
    },
    {
        "generation": 28,
        "firstname": "Krishnamaya",
        "gender": "female",
        "surname": "neyong",
        "firstname_nepali": "कृष्णमाया",
        "order": 5,
        "father": "qGZDF5Wc1bgWkE0OC74L",
        "id": "cDaaCodqQjuJKI6hVCh3"
    },
    {
        "generation": 23,
        "firstname": "anahangba",
        "gender": "male",
        "surname": "neyong",
        "father": "o7YMAqEasqeSQ0fNZGpp",
        "firstname_nepali": "अनाहाङबा",
        "order": 5,
        "id": "chFhfo407ZIArvrTTwAv"
    },
    {
        "generation": 30,
        "firstname": "Sanu",
        "gender": "female",
        "surname": "neyong",
        "father": "pLnkJiXFiQcdylhCcZcB",
        "firstname_nepali": "सानु",
        "order": 5,
        "id": "d3hvK8o06B8f27aB0BX5"
    },
    {
        "generation": 30,
        "firstname": "Sapana",
        "gender": "female",
        "surname": "neyong",
        "father": "JQhJS0JoOub4wEDU26Yw",
        "firstname_nepali": "सपना",
        "order": 5,
        "id": "e7kZhaQaFz67WiBvsIUd"
    },
    {
        "generation": 19,
        "firstname": "kumnure",
        "gender": "male",
        "surname": "neyong",
        "father": "kBXjge3C9VmrfvWYNW5M",
        "firstname_nepali": "कुम्नुरे",
        "id": "eIJxjMlMKrSpXWPTR8dS"
    },
    {
        "generation": 31,
        "firstname": "tila",
        "gender": "female",
        "surname": "chhetri",
        "father": "",
        "spouse": "4K3qHOoZR3daXcJByv27",
        "firstname_nepali": "तिला",
        "id": "fCrkzJ6Bo6pT1cw8FgmY"
    },
    {
        "generation": 29,
        "firstname": "Sarita",
        "gender": "female",
        "surname": "Sawa",
        "father": "eXOIzWaaOWilxGaoNY1q",
        "firstname_nepali": "सरिता",
        "order": 5,
        "id": "ks9QOhTGMflphxjlCh0P"
    },
    {
        "generation": 29,
        "firstname": "Harkamati",
        "gender": "female",
        "surname": "Chreng",
        "father": "sSRNStHwtiSf582KrlVl",
        "firstname_nepali": "हर्कमती",
        "order": 6,
        "id": "fK3YXLhymyPOH80AxR0F"
    },
    {
        "generation": 31,
        "firstname": "srijana",
        "gender": "female",
        "surname": "rai",
        "father": "",
        "spouse": "11PRMH2SDGaWGJxTpfpM",
        "firstname_nepali": "सिर्जना",
        "id": "fdaGPPr8Ke4ssuD9s0TG"
    },
    {
        "generation": 25,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "",
        "spouse": "oEafKRqTRSz0eMgYR9jK",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "gFRasB2Do5mbSUQOChk3"
    },
    {
        "generation": 31,
        "firstname": "Shanti",
        "gender": "male",
        "surname": "neyong",
        "father": "sc5GQQMKHH0vIeceI39U",
        "firstname_nepali": "शान्ति",
        "order": 1,
        "id": "gZTeOK4xuk93pEbEIynx"
    },
    {
        "generation": 30,
        "firstname": "kiran",
        "gender": "male",
        "surname": "neyong",
        "father": "a56rJXA0e9D9degC1yWe",
        "firstname_nepali": "किरण",
        "order": 1,
        "id": "gbOj0p48VbBnWCn3SdYw"
    },
    {
        "generation": 30,
        "firstname": "Pramod",
        "gender": "male",
        "surname": "neyong",
        "father": "kXbMroxtUzS2HS1OUeWK",
        "firstname_nepali": " प्रमोद",
        "order": 1,
        "id": "glzr654HMGIIwBVCg58N"
    },
    {
        "generation": 30,
        "firstname": "Nushang",
        "gender": "female",
        "surname": "neyong",
        "father": "wXoi6mCx1t64iFs1E04j",
        "firstname_nepali": "नुशाङ",
        "order": 1,
        "id": "guUpXiGyzKpLvrsS5EqU"
    },
    {
        "generation": 30,
        "firstname": "Shanta",
        "gender": "female",
        "surname": "Ninglekhu",
        "father": "",
        "spouse": "ngyZZ89D3lUG54oXEE8d",
        "firstname_nepali": "शान्ता",
        "order": 1,
        "id": "h74O9oSADwbrUw4yjPAg"
    },
    {
        "generation": 30,
        "firstname": "Mankumar",
        "gender": "male",
        "surname": "neyong",
        "father": "6EsKckwf0hIIRmTH02kk",
        "firstname_nepali": "मनकुमार",
        "order": 1,
        "id": "hBfoNlhwDWAvjoC2FZPu"
    },
    {
        "generation": 29,
        "firstname": "Ambika",
        "gender": "male",
        "surname": "neyong",
        "father": "IWuqwgwdrs0s9uGJTP95",
        "firstname_nepali": "अम्बिका",
        "order": 1,
        "id": "hQyPAM4dDLfI5ShleBx2"
    },
    {
        "generation": 31,
        "firstname": "avikcchya",
        "gender": "male",
        "surname": "neyong",
        "father": "4K3qHOoZR3daXcJByv27",
        "firstname_nepali": "अभिक्षा",
        "order": 1,
        "id": "hTaaKIMBpCrispqLlKjg"
    },
    {
        "generation": 29,
        "firstname": "Bhima",
        "gender": "female",
        "surname": "Maden",
        "father": "",
        "spouse": "Oncg6ZoiciU8fyA610i9",
        "firstname_nepali": "भीमा",
        "order": 1,
        "id": "htKmQ0ug2vol1FjLL1Yw"
    },
    {
        "generation": 29,
        "firstname": "Hitlershi",
        "gender": "male",
        "surname": "neyong",
        "father": "7owTCC5XAFs3GtNO7DKV",
        "firstname_nepali": "हिटलरशी",
        "order": 1,
        "id": "iQF4rfLiMvcljf1eugyv"
    },
    {
        "generation": 29,
        "firstname": "Akal Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "kf4un20w1ssu3R7xYu3Y",
        "firstname_nepali": "अकलबहादुर",
        "order": 1,
        "id": "ikiGkJgxhaFwSdYUeXQ1"
    },
    {
        "generation": 29,
        "firstname": "Manmaya",
        "gender": "female",
        "surname": "Wanem",
        "father": "",
        "spouse": "wqBPds94CS3LmfwG6uN9",
        "firstname_nepali": "मनमाया",
        "order": 1,
        "id": "imNE9cvSw1nQ0cEgDIy4"
    },
    {
        "generation": 29,
        "firstname": "Bidhya",
        "gender": "female",
        "surname": "Sambahamphe",
        "father": "",
        "spouse": "miXHsHQr36HDj79PK0om",
        "firstname_nepali": "विद्या",
        "order": 1,
        "id": "j9OCbe4pFV2BCu2q2Iau"
    },
    {
        "generation": 30,
        "firstname": "Bishal",
        "gender": "male",
        "surname": "neyong",
        "father": "3YLvHWCWdU1XtyPUbQ90",
        "firstname_nepali": "बिशाल",
        "order": 1,
        "id": "jMaWAgrgEMDUBFpyZXZU"
    },
    {
        "generation": 31,
        "firstname": "Unknown",
        "gender": "male",
        "surname": "neyong",
        "father": "MaO7WuuLxsy37ha8ogDS",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "jOzifI27w7ILLZZou06j"
    },
    {
        "generation": 29,
        "firstname": "Dilmaya",
        "gender": "female",
        "surname": "neyong",
        "father": "IWuqwgwdrs0s9uGJTP95",
        "firstname_nepali": "दिलमाया",
        "order": 1,
        "id": "jUbQHeVdVDQVL9wIrQhE"
    },
    {
        "generation": 28,
        "firstname": "Dhanmaya",
        "gender": "female",
        "surname": "Wanem",
        "father": "LXwLyNatGKv4iOisO1yF",
        "firstname_nepali": "धनमाया",
        "order": 1,
        "id": "jjXBxVLUFCZ9fSw5ohbN"
    },
    {
        "generation": 26,
        "firstname": "Tekroma",
        "gender": "female",
        "surname": "Faben",
        "father": "",
        "spouse": "G273ZuT2l3ZV4MxJBnyp",
        "firstname_nepali": "टेक्रोमा",
        "order": 1,
        "id": "k7UbuQJIbPGt9JzFvPWZ"
    },
    {
        "generation": 30,
        "firstname": "Chandrakala",
        "gender": "female",
        "surname": "Thopra",
        "father": "",
        "spouse": "uxyxVmb94D6gbIXyHyag",
        "firstname_nepali": "चन्द्रकला",
        "order": 1,
        "id": "k9yojmgxMJPXEiFKITCD"
    },
    {
        "generation": 31,
        "firstname": "suren",
        "gender": "male",
        "surname": "neyong",
        "father": "YBL7z5EuHgONVjBj4m1O",
        "firstname_nepali": "सुरेन",
        "order": 1,
        "id": "kHe5CjRbnKadEHChSmLL"
    },
    {
        "generation": 28,
        "firstname": "Prithivi Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "Yyk6rkgn77Gp8JLUSx97",
        "firstname_nepali": "पृथ्वीबहादुर",
        "order": 1,
        "id": "kNtaIpShWQZl4KPsu6l3"
    },
    {
        "generation": 28,
        "firstname": "Shuk Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "efEWP3DLaCN0MxeRZt2M",
        "firstname_nepali": "शुकबहादुर",
        "order": 1,
        "id": "kf4un20w1ssu3R7xYu3Y"
    },
    {
        "generation": 29,
        "firstname": "Deumaan",
        "gender": "male",
        "surname": "neyong",
        "father": "IWuqwgwdrs0s9uGJTP95",
        "firstname_nepali": "देउमान",
        "order": 2,
        "id": "gEFDJ1HrpPxoVZbXqjjS"
    },
    {
        "generation": 28,
        "firstname": "Padammaya",
        "gender": "female",
        "surname": "Saba",
        "father": "dv7viJovtWgjXcID9hqt",
        "firstname_nepali": "पद्ममाया",
        "order": 2,
        "id": "gIIDIVg2Aw7olv3VZOLn"
    },
    {
        "generation": 31,
        "firstname": "Devkala",
        "gender": "female",
        "surname": "H. Chongbang",
        "father": "",
        "spouse": "SVUYjvlN8CYKYa6LiHKQ",
        "firstname_nepali": "देवकला",
        "id": "gcIsXF4onv9j8oIdhL56"
    },
    {
        "generation": 29,
        "firstname": "Chandramaya",
        "gender": "female",
        "surname": "Thangden",
        "father": "",
        "spouse": "dQxa13Ho5vQJGuMiKV2D",
        "firstname_nepali": "चन्द्रमाया",
        "order": 1,
        "id": "hgJi0uevlb4t0OGVmdSY"
    },
    {
        "generation": 31,
        "firstname": "Buddhamaya",
        "gender": "female",
        "surname": "Okhrabu",
        "father": "",
        "spouse": "myeaPpjzfdFflJDYBXqd",
        "firstname_nepali": "बुधमाया",
        "order": 1,
        "id": "hjCbgbJNlzlGIySDhX2r"
    },
    {
        "generation": 30,
        "firstname": "Mangsiri",
        "gender": "female",
        "surname": "neyong",
        "father": "dfPoFsd66CVmbvKE5kSs",
        "firstname_nepali": "मङ्गसिरी",
        "order": 1,
        "id": "htDAdVsDY4jcgDMBxsmF"
    },
    {
        "generation": 30,
        "firstname": "Riya",
        "gender": "female",
        "surname": "neyong",
        "father": "UNiRE5Asje4wUBao6Ovt",
        "firstname_nepali": "रिया",
        "order": 1,
        "id": "j0VX67lL3nq34RVbuuOM"
    },
    {
        "generation": 27,
        "firstname": "kaalushi",
        "gender": "male",
        "surname": "neyong",
        "father": "IYCDjWRH1AC4Xnt2V5E0",
        "firstname_nepali": "कालुशी",
        "order": 1,
        "id": "jTIi0r5aQKbt6E2HCLjb"
    },
    {
        "generation": 27,
        "firstname": "Buddiwanta",
        "gender": "male",
        "surname": "neyong",
        "father": "3bibwxYc96UIXF2UGCkt",
        "firstname_nepali": "बुद्धिवन्त",
        "order": 1,
        "id": "jVFVyLs5pncqQ3NghTkv"
    },
    {
        "generation": 31,
        "firstname": "sujan",
        "gender": "male",
        "surname": "neyong",
        "father": "HDq5cGMBYMBBk0wLZNZ8",
        "firstname_nepali": "सुजन",
        "order": 2,
        "id": "gj3EFzPCT6b5CIcaxfwQ"
    },
    {
        "generation": 28,
        "firstname": "Dil Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "InqYU3gbNlXbuagAYZVM",
        "firstname_nepali": "दिलबहादुर",
        "order": 2,
        "id": "h19FdDID6NoY5evIqsdy"
    },
    {
        "generation": 30,
        "firstname": "Samwaaru",
        "gender": "male",
        "surname": "neyong",
        "father": "5st3NwcppZWvJGlqoFz1",
        "firstname_nepali": "सम्वरु",
        "order": 2,
        "id": "h8BPQS8Xg5tkyHYsvFsz"
    },
    {
        "generation": 31,
        "firstname": "Wachan",
        "gender": "male",
        "surname": "neyong",
        "father": "Yaav4K0D7iLo7WPTeLlx",
        "firstname_nepali": "वचन",
        "order": 2,
        "id": "i1YO3tzYmYz3u6g9saXJ"
    },
    {
        "generation": 31,
        "firstname": "Aashish",
        "gender": "male",
        "surname": "neyong",
        "father": "vPJscrn8uDcIxyqnFaZk",
        "firstname_nepali": "आशिष",
        "order": 2,
        "id": "iCXC65isX9jpkdioSJy6"
    },
    {
        "generation": 27,
        "firstname": "gumdal",
        "gender": "male",
        "surname": "neyong",
        "father": "IYCDjWRH1AC4Xnt2V5E0",
        "firstname_nepali": "गुमदल",
        "order": 2,
        "id": "jvu9WcOQ1Pq7s7ijBoNk"
    },
    {
        "generation": 31,
        "firstname": "Lilamani",
        "gender": "male",
        "surname": "neyong",
        "father": "I2Ri8A10eCMMJWHWXkg2",
        "firstname_nepali": "लीलामणि",
        "order": 2,
        "id": "kRmv5WsnFayz7u2ygDTJ"
    },
    {
        "generation": 31,
        "firstname": "Sangita",
        "gender": "female",
        "surname": "neyong",
        "father": "Yaav4K0D7iLo7WPTeLlx",
        "firstname_nepali": "संगीता",
        "order": 3,
        "id": "ijwhuXzwC8SqTcNmkbeP"
    },
    {
        "generation": 29,
        "firstname": "Harkamati",
        "gender": "female",
        "surname": "Yakso",
        "father": "eXOIzWaaOWilxGaoNY1q",
        "firstname_nepali": "हर्कमती",
        "order": 3,
        "id": "j7yDk9V29LNCvIxPkBla"
    },
    {
        "generation": 28,
        "firstname": "Chhatra Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "InqYU3gbNlXbuagAYZVM",
        "firstname_nepali": "छत्रबहादुर",
        "order": 3,
        "id": "jNq9JicWbpnRC2VMXjMO"
    },
    {
        "generation": 30,
        "firstname": "Narmaya",
        "gender": "female",
        "surname": "Wanem",
        "father": "",
        "spouse": "pLnkJiXFiQcdylhCcZcB",
        "firstname_nepali": "नर्मया ",
        "order": 3,
        "id": "jqlbxAkSEsRfEPEMhDeM"
    },
    {
        "generation": 24,
        "firstname": "jeewaaraay",
        "gender": "male",
        "surname": "neyong",
        "firstname_nepali": "जीवाराय",
        "order": 5,
        "father": "PoTRFDxUu9kJRxkv5ok7",
        "id": "jah7fN311IS4msHcF6Tk"
    },
    {
        "generation": 31,
        "firstname": "Radha",
        "gender": "female",
        "surname": "Parghari",
        "father": "",
        "spouse": "o7fTY7jpN2NLCBeAgJ7y",
        "firstname_nepali": "राधा",
        "id": "jqYJwSKmwnSORU3U8EX0"
    },
    {
        "generation": 18,
        "firstname": "ingjere",
        "gender": "male",
        "surname": "neyong",
        "father": "yeu9N9gngQQL5IKDwF8W",
        "firstname_nepali": "इङजेरे",
        "id": "kBXjge3C9VmrfvWYNW5M"
    },
    {
        "generation": 29,
        "firstname": "Dal Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "kNtaIpShWQZl4KPsu6l3",
        "firstname_nepali": "दलबहादुर",
        "order": 3,
        "id": "kXbMroxtUzS2HS1OUeWK"
    },
    {
        "generation": 29,
        "firstname": "Sumitra",
        "gender": "female",
        "surname": "neyong",
        "father": "IWuqwgwdrs0s9uGJTP95",
        "firstname_nepali": "सुमित्रा",
        "order": 8,
        "id": "bGadtE3mhgu45vMPdgfZ"
    },
    {
        "generation": 30,
        "firstname": "Sundari",
        "gender": "female",
        "surname": "Cherang Chongbang",
        "father": "",
        "spouse": "nMftfgKM92GtIvQnUN8Y",
        "firstname_nepali": "सुन्दरी",
        "id": "laESOBfHoK2ryzvgaXrD"
    },
    {
        "generation": 29,
        "firstname": "Parwati",
        "gender": "female",
        "surname": "F.Chongbang",
        "father": "",
        "spouse": "4I2vQ1fiu0BmwI0OlbzY",
        "firstname_nepali": "पार्वती",
        "id": "lqg7Kmee1HMe26qkGiiy"
    },
    {
        "generation": 29,
        "firstname": "Sabrada",
        "gender": "female",
        "surname": "Singyem",
        "father": "",
        "spouse": "wkAS2er2VnhS4hpSgltq",
        "firstname_nepali": "सर्बदा",
        "id": "lwgx3xWLer7e0XqZnJc3"
    },
    {
        "generation": 7,
        "firstname": "samyung",
        "gender": "male",
        "surname": "neyong",
        "father": "FusTqUC20qHDR0UugPAJ",
        "firstname_nepali": "सम्युंङ्ग",
        "id": "m6BYlslNndD2N5fCBGKr"
    },
    {
        "generation": 30,
        "firstname": "Anish",
        "gender": "male",
        "surname": "neyong",
        "father": "Yht84zfwSdRGQIKzTNh4",
        "firstname_nepali": "अनिस",
        "order": 3,
        "id": "pKnfr3qJceYuGpOstaln"
    },
    {
        "generation": 30,
        "firstname": "Kabita",
        "gender": "female",
        "surname": "neyong",
        "father": "ImkXMy2dTkAhNihvm6zk",
        "firstname_nepali": "कविता",
        "order": 3,
        "id": "qNuTZWXbBAsx7UdLv5IP"
    },
    {
        "generation": 29,
        "firstname": "Rudra Prasad",
        "gender": "male",
        "surname": "neyong",
        "father": "wkAS2er2VnhS4hpSgltq",
        "firstname_nepali": "रुद्रप्रसाद",
        "order": 3,
        "id": "qcsZQblaYoGvkRwVHwaF"
    },
    {
        "generation": 30,
        "firstname": "Lokendra",
        "gender": "male",
        "surname": "neyong",
        "father": "OwV0mfHCf1GVYHiL7E4x",
        "firstname_nepali": "लोकेन्द्र",
        "order": 3,
        "id": "qnxzJBxwipc9e4E0iarg"
    },
    {
        "generation": 30,
        "firstname": "Chandrakala",
        "gender": "female",
        "surname": "Rai",
        "father": "JmQAJQarEcVQ8v9jXrF9",
        "firstname_nepali": "चन्द्रकला",
        "order": 3,
        "id": "s4nXQWDBcclbFxCBkNoM"
    },
    {
        "generation": 30,
        "firstname": "nanda kumar",
        "gender": "male",
        "surname": "neyong",
        "father": "EUV9t8FpK21GDWNL11Ic",
        "firstname_nepali": "नन्दकुमार",
        "order": 4,
        "id": "pCfVR2jt4AFragku86sg"
    },
    {
        "generation": 30,
        "firstname": "Maiya",
        "gender": "female",
        "surname": "neyong",
        "father": "JmQAJQarEcVQ8v9jXrF9",
        "firstname_nepali": "मैंया",
        "order": 4,
        "id": "qAOsYFDAWfaWDkD6OKuO"
    },
    {
        "generation": 29,
        "firstname": "Gaduldhoj",
        "gender": "male",
        "surname": "neyong",
        "father": "kf4un20w1ssu3R7xYu3Y",
        "firstname_nepali": "गडुलधोज",
        "order": 4,
        "id": "re3rudyfMy7PkrwnU1j3"
    },
    {
        "generation": 24,
        "firstname": "bhaagpaal",
        "gender": "male",
        "surname": "neyong",
        "father": "PoTRFDxUu9kJRxkv5ok7",
        "firstname_nepali": "भागपाल",
        "order": 4,
        "id": "rxhZON7MFycPT0sw790V"
    },
    {
        "generation": 30,
        "firstname": "Bhimkumari",
        "gender": "female",
        "surname": "neyong",
        "father": "re3rudyfMy7PkrwnU1j3",
        "firstname_nepali": "भीमकुमारी",
        "order": 5,
        "id": "mxUKA2IfAAoM66WzO6Zh"
    },
    {
        "generation": 31,
        "firstname": "Indira",
        "gender": "female",
        "surname": "Rai",
        "father": "",
        "spouse": "FDuIt3kIlkgc71RRxtLm",
        "firstname_nepali": "इन्दिरा",
        "id": "nciuxNhX7ecGF7ievCJo"
    },
    {
        "generation": 22,
        "firstname": "gumsaita",
        "gender": "male",
        "surname": "neyong",
        "father": "X52wkoaL0xRQtykOJPRR",
        "firstname_nepali": "गुम्सैता",
        "id": "o7YMAqEasqeSQ0fNZGpp"
    },
    {
        "generation": 30,
        "firstname": "Kajindra",
        "gender": "male",
        "surname": "neyong",
        "father": "nMftfgKM92GtIvQnUN8Y",
        "firstname_nepali": "कजिन्द्र",
        "order": 5,
        "id": "o7fTY7jpN2NLCBeAgJ7y"
    },
    {
        "generation": 29,
        "firstname": "Indramaya",
        "gender": "female",
        "surname": "neyong",
        "father": "wkAS2er2VnhS4hpSgltq",
        "firstname_nepali": "इन्द्रमाया",
        "order": 5,
        "id": "pWjP9Ic0IL2EwyYFa2Ie"
    },
    {
        "generation": 29,
        "firstname": "Jagandhoj",
        "gender": "male",
        "surname": "neyong",
        "father": "sSRNStHwtiSf582KrlVl",
        "firstname_nepali": "जगनधोज",
        "order": 5,
        "id": "ql3VFANib5uzCyGbKzGB"
    },
    {
        "generation": 29,
        "firstname": "Dhankumari",
        "gender": "female",
        "surname": "neyong",
        "father": "wkAS2er2VnhS4hpSgltq",
        "firstname_nepali": "धनकुमारी",
        "order": 6,
        "id": "qORQV188vyTHB3ajz697"
    },
    {
        "generation": 29,
        "firstname": "Tara",
        "gender": "female",
        "surname": "neyong",
        "father": "wkAS2er2VnhS4hpSgltq",
        "firstname_nepali": "तारा",
        "order": 7,
        "id": "oWeO8ScMAV6SAfxgakaK"
    },
    {
        "generation": 30,
        "firstname": "Tulsimaya",
        "gender": "female",
        "surname": "Rai",
        "father": "ZagHQ0QrPX2qEzX7GGNG",
        "firstname_nepali": "तुलसीमाया",
        "order": 7,
        "id": "pAAHWIsSWyCFVxffPumN"
    },
    {
        "generation": 30,
        "firstname": "Gunkumari",
        "gender": "female",
        "surname": "Pangma",
        "father": "lvJOdENSgGMIGztLY2cU",
        "firstname_nepali": "गुणकुमारी",
        "order": 7,
        "id": "qlUUBRnyGpyAf7tNueT7"
    },
    {
        "firstname": "sawash",
        "gender": "male",
        "surname": "neyong",
        "father": "HW5K9vP9C72U9oXMAonW",
        "firstname_nepali": "स्वाश",
        "generation": 5,
        "id": "r5088R2zbGzMSD7iN2a9"
    },
    {
        "generation": 10,
        "firstname": "bufungjang",
        "gender": "male",
        "surname": "neyong",
        "father": "IJw5MAlspH97Qm8ClSzi",
        "firstname_nepali": "बुफुङजङ",
        "id": "rh6vJyZ6GbEIVtsjRyxN"
    },
    {
        "generation": 28,
        "firstname": "Fujungi ",
        "gender": "female",
        "surname": "Wanem",
        "father": "YbfnmnrCBHCa4fd8FOHH",
        "firstname_nepali": "फुजुंगी",
        "order": 1,
        "id": "sOLGoLQC4sC9diAzcElF"
    },
    {
        "generation": 28,
        "firstname": "Krishnabahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "u3QHjXwD0a1n35GBCQuR",
        "firstname_nepali": "कृष्णबहादुर",
        "order": 1,
        "id": "sSRNStHwtiSf582KrlVl"
    },
    {
        "generation": 30,
        "firstname": "Khadga bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "ikiGkJgxhaFwSdYUeXQ1",
        "firstname_nepali": "खड्गबहादुर",
        "order": 1,
        "id": "sc5GQQMKHH0vIeceI39U"
    },
    {
        "generation": 29,
        "firstname": "Khadga",
        "gender": "male",
        "surname": "neyong",
        "father": "wkAS2er2VnhS4hpSgltq",
        "firstname_nepali": "खड्गप्रसाद",
        "order": 1,
        "id": "t5MeCY6EJVRvW7u70xxz"
    },
    {
        "generation": 30,
        "firstname": "Bishnukumar",
        "gender": "male",
        "surname": "neyong",
        "father": "JmQAJQarEcVQ8v9jXrF9",
        "firstname_nepali": "बिष्णुकुमार",
        "order": 1,
        "id": "tB4EAyDEtofH4kzSr9H5"
    },
    {
        "generation": 29,
        "firstname": "Rajendra",
        "gender": "male",
        "surname": "neyong",
        "father": "4I2vQ1fiu0BmwI0OlbzY",
        "firstname_nepali": "राजेन्द्र",
        "order": 1,
        "id": "tH7iv918UMwJjif31eSg"
    },
    {
        "generation": 29,
        "firstname": "Chandramaya",
        "gender": "female",
        "surname": "neyong",
        "father": "72xTPRMzTjPPQQVDjSSB",
        "firstname_nepali": "चन्द्रमाया",
        "order": 1,
        "id": "tJJYsbCqxPYEkiii8Iax"
    },
    {
        "generation": 30,
        "firstname": "Narmaya",
        "gender": "female",
        "surname": "Pangma",
        "father": "",
        "spouse": "wXoi6mCx1t64iFs1E04j",
        "firstname_nepali": "नर्मया",
        "order": 1,
        "id": "tdo8ZeB7kBcyezGnL9AY"
    },
    {
        "generation": 29,
        "firstname": "Jasmaya",
        "gender": "female",
        "surname": "neyong",
        "father": "Imc5TtSjmhclodSnd4M1",
        "firstname_nepali": "जसमाया",
        "order": 1,
        "id": "tvzaODBYHDsyqcdU9swF"
    },
    {
        "generation": 28,
        "firstname": "Man Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "Fg2kKyyZb5vWv3118HpK",
        "firstname_nepali": "मनबहादुर",
        "order": 1,
        "id": "txwvFLftgmHOR0RxlO9A"
    },
    {
        "generation": 27,
        "firstname": "Sarganj",
        "gender": "male",
        "surname": "neyong",
        "father": "8ALJvsu4rCbrreGPywFx",
        "firstname_nepali": "सारगन्ज",
        "order": 1,
        "id": "u3QHjXwD0a1n35GBCQuR"
    },
    {
        "generation": 25,
        "firstname": "bhartashi",
        "gender": "male",
        "surname": "neyong",
        "father": "7OBd3Izm7KcYOBJABNRS",
        "firstname_nepali": "भर्तशि",
        "order": 1,
        "id": "uDjUwINr0vKxU8oUwvBr"
    },
    {
        "generation": 31,
        "firstname": "Amrit",
        "gender": "female",
        "surname": "neyong",
        "father": "SmvCiXMbxWGrPvxueSXU",
        "firstname_nepali": "अमृत",
        "order": 1,
        "id": "uR5kEGA4BckxLk6llQjW"
    },
    {
        "generation": 30,
        "firstname": "Dilkumari",
        "gender": "female",
        "surname": "neyong",
        "father": "pLnkJiXFiQcdylhCcZcB",
        "firstname_nepali": "दिलकुमारी",
        "order": 1,
        "id": "uXgnRQ1e6CAY18V4C0g7"
    },
    {
        "generation": 28,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "Okhrabu",
        "father": "",
        "spouse": "Yyk6rkgn77Gp8JLUSx97",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "uiPyThQDcMlnDHExNw0y"
    },
    {
        "generation": 30,
        "firstname": "Chijmaya",
        "gender": "female",
        "surname": "Thangden",
        "father": "",
        "spouse": "ikiGkJgxhaFwSdYUeXQ1",
        "firstname_nepali": "चिजमाया",
        "order": 1,
        "id": "v2ZMd1cS9jX8XnvCfYD0"
    },
    {
        "generation": 29,
        "firstname": "Budhroti",
        "gender": "female",
        "surname": "Liwang",
        "father": "",
        "spouse": "kNtaIpShWQZl4KPsu6l3",
        "firstname_nepali": "बुधरोती",
        "order": 1,
        "id": "v7D6dPAdGazZvZUrFs5s"
    },
    {
        "generation": 31,
        "firstname": "Bimala",
        "gender": "female",
        "surname": "Kedep",
        "father": "",
        "spouse": "I2Ri8A10eCMMJWHWXkg2",
        "firstname_nepali": "बिमला",
        "order": 1,
        "id": "vOrorcefuJhqOCwU905z"
    },
    {
        "generation": 30,
        "firstname": "Tanka Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "JQhJS0JoOub4wEDU26Yw",
        "firstname_nepali": "टंकबहादुर",
        "order": 1,
        "id": "vPJscrn8uDcIxyqnFaZk"
    },
    {
        "generation": 29,
        "firstname": "kavilal",
        "gender": "male",
        "surname": "neyong",
        "father": "qGZDF5Wc1bgWkE0OC74L",
        "firstname_nepali": "कविलाल",
        "order": 1,
        "id": "vXrdn3yKjJilj2N4Sa1Z"
    },
    {
        "generation": 30,
        "firstname": "Chandramaya",
        "gender": "female",
        "surname": "Langwa",
        "father": "",
        "spouse": "dfPoFsd66CVmbvKE5kSs",
        "firstname_nepali": "चन्द्रमाया",
        "order": 1,
        "id": "w7PGe6tyX2RVr3FDY3Gt"
    },
    {
        "generation": 28,
        "firstname": "Harka bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "GAEABfATbf0FabIDXRq9",
        "firstname_nepali": "हर्कबहादुर",
        "order": 1,
        "id": "wPQMm9eZcmVTM3W7ZQY8"
    },
    {
        "generation": 27,
        "firstname": "Man Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "fqdqLVyNkvlGvTXTMBIz",
        "firstname_nepali": "मानबहादुर",
        "order": 2,
        "id": "sOCofrH1YF9KUC3xrOJh"
    },
    {
        "generation": 28,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "Maden",
        "father": "",
        "spouse": "Yyk6rkgn77Gp8JLUSx97",
        "firstname_nepali": "Unknown",
        "order": 2,
        "id": "sRXsllq36VM7FCEHB0C0"
    },
    {
        "generation": 27,
        "firstname": "Maitabir",
        "gender": "male",
        "surname": "neyong",
        "father": "3bibwxYc96UIXF2UGCkt",
        "firstname_nepali": "मैत्बीर",
        "order": 2,
        "id": "sYK5xBb6KRk4rX3iff0Y"
    },
    {
        "generation": 31,
        "firstname": "Aayushma",
        "gender": "male",
        "surname": "neyong",
        "father": "IdBmWceSURfNlN1qwGLV",
        "firstname_nepali": "आयुष्मा",
        "order": 2,
        "id": "uW18aEGvqmkTh6tFzxZT"
    },
    {
        "generation": 29,
        "firstname": "Shree Prasad",
        "gender": "male",
        "surname": "neyong",
        "father": "dQxa13Ho5vQJGuMiKV2D",
        "firstname_nepali": "श्रीप्रसाद",
        "order": 2,
        "id": "uxyxVmb94D6gbIXyHyag"
    },
    {
        "generation": 31,
        "firstname": "Dilashi",
        "gender": "female",
        "surname": "neyong",
        "father": "I2Ri8A10eCMMJWHWXkg2",
        "firstname_nepali": "दिलाशी",
        "order": 2,
        "id": "v7pmCcfSp3eC7Gdfvjml"
    },
    {
        "generation": 31,
        "firstname": "Sanja",
        "gender": "female",
        "surname": "H.Chongbang",
        "father": "",
        "spouse": "vPJscrn8uDcIxyqnFaZk",
        "firstname_nepali": "सन्जा",
        "order": 2,
        "id": "vo9xgtMCIhENnGWfHDiJ"
    },
    {
        "generation": 28,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "1GepDR9JBG91M6qmNVTG",
        "firstname_nepali": "Unknown",
        "order": 2,
        "id": "w3mfeYmqBsMZzwQDhSjU"
    },
    {
        "generation": 29,
        "firstname": "Deshkumari",
        "gender": "female",
        "surname": "Pangthak",
        "father": "dQxa13Ho5vQJGuMiKV2D",
        "firstname_nepali": "देशकुमारी",
        "order": 2,
        "id": "w7ygPW2K2951txrVYYOF"
    },
    {
        "generation": 28,
        "firstname": "Faguhang",
        "gender": "male",
        "surname": "neyong",
        "father": "u5mt0MbVXnMOpYT1sn9h",
        "firstname_nepali": "फागुहाङ",
        "order": 3,
        "id": "tHJfnfHlXZjOmPQjbfST"
    },
    {
        "generation": 30,
        "firstname": "Namphung",
        "gender": "female",
        "surname": "neyong",
        "father": "R2HEcMIqsvQa7kON8pm1",
        "firstname_nepali": "नामफुङ",
        "order": 3,
        "id": "tXEjkdu3Q16y1UcIb6iY"
    },
    {
        "generation": 29,
        "firstname": "Haikamsi",
        "gender": "male",
        "surname": "neyong",
        "father": "7owTCC5XAFs3GtNO7DKV",
        "firstname_nepali": "हाइकाम्सी",
        "order": 3,
        "id": "tvvv8qSQpZHmigB6GGUR"
    },
    {
        "generation": 27,
        "firstname": "Khadga Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "OoNWKOdqDioDWMqkiCCT",
        "firstname_nepali": "खड्गबहादुर",
        "order": 4,
        "id": "u5mt0MbVXnMOpYT1sn9h"
    },
    {
        "generation": 30,
        "firstname": "Laxmi",
        "gender": "female",
        "surname": "neyong",
        "father": "ql3VFANib5uzCyGbKzGB",
        "firstname_nepali": "लक्ष्मी",
        "order": 4,
        "id": "u9t4A6OUAMQh3MFLtARG"
    },
    {
        "generation": 30,
        "firstname": "Sabita",
        "gender": "female",
        "surname": "neyong",
        "father": "ImkXMy2dTkAhNihvm6zk",
        "firstname_nepali": "सविता",
        "order": 4,
        "id": "uXi8DEpB6NG4di9L4GSe"
    },
    {
        "generation": 29,
        "firstname": "Nandakumari",
        "gender": "female",
        "surname": "neyong",
        "father": "wkAS2er2VnhS4hpSgltq",
        "firstname_nepali": "नन्दकुमारी",
        "order": 4,
        "id": "v3RCKqRrRhM0MMgGdS95"
    },
    {
        "generation": 31,
        "firstname": "Tilbikram",
        "gender": "male",
        "surname": "neyong",
        "father": "VFchMsM9H5j3ikGSkG5h",
        "firstname_nepali": "तिलविक्रम",
        "order": 5,
        "id": "tg9kZzkG6SSztphlAJpG"
    },
    {
        "generation": 30,
        "firstname": "binod",
        "gender": "male",
        "surname": "neyong",
        "father": "zGv5Oqm4WMll6hXgZLv5",
        "firstname_nepali": "बिनोद",
        "order": 5,
        "id": "uKCpgN4BkNv7NJh6qOvI"
    },
    {
        "generation": 30,
        "firstname": "Tink Maya",
        "gender": "female",
        "surname": "Singtha",
        "father": "Yht84zfwSdRGQIKzTNh4",
        "firstname_nepali": "टंकमाया",
        "order": 5,
        "id": "uKWLx8B75CQKBPWwbjIg"
    },
    {
        "generation": 31,
        "firstname": "Dilkumari",
        "gender": "female",
        "surname": "Liwang",
        "father": "6hdWyig4xeRlNlykDwun",
        "firstname_nepali": "दिलकुमारी",
        "order": 7,
        "id": "sVx0RRirjduskEacVJqi"
    },
    {
        "firstname": "nayaba",
        "gender": "male",
        "surname": "neyong",
        "father": "",
        "generation": 1,
        "firstname_nepali": "नयबा",
        "id": "veAMM4hkEsggQzTFLm3r"
    },
    {
        "generation": 29,
        "firstname": "Anjali",
        "gender": "female",
        "surname": "Rai",
        "father": "IWuqwgwdrs0s9uGJTP95",
        "firstname_nepali": "अञ्जली",
        "order": 6,
        "id": "wGVQQUx37PljGOEwH3i0"
    },
    {
        "generation": 30,
        "firstname": "Umadevi",
        "gender": "female",
        "surname": "Wanem",
        "father": "lvJOdENSgGMIGztLY2cU",
        "firstname_nepali": "उमादेवी",
        "order": 8,
        "id": "w2D9knNRcYawmpGZ5AYT"
    },
    {
        "generation": 30,
        "firstname": "gaulaxmi",
        "gender": "female",
        "surname": "rai",
        "father": "",
        "spouse": "zGv5Oqm4WMll6hXgZLv5",
        "firstname_nepali": "गौलक्ष्मी",
        "id": "wQ5Lq6pC45lTWqR5ijoI"
    },
    {
        "generation": 31,
        "firstname": "dhanmaya",
        "gender": "female",
        "surname": "magar",
        "father": "",
        "spouse": "YBL7z5EuHgONVjBj4m1O",
        "firstname_nepali": "धनमाया",
        "id": "wUqhJKFSMZx9YHWw1jgF"
    },
    {
        "generation": 30,
        "firstname": "Sabin",
        "gender": "male",
        "surname": "neyong",
        "father": "ngyZZ89D3lUG54oXEE8d",
        "firstname_nepali": "सबिन",
        "order": 1,
        "id": "wYqXEQNMgQo3DrxtRJAu"
    },
    {
        "generation": 28,
        "firstname": "Moti Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "CuMwzHx4dHOkCKMfS3Kq",
        "firstname_nepali": "मोतिबहादुर",
        "order": 1,
        "id": "wkAS2er2VnhS4hpSgltq"
    },
    {
        "generation": 31,
        "firstname": "Preajon",
        "gender": "female",
        "surname": "Lapcha",
        "father": "",
        "spouse": "SmvCiXMbxWGrPvxueSXU",
        "firstname_nepali": "प्रेजन",
        "order": 1,
        "id": "wnefqLkOw8fgcZosP01I"
    },
    {
        "generation": 28,
        "firstname": "Lok Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "BNIMygjbAj4nQvDxUcfJ",
        "firstname_nepali": "लोकबहादुर",
        "order": 1,
        "id": "wqBPds94CS3LmfwG6uN9"
    },
    {
        "generation": 27,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "",
        "spouse": "20D1cu5miJxZUqLZXvdh",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "wqK8DoKQjxruJVgDqCmG"
    },
    {
        "generation": 29,
        "firstname": "Sharda",
        "gender": "female",
        "surname": "Chongbang",
        "father": "",
        "spouse": "BnSgPFBxSRfQMVJyfPsy",
        "firstname_nepali": "शारदा",
        "order": 1,
        "id": "x3omjjHKLkM6JrraZWBP"
    },
    {
        "generation": 29,
        "firstname": "Buddhamaya",
        "gender": "female",
        "surname": "Fago Chongbang",
        "father": "",
        "spouse": "sSRNStHwtiSf582KrlVl",
        "firstname_nepali": "बुधमाया",
        "order": 1,
        "id": "xLlIrymdJKWU4nvdacSk"
    },
    {
        "generation": 30,
        "firstname": "Gaulaxmi",
        "gender": "female",
        "surname": "Rai",
        "father": "",
        "spouse": "zGv5Oqm4WMll6hXgZLv5",
        "firstname_nepali": "गौलक्ष्मी",
        "order": 1,
        "id": "y0FdMQbqCotBB7ev8Ujg"
    },
    {
        "generation": 29,
        "firstname": "Ranmaya",
        "gender": "female",
        "surname": "Wanem",
        "father": "",
        "spouse": "5alf3Nw8bulym1dR6cxr",
        "firstname_nepali": "रणमाया",
        "order": 1,
        "id": "yAHZMXb78vEmxupiWZf3"
    },
    {
        "generation": 26,
        "firstname": "Unknown",
        "gender": "female",
        "surname": "neyong",
        "father": "",
        "spouse": "3hu3aXkYoxPY9VA96MJT",
        "firstname_nepali": "Unknown",
        "order": 1,
        "id": "yKvv2mCwpfz6ae3fz5x3"
    },
    {
        "generation": 29,
        "firstname": "Gangamaya",
        "gender": "female",
        "surname": "Faben",
        "father": "",
        "spouse": "Dt6LJ1nYTuaOj6ZwSW5z",
        "firstname_nepali": "गंगामाया",
        "order": 1,
        "id": "yPNa49WEgqHZHMnCTpE6"
    },
    {
        "generation": 30,
        "firstname": "Anjana",
        "gender": "female",
        "surname": "neyong",
        "father": "3YLvHWCWdU1XtyPUbQ90",
        "firstname_nepali": "अञ्जना",
        "order": 1,
        "id": "yuxYFz1jOAFEbOhYgfYD"
    },
    {
        "generation": 29,
        "firstname": "Nar Prasad",
        "gender": "male",
        "surname": "neyong",
        "father": "OM9MJEJFRT2bKOAo3iUi",
        "firstname_nepali": "नरप्रसाद",
        "order": 2,
        "id": "wXoi6mCx1t64iFs1E04j"
    },
    {
        "generation": 30,
        "firstname": "Kaji",
        "gender": "male",
        "surname": "neyong",
        "father": "vXrdn3yKjJilj2N4Sa1Z",
        "firstname_nepali": "काजी",
        "order": 2,
        "id": "wjHaFfJZJmSfDvUdHAZm"
    },
    {
        "firstname": "biruhang",
        "gender": "male",
        "surname": "neyong",
        "father": "veAMM4hkEsggQzTFLm3r",
        "firstname_nepali": "बिरुहांग",
        "generation": 2,
        "id": "woek7DK1CgxJ24U25aBM"
    },
    {
        "generation": 30,
        "firstname": "Tunk Prasad",
        "gender": "male",
        "surname": "neyong",
        "father": "ql3VFANib5uzCyGbKzGB",
        "firstname_nepali": "टंकप्रसाद",
        "order": 1,
        "id": "xFyka1iAoJ6G4jeIF9YQ"
    },
    {
        "generation": 29,
        "firstname": "Dhan Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "OM9MJEJFRT2bKOAo3iUi",
        "firstname_nepali": "धनबहादुर",
        "order": 1,
        "id": "xYxZwzugiHlHI3Rc4yeK"
    },
    {
        "generation": 26,
        "firstname": "Kumdhoj",
        "gender": "male",
        "surname": "neyong",
        "father": "7r6MWXFKNvpwHDR6seQN",
        "firstname_nepali": "कुम्धोज",
        "order": 1,
        "id": "zIAG9EIybhjeooMK5JYh"
    },
    {
        "generation": 29,
        "firstname": "Sushma",
        "gender": "female",
        "surname": "neyong",
        "father": "J2ac0kCcTxVQjo4TbSqr",
        "firstname_nepali": "सुष्मा",
        "order": 1,
        "id": "zTLA2zfqQarLbwgNTjyj"
    },
    {
        "generation": 28,
        "firstname": "Sancharani",
        "gender": "female",
        "surname": "Okhrabu",
        "father": "",
        "spouse": "LXwLyNatGKv4iOisO1yF",
        "firstname_nepali": "संचारनी",
        "order": 1,
        "id": "zW7wr9mUM54t1DfxPwVS"
    },
    {
        "generation": 31,
        "firstname": "Soniya",
        "gender": "female",
        "surname": "neyong",
        "father": "o7fTY7jpN2NLCBeAgJ7y",
        "firstname_nepali": "सोनिया",
        "order": 1,
        "id": "zncq7bi1jTfD4CaCbY9R"
    },
    {
        "generation": 29,
        "firstname": "Ranjana",
        "gender": "female",
        "surname": "neyong",
        "father": "72xTPRMzTjPPQQVDjSSB",
        "firstname_nepali": "रञ्जना",
        "order": 2,
        "id": "wyoRId8JGlZnOApjCzS5"
    },
    {
        "generation": 29,
        "firstname": "Uttam",
        "gender": "male",
        "surname": "neyong",
        "father": "5alf3Nw8bulym1dR6cxr",
        "firstname_nepali": "उत्तम",
        "order": 2,
        "id": "yCb56AvQ7udeHbCm687K"
    },
    {
        "generation": 24,
        "firstname": "dhanwaaj",
        "gender": "male",
        "surname": "neyong",
        "father": "PoTRFDxUu9kJRxkv5ok7",
        "firstname_nepali": "धनवाज",
        "order": 2,
        "id": "yQtDCK1tH6s4kX53TfY2"
    },
    {
        "generation": 30,
        "firstname": "Ritu",
        "gender": "female",
        "surname": "neyong",
        "father": "ImkXMy2dTkAhNihvm6zk",
        "firstname_nepali": "रितु",
        "order": 2,
        "id": "ywjTSNsu1plSFzTcHl61"
    },
    {
        "generation": 29,
        "firstname": "dalbahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "P4Ad5J4jIDcrNpV1oIHJ",
        "firstname_nepali": "दलबहादुर",
        "order": 2,
        "id": "zGv5Oqm4WMll6hXgZLv5"
    },
    {
        "generation": 25,
        "firstname": "Bhaagpati",
        "gender": "male",
        "surname": "neyong",
        "father": "jah7fN311IS4msHcF6Tk",
        "firstname_nepali": "भागपति",
        "order": 2,
        "id": "zcHM10yqmUAAo2MiHU0j"
    },
    {
        "generation": 29,
        "firstname": "Dan Bahadur",
        "gender": "male",
        "surname": "neyong",
        "father": "dQxa13Ho5vQJGuMiKV2D",
        "firstname_nepali": "दानबहादुर",
        "order": 3,
        "id": "xkZl7TDiW90pUD4lWAqJ"
    },
    {
        "generation": 29,
        "firstname": "Laxman",
        "gender": "male",
        "surname": "neyong",
        "father": "IWuqwgwdrs0s9uGJTP95",
        "firstname_nepali": "लक्ष्मण",
        "order": 3,
        "id": "ySuEYexhhmyHiituMT18"
    },
    {
        "generation": 30,
        "firstname": "Pushpa",
        "gender": "female",
        "surname": "neyong",
        "father": "ikiGkJgxhaFwSdYUeXQ1",
        "firstname_nepali": "पुष्पा",
        "order": 3,
        "id": "yvsHB8RCXafIguXklXBq"
    },
    {
        "generation": 29,
        "firstname": "Mina sabai",
        "gender": "female",
        "surname": "Ch. Kyak",
        "father": "kf4un20w1ssu3R7xYu3Y",
        "firstname_nepali": "मिना सबै",
        "order": 4,
        "id": "yPmzJ4VPO1ERZfSSpyhj"
    },
    {
        "generation": 30,
        "firstname": "Saraswati",
        "gender": "female",
        "surname": "Rai",
        "father": "OwV0mfHCf1GVYHiL7E4x",
        "firstname_nepali": "सरस्वती",
        "order": 5,
        "id": "xm5ayrrxou46rmnM6n3i"
    },
    {
        "generation": 29,
        "firstname": "Bimala",
        "gender": "female",
        "surname": "neyong",
        "father": "wkAS2er2VnhS4hpSgltq",
        "firstname_nepali": "बिमला",
        "order": 7,
        "id": "wzDKCRIcli75WODJq0pJ"
    },
    {
        "generation": 17,
        "firstname": "panjare",
        "gender": "male",
        "surname": "neyong",
        "father": "I8wFoAUdSYu4Nd0hLezu",
        "firstname_nepali": "पानजरे",
        "id": "yeu9N9gngQQL5IKDwF8W"
    },
    {
        "generation": 31,
        "firstname": "Dipa",
        "gender": "female",
        "surname": "Magar",
        "father": "",
        "spouse": "5x8aVF3lmWNp0yGiXeWV",
        "firstname_nepali": "दीपा",
        "id": "yxjXia9ahyKPP7G7fUZ1"
    },
    {
        "generation": 30,
        "firstname": "Kamala",
        "gender": "female",
        "surname": "Banem",
        "father": "",
        "spouse": "qcsZQblaYoGvkRwVHwaF",
        "firstname_nepali": "कमला",
        "id": "zac2NKMb1KZvxjfNSgar"
    }
];