import Header from '../../layouts/Header.jsx';
import Footer from '../../layouts/Footer.jsx';

import Images from '../../../api/imagesConfig';
import { Link } from 'react-router-dom';

const CancelPage = () => {

    let donateCancelImg = Images.icons.donateCancel;

    return (
        <div className="page page-donate-cancel">
            <Header />
            
            <div className="block-wrapper">
                <div className="donate-container block">
                    <h1>Donation Cancelled</h1>
                    <img src={donateCancelImg} alt="donation-cancelled" />
                    <p>You have cancelled your donation.</p>
                    <p>We respect your decision and wish for your support by sharing this platform to your friends and family.</p>
                    
                    <Link to="/caste/select" className="btn">
                        Continue to search
                    </Link>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default CancelPage;