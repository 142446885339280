import images from "../../api/imagesConfig.js";

const Maintenance = () => {
	let leafImage = images.icons["leaf"];
	let treeImage = images.icons["tree"];

	return (
		<>
			<div id="maintenance-logo">
				<img src={treeImage} alt="namawali tree" />
			</div>

			<div className="page page-maintenance">
				<h1>This is page is under maintenance.</h1>
				<p>see you soon...</p>
			</div>

			<section id="special-leaves">
				<div className="set">
					<div>
						<img src={leafImage} />
					</div>
					<div>
						<img src={leafImage} />
					</div>
					<div>
						<img src={leafImage} />
					</div>
					<div>
						<img src={leafImage} />
					</div>
					<div>
						<img src={leafImage} />
					</div>
					<div>
						<img src={leafImage} />
					</div>
				</div>
			</section>
		</>
	);
};

export default Maintenance;
