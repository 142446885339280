import Header from "../layouts/Header";

import images from "../../api/imagesConfig.js";
import Footer from "../layouts/Footer";

const AboutPage = () => {
	let coffeeImg = images.icons["coffee"];

	return (
		<div className="page">
			<Header />

			<h1 className="about-title">Project Namawali</h1>

			<div className="block-wrapper">
				<div className="login-container block">
					<p>
						Many years ago, during our friends' gatherings, among
						the many conversations we had, one topic always left me
						with deep thoughts: our contribution to our society and
						culture. I am sure that many of us, every once in a
						while, think about our existence and origin. However, we
						often toss those thoughts away due to the busyness of
						our daily lives, promising ourselves that we will return
						to them once we have free time.
					</p>
					<p>
						My profession is building web applications and turning
						ideas into reality. So, I thought, why not make
						something that will help my own community? I was
						introduced to a book called Bangshawali that contained
						information about my mother's ancestors, and they
						printed it every few years as new members were added.
						There, I saw an opportunity to use my skills to help
						them solve that problem and talked to a few of my
						friends and family.
					</p>
					<p>
						After a few weeks of development, life caught up with
						me, and I left the project incomplete, moving on with my
						life. However, after 5-6 years, I came back to the
						realization that our time in this world is limited, and
						the thing we will regret the most will always be the
						things we did not do rather than the things we did.
					</p>
					<p>
						In 2021, I came to the UK from Nepal and started the
						journey to finish my dream project, which I named
						Namawali. With the help of everyone in the Limbu
						community, I challenged myself to complete this project
						within 2023.
					</p>
					<div className="coffee-icon">
						<img src={coffeeImg} alt="coffee thanks" />
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default AboutPage;
